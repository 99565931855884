import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LabelSortIcon from 'components/LabelSortIcon';
import { GET_ALL_ACCOUNTS } from 'graphQL/Accounts/Queries';
import React, { Fragment, useEffect, useState } from 'react';
import { ascSort, descSort } from 'utils/businessLogic';
import useStyles from '../style';
import DropdownMenu from './DropdownMenu';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E36285',
    color: theme.palette.common.black,
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '34px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: '400',
    fontSize: '16px',
    backgroundColor: '#E5E5E5',
    lineHeight: '22px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 9,
  },
}));

interface IData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
}

export default function AccountTable({ search }) {
  const classes = useStyles();
  const [list, setList] = useState<any[]>();
  const [dataNoneSort, setDataNoneSort] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [fieldSort, setFieldSort] = useState('');
  const [getAccountLazy, { data: data }] = useLazyQuery(GET_ALL_ACCOUNTS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.accounts.length > 0) {
      const listFilter = data?.accounts.filter(
        (item: IData) =>
          item.firstName.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          item.phoneNumber.toLowerCase().includes(search.toLowerCase()) ||
          item.lastName.toLowerCase().includes(search.toLowerCase()) ||
          item.role.toLowerCase().includes(search.toLowerCase()),
      );
      const temp =
        fieldSort !== ''
          ? sortBy === 'asc'
            ? listFilter.sort(ascSort(fieldSort))
            : listFilter.sort(descSort(fieldSort))
          : listFilter;
      setList(temp);
      setDataNoneSort(listFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, data?.accounts]);

  useEffect(() => {
    getAccountLazy();
  }, [getAccountLazy]);

  return (
    <>
      {list ? (
        <TableContainer className={classes.tableContainer}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <LabelSortIcon
                    title="First Name"
                    field="firstName"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <LabelSortIcon
                    title="Last Name"
                    field="lastName"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <LabelSortIcon
                    title="Email"
                    field="email"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">Phone Number</StyledTableCell>
                <StyledTableCell align="left">Role</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ paddingTop: '32px' }}>
              <TableRow sx={{ height: '32px' }}></TableRow>
              {list.length > 0 ? (
                list.map((row: IData) => (
                  <Fragment key={row.id}>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        {row.firstName}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.lastName}</StyledTableCell>
                      <StyledTableCell align="left">{row.email}</StyledTableCell>
                      <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                      <StyledTableCell align="left">{row.role}</StyledTableCell>
                      <StyledTableCell align="left">
                        <DropdownMenu row={row} setList={setList} list={list} />
                      </StyledTableCell>
                    </StyledTableRow>
                    <TableRow sx={{ height: '7px' }}></TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <div>
                      <Typography>No account found!</Typography>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}
