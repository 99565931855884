import React, { useState } from 'react';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../path/index';
import { useLazyQuery, useMutation } from '@apollo/client';
import useStyles from '../style';
import ConfirmDialog from 'components/ConfirmDialog';
import {
  DELETE_BOOKING,
  PUBLISH_BOOKING,
  RESERVED_BOOKING,
  UNPUBLISH_BOOKING,
} from 'graphQL/Bookings/Mutations';
import { GET_ALL_BOOKINGS } from 'graphQL/Bookings/Queries';
import { toastError, toastSuccess } from 'components/Toast';
import { EStatus } from 'types/globalType';

const ACTIONS = [
  {
    name: 'Delete Booking',
    type: 'delete',
  },
  {
    name: 'Published Booking',
    type: EStatus.PUBLISHED,
  },
  {
    name: 'Unpublished Booking',
    type: EStatus.DARFT,
  },
  {
    name: 'Reserved Booking',
    type: EStatus.RESERVED,
  },
];

export const question = (type) => {
  return `Confirm ${type}?`;
};

function DropdownMenu(props) {
  const classes = useStyles();
  const { row } = props;
  const navigate = useNavigate();
  const [save, setSave] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const handleSave = () => {
    setSave(false);
  };

  const [actionType, setActionType] = useState('');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAccountDetail = (id: number) => {
    navigate(PATH.dashboard.bookings.view(id));
    setAnchorEl(null);
  };

  const [getAllBookings] = useLazyQuery(GET_ALL_BOOKINGS, {
    fetchPolicy: 'network-only',
  });

  const [deleteBooking] = useMutation(DELETE_BOOKING, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      toastSuccess('Delete Booking Success');
      getAllBookings();
    },
    onError(error) {
      toastError('Delete Booking Fail');
      console.log('Delete Failed: ', error);
    },
  });

  const [publishBooking] = useMutation(PUBLISH_BOOKING, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      toastSuccess('Publish Booking Success');
      getAllBookings();
    },
    onError(error) {
      toastError('Publish Booking Fail');
      console.log('Publish Failed: ', error);
    },
  });

  const [unpublishBooking] = useMutation(UNPUBLISH_BOOKING, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      toastSuccess('Unpublish Booking Success');
      getAllBookings();
    },
    onError(error) {
      toastError('Unpublish Booking Fail');
      console.log('Unpublish Failed: ', error);
    },
  });

  const [reserveBooking] = useMutation(RESERVED_BOOKING, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      toastSuccess('Reserved Booking Success');
      getAllBookings();
    },
    onError(error) {
      toastError('Reserved Booking Fail');
      console.log('Reserved Failed: ', error);
    },
  });

  const handleAction = () => {
    const variables = {
      advertiseBookingId: email,
    };
    switch (actionType) {
      case 'delete':
        deleteBooking({ variables });
        break;
      case EStatus.PUBLISHED:
        publishBooking({ variables });
        break;
      case EStatus.DARFT:
        unpublishBooking({ variables });
        break;
      case EStatus.RESERVED:
        reserveBooking({ variables });
        break;
      default:
    }
    setSave(false);
  };

  const onFocusAction = (e, type) => {
    setEmail(e);
    setSave(true);
    setActionType(type);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ fontSize: 25 }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className={classes.menuItem} onClick={() => onAccountDetail(row.id)}>
          Booking Details
        </MenuItem>
        {ACTIONS.map((status, index) => {
          return (
            <MenuItem
              key={index}
              disabled={row.status === status.type}
              className={classes.menuItem}
              onClick={() => onFocusAction(row.id, status.type)}
            >
              {status.name}
            </MenuItem>
          );
        })}
      </Menu>
      <ConfirmDialog
        onClickAction={handleAction}
        email={email}
        open={save}
        handleClose={handleSave}
        title={"You're permanent"}
        question={question(actionType)}
        action={actionType === 'delete' ? 'delete' : 'edit'}
        confirm="Yes"
      />
    </>
  );
}

export default DropdownMenu;
