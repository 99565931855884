import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '20px',
    },
  },
  dialogHeader: {
    '&.MuiDialogTitle-root': {
      textAlign: 'end',
      padding: '28px 28px 10px 28px',
    },
  },
  dialogContent: {
    '&.MuiDialogContent-root': {
      textAlign: 'center',
      display: 'block !important',
      padding: '0px 24px 38px',
    },
  },
  dialogContentText: {
    '&.MuiDialogContentText-root': {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '38,42px',
      color: '#000000',
      display: 'block !important',
    },
  },
  dialogAction: {
    '&.MuiDialogActions-root': {
      justifyContent: 'center',
      padding: '0px 54px 49px',
    },
  },
  button: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: '#F4D23F',
      backgroundColor: '#E36285',
      borderRadius: '13px',
      fontSize: '21px',
      lineHeight: '33,62',
      fontWeight: 600,
      padding: '10px 58px 10px',
      marginRight: '15px',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#E36285',
    },
  },
});

export default useStyles;
