import {
  FLOAT,
  FLOATING_POINT,
  INTEGER,
  NAME_TABLE_TAB_SPECIAL,
  NAME_TABLE_TAB_START,
  REGEX_INTERGER,
} from '../types/globalType';

export const REGEX_CONFIG = {
  FLOAT: /^[+-]?([0-9]+([.][0-9])?|[.][0-9]+)$/,
  FLOATING_POINT: /^[-+]?[0-9].?[0-9]+([eE][-+]?[0-9]+)?$/,
  INTEGER: /^[0-9]+$/,
  NAME_TABLE_TAB_START: /^[a-zA-Z_]+$/,
  NAME_TABLE_TAB_SPECIAL: /^[a-zA-Z0-9_]*$/,
  REGEX_INTERGER: /[^0-9.-]+/,
};

export function isValidFormatWithRegex(regex: any, value: any) {
  if (value && regex) {
    const REGEXT = new RegExp(regex);
    return REGEXT.test(value);
  }
  return false;
}

export const typeRegex = (type: string) => {
  switch (type) {
    case FLOAT:
      return REGEX_CONFIG.FLOAT;
    case FLOATING_POINT:
      return REGEX_CONFIG.FLOATING_POINT;
    case INTEGER:
      return REGEX_CONFIG.INTEGER;
    case NAME_TABLE_TAB_START:
      return REGEX_CONFIG.NAME_TABLE_TAB_START;
    case NAME_TABLE_TAB_SPECIAL:
      return REGEX_CONFIG.NAME_TABLE_TAB_SPECIAL;
    case REGEX_INTERGER:
      return REGEX_CONFIG.REGEX_INTERGER;
    default:
      return REGEX_CONFIG.FLOAT;
  }
};

export const handleRegexField = (e: any, field: string, type: string, setFieldValue: any) => {
  const value = e?.target?.value;
  const regex = typeRegex(type);
  if (Number(value) && value >= 0 && isValidFormatWithRegex(regex, value)) {
    e.persist();
    setFieldValue(field, Number(value));
  }
  if (value === '') {
    setFieldValue(field, '');
  }
  if (value === 0 || value === '0') {
    setFieldValue(field, 0);
  }
};

export const handleRegexEvent = (e: any, type: string) => {
  const value = e?.target?.value;
  const regex = typeRegex(type);
  if (Number(value) && value >= 0 && isValidFormatWithRegex(regex, value)) {
    e.persist();
    return Number(value);
  }
  if (value === '') {
    return '';
  }
  if (value === 0 || value === '0') {
    return 0;
  }
  return '';
};
