/* eslint-disable indent */
import { Box, Typography, Button, MenuItem, Menu } from '@mui/material';
import React, { useRef, useContext } from 'react';
import useStyle from './styles';
import { useNavigate } from 'react-router';
import { PATH } from '../../path';
import { useLocation } from 'react-router-dom';
import User from './user';
import { ReactComponent as LogoImg } from 'assets/images/Logo.svg';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'utils/firebase';
import MenuIcon from '@mui/icons-material/Menu';
import { RoleContext } from 'components/Layout';

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width };
};

export default function Header({ salesPersonName }) {
  const classes = useStyle();
  const testRole = useContext(RoleContext);
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const viewPort = useViewport();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const headerOptions = [
    {
      name: 'Bookings',
      isActive: pathname.includes('bookings'),
      path: PATH.dashboard.bookings.root,
    },
    {
      name: 'Calendar',
      isActive: pathname.includes('calendar'),
      path: PATH.dashboard.calendar.root,
    },
    {
      name: 'Companies',
      isActive: pathname.includes('companies'),
      path: PATH.dashboard.companies.root,
    },
    {
      name: 'Reports',
      isActive: pathname.includes('reports'),
      path: PATH.dashboard.reports.root,
    },
  ];

  const adminHeaderOptions = [
    {
      name: 'Bookings',
      isActive: pathname.includes('bookings'),
      path: PATH.dashboard.bookings.root,
    },
    {
      name: 'Calendar',
      isActive: pathname.includes('calendar'),
      path: PATH.dashboard.calendar.root,
    },
    {
      name: 'Companies',
      isActive: pathname.includes('companies'),
      path: PATH.dashboard.companies.root,
    },
    {
      name: 'Sale Accounts',
      isActive: pathname.includes('sale-accounts'),
      path: PATH.dashboard.saleAccounts.root,
    },
    {
      name: 'Ads Types',
      isActive: pathname.includes('advertising-types'),
      path: PATH.dashboard.adTypes.root,
    },
    {
      name: 'Ads Target',
      isActive: pathname.includes('advertising-target'),
      path: PATH.dashboard.adsTarget.root,
    },
    {
      name: 'Reports',
      isActive: pathname.includes('reports'),
      path: PATH.dashboard.reports.root,
    },
  ];

  const onNavigate = (toPath) => {
    navigate(toPath);
  };

  return (
    <>
      <div
        style={{
          width: '100vw',
          padding: '15px 5%',
          background: '#F9E0E7',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'fixed',
          top: 0,
          zIndex: 99,
          boxSizing: 'border-box',
          fontFamily: 'Hind',
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center" sx={{ width: '100%' }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              className={classes.menuRoot2}
              sx={{ flexGrow: 1 }}
              flexDirection="row"
              alignItems="center"
              padding="0"
              minWidth="auto"
              overflow="auto"
            >
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MenuIcon sx={{ fontSize: '32px', color: '#E36285' }} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                className={classes.menuRoot2}
              >
                {headerOptions.map((item, index) => (
                  <MenuItem
                    key={index}
                    className={classes.menuItem}
                    onClick={() => {
                      onNavigate(item.path);
                      handleClose();
                    }}
                  >
                    <Typography className={classes.menuItemText}>{item.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <LogoImg
              style={{
                width: '80px',
                height: '80px',
                marginRight: '24px',
                background: 'none !important',
              }}
              className={classes.logoHeader}
            />
          </Box>
          <Box
            sx={{ flexGrow: 1 }}
            flexDirection="row"
            alignItems="center"
            display={viewPort.width <= 800 ? 'none' : 'flex'}
          >
            {testRole === 'admin'
              ? adminHeaderOptions.map((head, index) => (
                  <Box key={index} marginRight="32px">
                    <Typography
                      variant="h4"
                      style={{
                        fontFamily: 'Hind',
                        color: head.isActive ? '#E36285' : '#000',
                        fontWeight: 700,
                        cursor: 'pointer',
                      }}
                      className={classes.headerOption}
                      onClick={() => onNavigate(head.path)}
                    >
                      {head.name}
                    </Typography>
                  </Box>
                ))
              : headerOptions.map((head, index) => (
                  <Box key={index} marginRight="32px">
                    <Typography
                      variant="h4"
                      style={{
                        color: head.isActive ? '#E36285' : '#000',
                        fontWeight: 700,
                        cursor: 'pointer',
                      }}
                      className={classes.headerOption}
                      onClick={() => onNavigate(head.path)}
                    >
                      {head.name}
                    </Typography>
                  </Box>
                ))}
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <User email={user?.email} name={salesPersonName} />
          </Box>
        </Box>
      </div>
    </>
  );
}
