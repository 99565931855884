import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  headerText: {
    '&.MuiTypography-h2': {
      fontWeight: 700,
      lineHeight: '54.43px',
    },
  },
  searchPaper: {
    '&.MuiPaper-root': {
      padding: '2px 10px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: '13px',
    },
  },
  input: {
    fontSize: '2rem',
    '&.MuiInputBase-root': {
      width: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      fontWeight: 300,
      fontSize: '2rem',
    },
    '&.MuiOutlinedInput-input': {
      fontSize: '2rem',
      padding: '5px 10px',
    },
    '&.MuiFormHelperText-root': {
      fontSize: '1.5rem',
      color: 'red',
    },
  },
  selectInput: {
    width: '100%',
    borderRadius: '7px',
    '&.MuiSelect-select': {
      padding: '0px',
    },
  },
  inputHeader: {
    fontSize: '20px !important',
    fontWeight: '700 !important',
    lineHeight: '26px !important',
    marginBottom: '5px !important',
    boxShadow: 'none',
    padding: '0px',
  },
  button: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: '#F4D23F',
      backgroundColor: '#E36285',
      borderRadius: '13px',
      fontSize: '21px',
      lineHeight: '33,62px',
      fontWeight: 700,
      padding: '10px 66px',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#E36285',
    },
  },
  deleteTitle: {
    '&.MuiTypography-root': {
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '26px',
      cursor: 'pointer',
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      fontSize: '1.7rem',
    },
  },
  helpertext: {
    color: 'red',
    fontSize: '1.7rem',
    '&.MuiFormHelperText-root': {
      fontSize: '1.5rem',
      color: 'red',
    },
  },
});
