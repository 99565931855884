/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect, createContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Spinner from 'components/Spiner';
import Dashboard from 'components/Dashboard';
import SaleAccounts from 'pages/SaleAcounts';
import AccountDetail from 'pages/SaleAcounts/component/AccountDetail';
import Bookings from 'pages/Bookings';
import BookingsView from 'pages/Bookings/component/BookingsView';
import Calendar from 'pages/Calendar';
import AddAccount from '../../pages/SaleAcounts/component/AddAccount';
import AdminRouter from './AdminRouter';
import { useLazyQuery } from '@apollo/client';
import { GET_USER } from '../../graphQL/Accounts/Queries';
import { auth } from 'utils/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Companies from 'pages/Companies';
import AddCompanies from 'pages/Companies/component/AddCompanies';
import CompanyDetail from 'pages/Companies/component/CompanyDetail';
import Reports from 'pages/Reports';
import AdvertisingTypes from 'pages/AdvertisingType';
import AdTypeDetail from 'pages/AdvertisingType/component/AdTypeDetail';
import AddAdType from 'pages/AdvertisingType/component/AddAdType';
import AdvertisingTarget from 'pages/AdvertisingTarget';

const defaultRole = 'agent';

export const RoleContext = createContext(defaultRole);

export default function Layout() {
  const isAuthorized = true;
  const Login = lazy(() => import('../../pages/Login/index'));
  const [role, setRole] = React.useState('admin');
  const [salesPersonName, setSalesPersonName] = React.useState('');
  const [user] = useAuthState(auth);
  const [getUserLoggedIn, { data, loading, error }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (user) {
        setRole(response?.getUser.role);
        setSalesPersonName(`${response?.getUser.firstName} ${response?.getUser.lastName}`);
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  React.useEffect(() => {
    if (user) {
      getUserLoggedIn();
    }
  }, [user]);

  return (
    <>
      <RoleContext.Provider value={role}>
        <div>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard salesPersonName={salesPersonName} />}>
                <Route path="" element={<Navigate to="bookings" />} />
                <Route
                  path="sale-accounts"
                  element={
                    <AdminRouter role={role}>
                      <SaleAccounts />
                    </AdminRouter>
                  }
                />
                <Route
                  path="sale-accounts/add"
                  element={
                    <AdminRouter role={role}>
                      <AddAccount />
                    </AdminRouter>
                  }
                />
                <Route
                  path="sale-accounts/:id"
                  element={
                    <AdminRouter role={role}>
                      <AccountDetail />
                    </AdminRouter>
                  }
                />
                <Route path="bookings" element={<Bookings />} />
                <Route
                  path="bookings/:id"
                  element={<BookingsView salesPersonName={salesPersonName} />}
                />
                <Route path="calendar" element={<Calendar />} />
                <Route path="companies" element={<Companies />} />
                <Route path="companies/:id" element={<CompanyDetail />} />
                <Route path="companies/add" element={<AddCompanies />} />
                <Route path="reports" element={<Reports />} />
                <Route
                  path="advertising-target"
                  element={
                    <AdminRouter role={role}>
                      <AdvertisingTarget />
                    </AdminRouter>
                  }
                />
                <Route
                  path="advertising-types"
                  element={
                    <AdminRouter role={role}>
                      <AdvertisingTypes />
                    </AdminRouter>
                  }
                />
                <Route
                  path="advertising-types/:id"
                  element={
                    <AdminRouter role={role}>
                      <AdTypeDetail />
                    </AdminRouter>
                  }
                />
                <Route
                  path="advertising-types/add"
                  element={
                    <AdminRouter role={role}>
                      <AddAdType />
                    </AdminRouter>
                  }
                />
              </Route>
            </Routes>
          </Suspense>
        </div>
      </RoleContext.Provider>
    </>
  );
}
