import { useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Divider } from '@mui/material';
import Spinner from 'components/Spiner';
import { GET_ALL_ADSTARGETS } from 'graphQL/AdsTarget/Queries';
import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/images/edit-icon.svg';
import AdTargetForm from './component/AdTargetForm';
import AdTargetTable from './component/AdTargetTable';

const ascSort = (sortBy) => (a, b) => a[sortBy] > b[sortBy] ? 1 : -1;
interface IAdvertiseType {
  id: string;
  name: string;
  description: string;
  viewType: string;
}

interface IAdTypeObject {
  adType: IAdvertiseType[];
  sort: number;
}

interface IData {
  [x: string]: any;
  id: number;
  target: string;
  adTypeObject: IAdTypeObject[];
}

export default function AdvertisingTarget(props) {
  const [list, setList] = useState<IData>();
  const [adTypeArr, setAdTypeArr] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleNonDelete = () => {
    setIsDelete(false);
  };

  const handleNonEdit = () => {
    setIsSave(false);
  };

  const [getAdsTargetLazy] = useLazyQuery(GET_ALL_ADSTARGETS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setList(response?.allAdsTargetContent);
      setAdTypeArr(response?.allAdsTargetContent[0]?.adTypeObjects.sort(ascSort('sort')));
      setLoading(false);
    },
    onError(error) {
      console.log('Login Failed: ', error);
    },
  });

  useEffect(() => {
    getAdsTargetLazy();
  }, [getAdsTargetLazy]);

  return (
    <>
      {!loading ? (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '40px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div>
              {isEdit && (
                <Box sx={{ width: '100px' }}>
                  <KeyboardBackspaceIcon
                    sx={{ fontSize: '36px' }}
                    onClick={() => setIsEdit(false)}
                  />
                </Box>
              )}
            </div>

            <div style={{ width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography style={{ fontSize: '34px', fontWeight: '700', marginRight: '40px' }}>
                  Ads Target
                </Typography>
                {!isEdit && <EditIcon onClick={() => setIsEdit(true)} />}
              </Box>
              <Divider />
              <Box marginTop={3} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                {!isEdit ? (
                  <AdTargetTable list={list} adTypeArr={adTypeArr} setAdTypeArr={setAdTypeArr} />
                ) : (
                  <AdTargetForm
                    setLoading={setLoading}
                    setIsEdit={setIsEdit}
                    list={list}
                    isAdd={false}
                    isSave={isSave}
                    isDelete={isDelete}
                    handleNonEdit={handleNonEdit}
                    handleNonDelete={handleNonDelete}
                    setIsSave={setIsSave}
                    setIsDelete={setIsDelete}
                  />
                )}
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}
