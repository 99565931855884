import { makeStyles } from '@mui/styles';
const breakPoint = 890;
const largeBreakPoint = 1204;

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  headerText: {
    '&.MuiTypography-h2': {
      fontWeight: 700,
      lineHeight: '54.43px',
      fontSize: '2.4rem',
      fontFamily: ['"Hind"', 'Open Sans'].join(','),
    },
  },
  searchPaper: {
    '&.MuiPaper-root': {
      padding: '8px 10px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: '13px',
    },
    marginRight: '20px',
    marginBottom: '12px',
    [`@media (min-width:${breakPoint}px)`]: {
      marginBottom: '0px',
    },
  },
  inputHeader: {
    fontSize: '20px !important',
    fontWeight: '700 !important',
    lineHeight: '26px !important',
    marginBottom: '5px !important',
  },
  button: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: '#F4D23F',
      backgroundColor: '#E36285',
      borderRadius: '13px',
      fontSize: '21px',
      lineHeight: '33,62px',
      fontWeight: 700,
      padding: '10px 66px',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#E36285',
    },
  },
  deleteTitle: {
    '&.MuiTypography-root': {
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '26px',
      cursor: 'pointer',
    },
  },
  searchAndAdd: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [`@media (min-width:${breakPoint}px)`]: {
      flexDirection: 'row',
    },
  },
  tableContainer: {
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F9E0E7',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E36285',
      width: '4px',
      borderRadius: '12px',
    },
  },
  menu: {
    padding: '10px',
    fontSize: '1.5rem',
    '&.MuiMenuItem-root': {
      fontSize: '1.6rem',
    },
  },
  chartBox: {
    padding: '0px 100px',
    boxShadow: 'none',
    background: 'none',
    width: 'auto',
  },
  reportsInput: {
    background: 'none',
    fontSize: '1.5rem',
    '&.MuiInputBase-root': {
      width: '300px',
      boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      fontWeight: 300,
      fontSize: '1.5rem',
      background: 'none',
    },
    '&.MuiOutlinedInput-input': {
      fontSize: '1.5rem',
      padding: '5px 10px',
      background: 'none',
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      fontSize: '1.5rem',
    },
  },
});

export default useStyles;
