import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import {
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LabelSortIcon from 'components/LabelSortIcon';
import dayjs from 'dayjs';
import { GET_ALL_BOOKINGS } from 'graphQL/Bookings/Queries';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EStatus, EStatusDisplay } from 'types/globalType';
import { ascSort, descSort } from 'utils/businessLogic';
import { compareDateBooking } from 'utils/compareDateBooking';
import { PATH } from '../../../path/index';
import useStyle from '../style';
import DropdownMenu from './DropdownMenu';

export const renderStatus = (row) => {
  const { status } = row;
  switch (status) {
    case EStatus.DARFT:
      return (
        <Chip
          style={{
            marginLeft: '10px',
            fontSize: '16px',
            backgroundColor: '#b5b4b4',
            fontWeight: 500,
          }}
          label={EStatusDisplay.DARFT}
          size="medium"
        ></Chip>
      );
    case EStatus.PUBLISHED:
      const statusBooking = compareDateBooking(row);
      return (
        <Chip
          style={{
            marginLeft: '10px',
            fontSize: '16px',
            backgroundColor: statusBooking.styles.backgroundColor,
            color: statusBooking.styles.color,
            fontWeight: 500,
          }}
          label={statusBooking.status}
          size="medium"
        ></Chip>
      );
    case EStatus.RESERVED:
      return (
        <Chip
          style={{
            marginLeft: '10px',
            fontSize: '16px',
            backgroundColor: '#f2f956',
            fontWeight: 500,
          }}
          label={EStatusDisplay.RESERVED}
          size="medium"
        ></Chip>
      );
    default:
      return;
  }
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E36285',
    color: theme.palette.common.black,
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '34px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: '400',
    fontSize: '16px',
    backgroundColor: '#E5E5E5',
    lineHeight: '22px',
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 9,
  },
}));

export default function BookingsTable({ search }) {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('');
  const [fieldSort, setFieldSort] = useState('');

  const [bookingsData, setBookingsData] = useState<any>();
  const [dataNoneSort, setDataNoneSort] = useState<any[]>([]);

  const [getBookingsData, { data: dataBookings }] = useLazyQuery(GET_ALL_BOOKINGS, {
    fetchPolicy: 'network-only',
  });

  const classes = useStyle();

  const onAccountDetail = (id) => {
    navigate(PATH.dashboard.bookings.view(id.id));
  };

  useEffect(() => {
    if (dataBookings?.advertiseBookings) {
      const listFilter = dataBookings?.advertiseBookings
        .map((item: any) => {
          return {
            ...item,
            adType: item.adType.name,
            companyName: item.company.tradingName,
            statusDisplay: compareDateBooking(item).status,
          };
        })
        .filter(
          (item: any) =>
            item.salesName.toLowerCase().includes(search.toLowerCase()) ||
            dayjs(item.startDate).format('DD MMM').toLowerCase().includes(search.toLowerCase()) ||
            item.companyName.toLowerCase().includes(search.toLowerCase()) ||
            dayjs(item.endDate).format('DD MMM').toLowerCase().includes(search.toLowerCase()),
        );
      const temp =
        fieldSort !== ''
          ? sortBy === 'asc'
            ? listFilter.sort(ascSort(fieldSort))
            : listFilter.sort(descSort(fieldSort))
          : listFilter;
      setBookingsData(temp);
      setDataNoneSort(listFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dataBookings?.advertiseBookings]);

  useEffect(() => {
    getBookingsData();
  }, [getBookingsData]);

  return (
    <>
      {bookingsData ? (
        <TableContainer className={classes.tableContainer}>
          <Table sx={{ minWidth: 700, borderRadius: '0px' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <LabelSortIcon
                    title="Company"
                    field="companyName"
                    list={bookingsData}
                    setList={setBookingsData}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <LabelSortIcon
                    title="Ads type"
                    field="adType"
                    list={bookingsData}
                    setList={setBookingsData}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <LabelSortIcon
                    title="Duration"
                    field="startDate"
                    list={bookingsData}
                    setList={setBookingsData}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <LabelSortIcon
                    title="Sales Person"
                    field="salesName"
                    list={bookingsData}
                    setList={setBookingsData}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <LabelSortIcon
                    title="Status"
                    field="statusDisplay"
                    list={bookingsData}
                    setList={setBookingsData}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ paddingTop: '32px' }}>
              <TableRow sx={{ height: '32px' }}></TableRow>
              {bookingsData?.length > 0 ? (
                bookingsData?.map((row, index) => (
                  <Fragment key={row.id}>
                    <StyledTableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          fontWeight: '500',
                          fontSize: '16px',
                          backgroundColor: '#E5E5E5',
                          lineHeight: '22px',
                        }}
                        onClick={() => onAccountDetail(row)}
                      >
                        {row.company.tradingName}
                      </TableCell>
                      <StyledTableCell align="left" onClick={() => onAccountDetail(row)}>
                        {row?.adType}
                      </StyledTableCell>
                      <StyledTableCell align="left" onClick={() => onAccountDetail(row)}>
                        {`${dayjs(row.startDate).format('DD MMM')} - ${dayjs(row.endDate).format(
                          'DD MMM',
                        )}`}
                      </StyledTableCell>
                      <StyledTableCell align="left" onClick={() => onAccountDetail(row)}>
                        {row.salesName}
                      </StyledTableCell>
                      <StyledTableCell align="left" onClick={() => onAccountDetail(row)}>
                        {renderStatus(row)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <DropdownMenu row={row} />
                      </StyledTableCell>
                    </StyledTableRow>
                    <TableRow sx={{ height: '7px' }}></TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Typography variant="h4">No booking found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}
