/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Chip, FormControl, InputBase, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import useStyles from '../style';
import ChipItem from './ChipItem';
import DetailPictureDialog from './DetailPictureDialog';
import UploadImageCrop from './UploadImageCrop';

const HTTPS = 'https://';

const Input = styled('input')({
  display: 'none',
});

export default function BookingsUpload(props) {
  const {
    isEdit,
    data,
    id,
    setUploadResourceType,
    setFile,
    handleRemoveDialogOpen,
    uploadResourceData,
    file,
  } = props;
  const classes = useStyles();
  const [fileType, setFileType] = React.useState('article');
  const [linkEng, setLinkEng] = useState('https://');
  const [linkChi, setLinkChi] = useState('https://');
  const [openDetailPictureDialog, setOpenDetailPictureDialog] = useState(false);
  const [openUploadPictureDialog, setOpenUploadPictureDialog] = useState({
    open: false,
    type: '',
  });
  const [externalUrl, setExternalUrl] = useState('');

  const handleCheckUrl = (externalUrl: string) => {
    if (externalUrl.slice(0, 8) !== HTTPS && externalUrl !== '') {
      return `${HTTPS}${externalUrl}`;
    }
    return externalUrl;
  };

  const handleSelectBannerEngFile = (event) => {
    setFile((e) => [...e, event]);

    setUploadResourceType((e) => [
      ...e,
      {
        bookingId: id,
        adTypeId: data.adType.id,
        companyId: data.company.id,
        description: event.name,
        type: 'photo',
        language: 'English',
        externalUrl: handleCheckUrl(externalUrl),
      },
    ]);
  };

  const handleSelectBannerChiFile = (event) => {
    setFile((e) => [...e, event]);
    setUploadResourceType((e) => [
      ...e,
      {
        bookingId: id,
        adTypeId: data.adType.id,
        companyId: data.company.id,
        description: event.name,
        type: 'photo',
        language: 'Chinese',
        externalUrl: handleCheckUrl(externalUrl),
      },
    ]);
  };

  const handleSelectArticleEngFile = (event) => {
    setFile((e) => [...e, event.target.files[0]]);
    setUploadResourceType((e) => [
      ...e,
      {
        bookingId: id,
        adTypeId: data.adType.id,
        companyId: data.company.id,
        description: event.target.files[0].name,
        type: 'text',
        language: 'English',
        externalUrl: handleCheckUrl(externalUrl),
      },
    ]);
  };

  const handleSelectArticleChiFile = (event) => {
    setFile((e) => [...e, event.target.files[0]]);
    setUploadResourceType((e) => [
      ...e,
      {
        bookingId: id,
        adTypeId: data.adType.id,
        companyId: data.company.id,
        description: event.target.files[0].name,
        type: 'text',
        language: 'Chinese',
        externalUrl: handleCheckUrl(externalUrl),
      },
    ]);
  };

  const [pictureData, setPictureData] = useState();

  const handleCloseDetailPictureDialog = () => {
    setOpenDetailPictureDialog(false);
  };

  const handleCloseUploadPictureDialog = () => {
    setOpenUploadPictureDialog({
      open: false,
      type: '',
    });
    setExternalUrl('');
  };

  const handleOpenDetailPictureDialog = (e) => {
    if (e.externalUrl !== '' && e.externalUrl !== null) {
      window.open(e.externalUrl);
    }
    setPictureData(e);
    setOpenDetailPictureDialog(true);
  };

  const renderFileSelect = (file, type, language) => {
    const deleteFile = (indexDelete) => {
      const tempFile = file.filter((_, index) => {
        return index !== indexDelete;
      });
      const tempDataResource = uploadResourceData.filter((_, index) => {
        return index !== indexDelete;
      });
      setFile(tempFile);
      setUploadResourceType(tempDataResource);
    };
    return file.length
      ? file.map((item, index) => {
          return uploadResourceData[index]?.type === type &&
            uploadResourceData[index]?.language === language ? (
            <Chip
              key={index}
              sx={{ width: '60%', justifyContent: 'flex-start', mb: 2 }}
              variant="outlined"
              icon={
                <HighlightOffIcon
                  sx={{ fontSize: '22px', cursor: 'pointer' }}
                  onClick={() => deleteFile(index)}
                />
              }
              label={
                <Typography
                  sx={{ '&.MuiTypography-root': { fontStyle: 'italic' } }}
                  className={classes.uploadFileName}
                >
                  {item && item.name}
                </Typography>
              }
            />
          ) : null;
        })
      : null;
  };

  return (
    <Box>
      <Typography className={classes.uploadTitle}>UPLOADS</Typography>
      <Box className={classes.gridBox} sx={{ display: 'flex', flexDirection: 'column !important' }}>
        <Box className={classes.formControl} sx={{ width: '100% !important' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <FormControl
              sx={{ flexDirection: 'row', margin: '24px 0px 9px 0px', alignItems: 'center' }}
            >
              <Typography className={classes.uploadInput}>Banner (ENG)</Typography>
              {isEdit ? (
                <FileUploadOutlinedIcon
                  sx={{ fontSize: '24px', cursor: 'pointer' }}
                  onClick={() =>
                    setOpenUploadPictureDialog({
                      open: true,
                      type: 'ENG',
                    })
                  }
                />
              ) : null}
            </FormControl>
            {data?.resources?.some((e) => e.language === 'English' && e.type === 'photo') &&
              data?.resources
                .filter((e) => e.language === 'English' && e.type === 'photo')
                .map((i, index) => (
                  <ChipItem
                    key={index}
                    index={index}
                    data={i}
                    isEdit={isEdit}
                    handleRemoveDialogOpen={handleRemoveDialogOpen}
                    handleOpenDetailPictureDialog={handleOpenDetailPictureDialog}
                  />
                ))}
            {isEdit && renderFileSelect(file, 'photo', 'English')}
            <Typography>_</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <FormControl sx={{ flexDirection: 'row', margin: '24px 0px 9px 0px' }}>
              <Typography className={classes.uploadInput}>
                {fileType === 'article' ? 'Article' : 'Link'} (ENG)
              </Typography>

              {isEdit && fileType === 'article' ? (
                <label htmlFor="icon-button-article-eng">
                  <Input
                    accept=".md .markdown"
                    id="icon-button-article-eng"
                    type="file"
                    onChange={handleSelectArticleEngFile}
                  />
                  <FileUploadOutlinedIcon sx={{ fontSize: '24px', cursor: 'pointer' }} />
                </label>
              ) : null}
            </FormControl>
            {fileType === 'article' &&
              data?.resources?.some((e) => e.language === 'English' && e.type === 'text') &&
              data?.resources
                .filter((e) => e.language === 'English' && e.type === 'text')
                .map((i, index) => (
                  <ChipItem
                    key={index}
                    index={index}
                    data={i}
                    isEdit={isEdit}
                    handleRemoveDialogOpen={handleRemoveDialogOpen}
                    handleOpenDetailPictureDialog={handleOpenDetailPictureDialog}
                  />
                ))}
            {fileType === 'article' && isEdit && renderFileSelect(file, 'text', 'English')}
            <Typography>_</Typography>
            {fileType === 'link' &&
              (!isEdit ? (
                <Typography className={classes.uploadFileName}>
                  {linkEng ? linkEng : '_'}
                </Typography>
              ) : (
                <InputBase
                  value={linkEng}
                  className={classes.input}
                  placeholder="Enter URL"
                  sx={{ fontSize: '21px' }}
                  onChange={(e) => setLinkEng(e.target.value)}
                />
              ))}
          </Box>
        </Box>
        <Box className={classes.formControl} sx={{ width: '100% !important' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <FormControl sx={{ flexDirection: 'row', margin: '24px 0px 9px 0px' }}>
              <Typography className={classes.uploadInput}>Banner (CHI)</Typography>
              {isEdit ? (
                <FileUploadOutlinedIcon
                  sx={{ fontSize: '24px', cursor: 'pointer' }}
                  onClick={() =>
                    setOpenUploadPictureDialog({
                      open: true,
                      type: 'CHI',
                    })
                  }
                />
              ) : null}
            </FormControl>
            {data?.resources?.some((e) => e.language === 'Chinese' && e.type === 'photo') &&
              data?.resources
                .filter((e) => e.language === 'Chinese' && e.type === 'photo')
                .map((i, index) => (
                  <ChipItem
                    key={index}
                    index={index}
                    data={i}
                    isEdit={isEdit}
                    handleRemoveDialogOpen={handleRemoveDialogOpen}
                    handleOpenDetailPictureDialog={handleOpenDetailPictureDialog}
                  />
                ))}
            {isEdit && renderFileSelect(file, 'photo', 'Chinese')}
            <Typography>_</Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <FormControl sx={{ flexDirection: 'row', margin: '24px 0px 9px 0px' }}>
              <Typography className={classes.uploadInput}>
                {fileType === 'article' ? 'Article' : 'Link'} (CHI)
              </Typography>

              {isEdit && fileType === 'article' ? (
                <label htmlFor="icon-button-article-chi">
                  <Input
                    accept=".txt"
                    id="icon-button-article-chi"
                    type="file"
                    onChange={handleSelectArticleChiFile}
                  />
                  <FileUploadOutlinedIcon sx={{ fontSize: '24px', cursor: 'pointer' }} />
                </label>
              ) : null}
            </FormControl>
            {fileType === 'article' &&
              data?.resources?.some((e) => e.language === 'Chinese' && e.type === 'text') &&
              data?.resources
                .filter((e) => e.language === 'Chinese' && e.type === 'text')
                .map((i, index) => (
                  <ChipItem
                    key={index}
                    index={index}
                    data={i}
                    isEdit={isEdit}
                    handleRemoveDialogOpen={handleRemoveDialogOpen}
                    handleOpenDetailPictureDialog={handleOpenDetailPictureDialog}
                  />
                ))}

            {fileType === 'article' && isEdit && renderFileSelect(file, 'text', 'Chinese')}
            <Typography>_</Typography>
            {fileType === 'link' &&
              (!isEdit ? (
                <Typography className={classes.uploadFileName}>
                  {linkChi ? linkChi : '_'}
                </Typography>
              ) : (
                <InputBase
                  value={linkChi}
                  className={classes.input}
                  placeholder="Enter URL"
                  sx={{ fontSize: '21px' }}
                  onChange={(e) => setLinkChi(e.target.value)}
                />
              ))}
          </Box>
        </Box>
      </Box>

      {openUploadPictureDialog.open && (
        <UploadImageCrop
          setExternalUrl={setExternalUrl}
          externalUrl={externalUrl}
          open={openUploadPictureDialog.open}
          type={openUploadPictureDialog.type}
          handleClose={handleCloseUploadPictureDialog}
          handleSelectBannerEngFile={handleSelectBannerEngFile}
          handleSelectBannerChiFile={handleSelectBannerChiFile}
        />
      )}

      <DetailPictureDialog
        open={openDetailPictureDialog}
        handleClose={handleCloseDetailPictureDialog}
        pictureData={pictureData}
      />
    </Box>
  );
}
