import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LabelSortIcon from 'components/LabelSortIcon';
import { GET_ALL_COMPANIES } from 'graphQL/Companies/Queries';
import React, { Fragment, useEffect, useState } from 'react';
import { ascSort, descSort } from 'utils/businessLogic';
import useStyle from '../style';
import DropdownMenu from './DropdownMenu';

interface IData {
  id: number;
  companyName: string;
  tradingName: string;
  abn: string;
  phoneNumber: string;
  category: string;
  email: string;
  contactFirstName: string;
  contactLastName: string;
  createdAt: string;
  tags: string[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E36285',
    color: 'black',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '34px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: '400',
    fontSize: '16px',
    backgroundColor: '#E5E5E5',
    lineHeight: '22px',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 9,
  },
}));

export default function CompaniesTable({ search }) {
  const [list, setList] = useState<any[]>();
  const [dataNoneSort, setDataNoneSort] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [fieldSort, setFieldSort] = useState('');
  const classes = useStyle();

  const [getCompaniesLazy, { data: data }] = useLazyQuery(GET_ALL_COMPANIES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.companies.length > 0) {
      const listFilter = data?.companies.filter(
        (item: IData) =>
          item.companyName.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          item.phoneNumber.toLowerCase().includes(search.toLowerCase()) ||
          item.tradingName.toLowerCase().includes(search.toLowerCase()) ||
          item.category.toLowerCase().includes(search.toLowerCase()) ||
          item.contactFirstName.toLowerCase().includes(search.toLowerCase()) ||
          item.contactLastName.toLowerCase().includes(search.toLowerCase()),
      );
      const temp =
        fieldSort !== ''
          ? sortBy === 'asc'
            ? listFilter.sort(ascSort(fieldSort))
            : listFilter.sort(descSort(fieldSort))
          : listFilter;
      setList(temp);
      setDataNoneSort(listFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, data?.companies]);

  useEffect(() => {
    getCompaniesLazy();
  }, [getCompaniesLazy]);

  return (
    <>
      {list ? (
        <TableContainer className={classes.tableContainer}>
          <Table sx={{ minWidth: 700, borderRadius: '0px' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <LabelSortIcon
                    title="Company Name"
                    field="companyName"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <LabelSortIcon
                    title="Trading Name "
                    field="tradingName"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <LabelSortIcon
                    title="Contact Name"
                    field="contactFirstName"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <LabelSortIcon
                    title="Email"
                    field="email"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <LabelSortIcon
                    title="Phone Number"
                    field="phoneNumber"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <LabelSortIcon
                    title="Category"
                    field="category"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ paddingTop: '32px' }}>
              <TableRow sx={{ height: '32px' }}></TableRow>
              {list.length > 0 ? (
                list.map((row: IData) => (
                  <Fragment key={row.id}>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        {row.companyName}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.tradingName}</StyledTableCell>

                      <StyledTableCell align="left">{`${row.contactFirstName} ${row.contactLastName}`}</StyledTableCell>
                      <StyledTableCell align="left">{row.email}</StyledTableCell>
                      <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                      <StyledTableCell align="left">{row.category}</StyledTableCell>
                      <StyledTableCell align="left">
                        <DropdownMenu
                          row={row}
                          setList={setList}
                          dataNoneSort={dataNoneSort}
                          list={list}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                    <TableRow sx={{ height: '7px' }}></TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                    <Typography variant="h4">No company found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}
