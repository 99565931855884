import React from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyle from './styles';
import { DialogContent } from '@material-ui/core';

interface IProps {
  open: boolean;
  handleClose?: () => void;
  title: string;
  question: string;
  action: string;
  onClickAction?: () => void;
  buttonLoading?: boolean;
}

export default function ConfirmDialog(props) {
  const { open, handleClose, title, question, action, onClickAction, confirm, buttonLoading } =
    props;
  const classes = useStyle();

  return (
    <Dialog
      sx={{ borderRadius: '20px' }}
      className={classes.dialog}
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogHeader}>
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {action === 'delete' ? (
          <>
            <DialogContentText className={classes.dialogContentText}>
              {title} <b>delete</b> a item
            </DialogContentText>
            <DialogContentText className={classes.dialogContentText}>{question}</DialogContentText>
          </>
        ) : (
          <>
            <DialogContentText className={classes.dialogContentText}>{title}</DialogContentText>
            <DialogContentText className={classes.dialogContentText}>
              <b>{question}</b>
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          sx={{ '&.MuiButton-root': { color: '#fff' } }}
          className={classes.button}
          variant="contained"
          disableElevation
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          sx={{
            '&.MuiButton-root': {
              padding: '10px 70px 10px',
              marginRight: '0px',
              marginLeft: '15px',
            },
          }}
          className={classes.button}
          variant="contained"
          disableElevation
          disabled={buttonLoading}
          onClick={onClickAction}
        >
          {!buttonLoading ? (
            confirm
          ) : (
            <CircularProgress color="primary" sx={{ color: '#F4D23F' }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
