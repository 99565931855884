import { gql } from '@apollo/client';

export const CREATE_NEW_ADSTARGET = gql`
  mutation CreateAdsTargetContent($adsTargetContent: AdsTargetContentInput!) {
    createAdsTargetContent(adsTargetContent: $adsTargetContent)
  }
`;

export const DELETE_ADSTARGET = gql`
  mutation DeleteAdsTargetContent($adsTargetContentId: ID!) {
    deleteAdsTargetContent(adsTargetContentId: $adsTargetContentId)
  }
`;

export const UPDATE_ADSTARGET = gql`
  mutation UpdateAdsTargetContent(
    $adsTargetContent: AdsTargetContentInput!
    $adsTargetContentId: ID!
  ) {
    updateAdsTargetContent(
      adsTargetContent: $adsTargetContent
      adsTargetContentId: $adsTargetContentId
    )
  }
`;
