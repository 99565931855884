import { gql } from '@apollo/client';

export const ADD_USER = gql`
  mutation AddAccount($account: AccountInput!) {
    addAccount(account: $account)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteAccount($email: String!) {
    deleteAccount(email: $email)
  }
`;
