import { useMutation } from '@apollo/client';
import HistoryIcon from '@mui/icons-material/History';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnpublishedRoundedIcon from '@mui/icons-material/UnpublishedRounded';
import { Box, Button, FormControl, Typography } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import ConfirmDialog from 'components/ConfirmDialog';
import { RoleContext } from 'components/Layout';
import { toastError, toastSuccess } from 'components/Toast';
import dayjs from 'dayjs';
import {
  PUBLISH_BOOKING,
  RESERVED_BOOKING,
  UNPUBLISH_BOOKING,
  UPDATE_BOOKING,
  UPLOAD_BOOKING_PHOTO,
} from 'graphQL/Bookings/Mutations';
import { GET_ALL_BOOKINGS, GET_BOOKING_BY_ID } from 'graphQL/Bookings/Queries';
import moment from 'moment';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { EStatus } from 'types/globalType';
import useStyles from '../style';
import { renderStatus } from './BookingsTable';
import { question } from './DropdownMenu';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    fontSize: 18,
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function BookingsDetails(props) {
  const {
    isEdit,
    data,
    editBookingData,
    id,
    setEditBookingData,
    detailCompanyData,
    uploadResourceData,
    file,
    setFile,
    setIsEdit,
  } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [totalQuotation, setTotalQuotation] = useState(0);
  const role = useContext(RoleContext);
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const error =
    editBookingData.name === '' ||
    editBookingData.quotationPerDay === '' ||
    moment(editBookingData.endDate).diff(editBookingData.startDate, 'days') < 0;

  const handleUpdate = () => {
    if (!error) {
      setIsUpdate(true);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setIsUpdate(false);
    setOpenDialog(false);
  };

  const [updateBooking] = useMutation(UPDATE_BOOKING, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_BOOKING_BY_ID, variables: { id: id } }],
    onCompleted(res) {
      toastSuccess('Update Booking Success');
      setIsEdit(false);
      setIsUpdate(false);
      setIsLoading(false);
    },
    onError(err) {
      toastError('Update Booking Fail');
      setIsUpdate(false);
    },
  });

  const [uploadBookingResource] = useMutation(UPLOAD_BOOKING_PHOTO, {
    refetchQueries: [{ query: GET_BOOKING_BY_ID, variables: { id: id } }],
    onCompleted(res) {
      setFile([]);
      setIsEdit(false);
      setIsUpdate(false);
    },
    onError(err) {
      setIsUpdate(false);
      setFile([]);
    },
  });

  const [publishBooking] = useMutation(PUBLISH_BOOKING, {
    refetchQueries: [{ query: GET_BOOKING_BY_ID, variables: { id: id } }],
    fetchPolicy: 'network-only',
    onCompleted(response) {
      toastSuccess('Publish Booking Success');
      setOpenDialog(false);
      setIsLoading(false);
    },
    onError(error) {
      toastError('Publish Booking Fail');
      console.log('Publish Failed: ', error);
      setOpenDialog(false);
    },
  });

  const [unpublishBooking] = useMutation(UNPUBLISH_BOOKING, {
    refetchQueries: [{ query: GET_BOOKING_BY_ID, variables: { id: id } }],
    fetchPolicy: 'network-only',
    onCompleted(response) {
      toastSuccess('Unpublish Booking Success');
      setOpenDialog(false);
      setIsLoading(false);
    },
    onError(error) {
      toastError('Unpublish Booking Fail');
      console.log('Unpublish Failed: ', error);
      setOpenDialog(false);
    },
  });

  const [reserveBooking] = useMutation(RESERVED_BOOKING, {
    refetchQueries: [{ query: GET_BOOKING_BY_ID, variables: { id: id } }],
    fetchPolicy: 'network-only',
    onCompleted(response) {
      toastSuccess('Reserved Booking Success');
      setOpenDialog(false);
      setIsLoading(false);
    },
    onError(error) {
      toastError('Reserved Booking Fail');
      console.log('Reserved Failed: ', error);
      setOpenDialog(false);
    },
  });

  const handleEditBooking = () => {
    if (file) {
      file.forEach((fileItem, index) => {
        const variables = {
          advertisingResource: uploadResourceData[index],
          file: fileItem,
        };
        uploadBookingResource({ variables });
      });
    }
    if (editBookingData && id) {
      const variables = {
        advertiseBooking: editBookingData,
        advertiseBookingId: id,
      };
      updateBooking({ variables });
    }
    setIsLoading(true);
  };

  const handleAction = () => {
    const variables = {
      advertiseBookingId: id,
    };
    switch (type) {
      case EStatus.PUBLISHED:
        publishBooking({ variables });
        break;
      case EStatus.DARFT:
        unpublishBooking({ variables });
        break;
      case EStatus.RESERVED:
        reserveBooking({ variables });
        break;
      default:
    }
    setIsLoading(true);
  };

  const onFocusAction = (type) => {
    setType(type);
    setAnchorEl(null);
    setOpenDialog(true);
  };

  useEffect(() => {
    if (data) {
      setTotalQuotation(
        (e) =>
          (e = data.quotationPerDay * (dayjs(data.endDate).diff(dayjs(data.startDate), 'day') + 1)),
      );
    }
  }, [data]);

  return (
    <>
      {!isEdit && detailCompanyData && data ? (
        <Box className={classes.contDetailBox}>
          <FormControl sx={{ mb: '64px' }}>
            <Typography
              className={classes.totalDetail}
            >{`Sub Total: $ ${totalQuotation} `}</Typography>
            <Typography
              sx={{ '&.MuiTypography-root': { fontStyle: 'italic' } }}
              className={classes.totalDetail}
            >
              {`GST (10%): $ ${(totalQuotation * 0.1).toFixed(2)}`}
            </Typography>
            <Typography
              sx={{ '&.MuiTypography-root': { fontWeight: 600 } }}
              className={classes.totalDetail}
            >
              {`Grand Total: $ ${(totalQuotation * 1.1).toFixed(2)}`}
            </Typography>
          </FormControl>
          <FormControl sx={{ mb: '25px' }}>
            <Typography
              sx={{ '&.MuiTypography-root': { fontWeight: 700 } }}
              className={classes.totalDetail}
            >
              Payment Details
            </Typography>
            <Typography className={classes.paymentDetail}>
              {detailCompanyData.companyName}
            </Typography>
            <Typography className={classes.paymentDetail}>
              {' '}
              {detailCompanyData.tradingName}
            </Typography>
            <Typography
              className={classes.paymentDetail}
            >{`ABN ${detailCompanyData.abn}`}</Typography>
          </FormControl>
          <FormControl sx={{ mb: '25px' }}>
            <Typography
              sx={{ '&.MuiTypography-root': { fontWeight: 700 } }}
              className={classes.totalDetail}
            >
              Contact Details
            </Typography>
            <Typography className={classes.paymentDetail}>
              {' '}
              {`${detailCompanyData.contactFirstName} ${detailCompanyData.contactLastName}`}
            </Typography>
            <Typography className={classes.paymentDetail}>
              {' '}
              {detailCompanyData.phoneNumber}
            </Typography>
          </FormControl>
          <FormControl sx={{ mb: '30px' }}>
            <Typography
              sx={{ '&.MuiTypography-root': { fontWeight: 700 } }}
              className={classes.totalDetail}
            >
              Reference No.
            </Typography>
            <Typography className={classes.paymentDetail}>
              {id.slice(0, 6).toUpperCase()}
            </Typography>
          </FormControl>
          <MailOutlineIcon sx={{ fontSize: '41px', mb: '60px' }} />
          <FormControl sx={{ alignItems: 'center' }}>
            <Box
              style={{
                marginBottom: 30,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  '&.MuiTypography-root': {
                    fontWeight: 500,
                    lineHeight: '19,5px',
                    fontSize: '16px',
                  },
                }}
              >
                Status:
              </Typography>
              {renderStatus(data)}
            </Box>
            {role === 'admin' ? (
              <>
                <Typography
                  sx={{
                    '&.MuiTypography-root': {
                      fontWeight: 500,
                      lineHeight: '19,5px',
                      fontSize: '16px',
                    },
                  }}
                >
                  *New Changes Unpublished
                </Typography>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  style={{ fontSize: 18, backgroundColor: '#E36285' }}
                >
                  Action
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseDropDown}
                >
                  <MenuItem
                    disabled={data.status === EStatus.PUBLISHED}
                    onClick={() => onFocusAction(EStatus.PUBLISHED)}
                    style={{ fontSize: 18 }}
                    disableRipple
                  >
                    <TaskAltIcon />
                    Published
                  </MenuItem>
                  <MenuItem
                    disabled={data.status === EStatus.DARFT}
                    onClick={() => onFocusAction(EStatus.DARFT)}
                    style={{ fontSize: 18 }}
                    disableRipple
                  >
                    <UnpublishedRoundedIcon />
                    Unpublished
                  </MenuItem>
                  <MenuItem
                    disabled={data.status === EStatus.RESERVED}
                    onClick={() => onFocusAction(EStatus.RESERVED)}
                    style={{ fontSize: 18 }}
                    disableRipple
                  >
                    <HistoryIcon />
                    Reserved
                  </MenuItem>
                </StyledMenu>
              </>
            ) : (
              <div></div>
            )}
          </FormControl>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Button
            sx={{
              '&.MuiButton-root': {
                paddingRight: '50px',
                paddingLeft: '50px',
                marginLeft: '100px',
                ['@media(max-width: 768px)']: {
                  marginLeft: '0px',
                },
              },
            }}
            className={classes.button}
            variant="contained"
            disableElevation
            onClick={handleUpdate}
          >
            Save
          </Button>
          <ConfirmDialog
            onClickAction={handleEditBooking}
            open={isUpdate}
            buttonLoading={isLoading}
            handleClose={handleClose}
            title="You've made a change"
            question="Save changes?"
            action="update"
            confirm="Yes"
          />
        </Box>
      )}
      <ConfirmDialog
        onClickAction={handleAction}
        open={openDialog}
        handleClose={handleClose}
        title="You are change the status a booking"
        question={question(type)}
        buttonLoading={isLoading}
        action="edit"
        confirm="Yes"
      />
    </>
  );
}
