import { useLazyQuery, useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Box, Button, IconButton, MenuItem, TextField } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import { toastError, toastSuccess } from 'components/Toast';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import { UPDATE_ADSTARGET } from 'graphQL/AdsTarget/Mutation';
import { GET_ALL_ADSTARGETS } from 'graphQL/AdsTarget/Queries';
import { CREATE_NEW_ADSTYPE, DELETE_ADSTYPE, UPDATE_ADSTYPE } from 'graphQL/AdsType/Mutations';
import { GET_ALL_ADSTYPES } from 'graphQL/AdsType/Queries';
import { GET_ALL_BOOKINGS } from 'graphQL/Bookings/Queries';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EStatus } from 'types/globalType';
import { advertisementType } from 'utils/constants';
import * as Yup from 'yup';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete-icon.svg';
import { useStyles } from './style';

const accountSchema = Yup.object().shape({
  name: Yup.string().required('You have to fill this field'),
  viewType: Yup.string().required('Please select a role'),
  maxBookingQuantity: Yup.number().required('You have to fill this field'),
});

interface IProps {
  isSave: boolean;
  setIsSave: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDelete: React.Dispatch<React.SetStateAction<boolean>>;
  isDelete?: boolean;
  isAdd?: boolean;
  handleNonDelete: () => void;
  handleNonEdit: () => void;
  adsTypeDetail?: any;
}

function AdTypeForm({
  isSave,
  setIsSave,
  isDelete,
  handleNonDelete,
  handleNonEdit,
  setIsDelete,
  isAdd,
  adsTypeDetail,
}: IProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [adsTypeData, setAdsTypeData] = React.useState({
    name: '',
    viewType: 'banner',
    description: '',
    maxBookingQuantity: '',
  });

  const formik = useFormik({
    initialValues: adsTypeData,
    validationSchema: accountSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (
        !(
          checkAdsTypeUsed() &&
          Number(formik.values.maxBookingQuantity) < Number(adsTypeDetail.maxBookingQuantity)
        )
      ) {
        try {
          setIsSave(true);
          setAdsTypeData(values);
        } catch (err: any) {
          console.log('false', err);
        }
      }
    },
  });

  const [getAdsTargetLazy, { data: dataTarget }] = useLazyQuery(GET_ALL_ADSTARGETS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {},
  });

  const allTarget = dataTarget?.allAdsTargetContent[0]?.adTypeObjects;

  const [updateAdsTarget] = useMutation(UPDATE_ADSTARGET, {
    fetchPolicy: 'network-only',
    onCompleted(response) {},
  });

  const [createNewAdsType] = useMutation(CREATE_NEW_ADSTYPE, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_ALL_ADSTYPES }],
    onCompleted(response) {
      toastSuccess('Create Ads Type Success');
    },
    onError(error) {
      toastError('Create Ads Type Fail');
      console.log('Add Failed: ', error);
    },
  });

  const [updateAdsType] = useMutation(UPDATE_ADSTYPE, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_ALL_ADSTYPES }],
    onCompleted(response) {
      toastSuccess('Update Ads Type Success');
      navigate(-1);
    },
    onError(error) {
      toastError('Update Ads Type Fail');
      console.log('Add Failed: ', error);
    },
  });

  const onCreateAdsType = () => {
    const variables = {
      advertiseType: adsTypeData,
    };
    createNewAdsType({ variables });
    setIsSave(false);
    navigate(-1);
  };

  const onUpdateAdsType = () => {
    const variables = {
      advertiseType: adsTypeData,
      advertiseTypeId: adsTypeDetail.id,
    };
    updateAdsType({ variables });
  };

  const [deleteAdsType] = useMutation(DELETE_ADSTYPE, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      toastSuccess('Delete Ads Type Success');
      navigate(-1);
    },
    onError(error) {
      toastError('Delete Ads Type Fail');
      console.log('Delete Failed: ', error);
    },
  });

  const handleDeleteAdsType = () => {
    const variables = {
      advertiseTypeId: adsTypeDetail.id,
    };
    deleteAdsType({ variables });

    const temp = allTarget?.filter((target) => {
      return target.adType.id !== adsTypeDetail.id;
    });

    if (temp.length !== allTarget.length) {
      const tempTarget = temp.map((item) => {
        return {
          adTypeID: item.adType.id,
          sort: item.sort,
        };
      });
      const variables = {
        adsTargetContentId: dataTarget?.allAdsTargetContent[0]?.id,
        adsTargetContent: {
          target: dataTarget?.allAdsTargetContent[0]?.target,
          adTypes: tempTarget,
        },
      };

      updateAdsTarget({ variables });
    }
  };

  useEffect(() => {
    if (adsTypeDetail) {
      setAdsTypeData((e) => ({
        ...e,
        name: adsTypeDetail.name,
        description: adsTypeDetail.description,
        viewType: adsTypeDetail.viewType,
        maxBookingQuantity: adsTypeDetail.maxBookingQuantity,
      }));
    }
  }, [adsTypeDetail]);

  const { touched, errors } = formik;

  const handlePress = (event: any) => {
    if (!/[0-9]/.test(event.key) || (/[0]/.test(event.key) && event.target.value === '')) {
      event.preventDefault();
    }
  };

  const now = new Date();

  const [getBookingsData, { data: dataBookings }] = useLazyQuery(GET_ALL_BOOKINGS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getBookingsData();
    getAdsTargetLazy();
  }, [getBookingsData, getAdsTargetLazy]);

  const checkAdsTypeUsed = () => {
    if (dataBookings && adsTypeDetail) {
      const temp = dataBookings?.advertiseBookings?.filter((booking) => {
        return booking.status !== EStatus.DARFT && dayjs(now).diff(dayjs(booking.endDate)) <= 0;
      });
      const checkUsed = temp.filter((item) => {
        return item.adType.id === adsTypeDetail?.id;
      }).length;
      return Boolean(checkUsed);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Box sx={{ width: '500px', marginTop: '40px', display: 'block !important' }}>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Name</Typography>
            <TextField
              fullWidth
              name="name"
              className={classes.input}
              placeholder="Name"
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helpertext,
                },
              }}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={Boolean(touched.name && errors.name)}
              helperText={formik.touched.name ? formik.errors.name : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Description</Typography>
            <TextField
              fullWidth
              name="description"
              className={classes.input}
              placeholder="Description"
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helpertext,
                },
              }}
              value={formik.values.description}
              onChange={formik.handleChange}
              helperText={formik.touched.description ? formik.errors.description : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Max Booking Quantity</Typography>
            <TextField
              fullWidth
              name="maxBookingQuantity"
              className={classes.input}
              type="number"
              placeholder="Max Booking Quantity"
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helpertext,
                },
              }}
              value={formik.values.maxBookingQuantity}
              onChange={formik.handleChange}
              onKeyPress={handlePress}
              error={Boolean(
                (touched.maxBookingQuantity && errors.maxBookingQuantity) ||
                  (touched.maxBookingQuantity &&
                    checkAdsTypeUsed() &&
                    Number(formik.values.maxBookingQuantity) <
                      Number(adsTypeDetail.maxBookingQuantity)),
              )}
              helperText={
                (formik.touched.maxBookingQuantity && formik.errors.maxBookingQuantity) ||
                (formik.touched.maxBookingQuantity &&
                  formik.values.maxBookingQuantity &&
                  checkAdsTypeUsed() &&
                  Number(formik.values.maxBookingQuantity) <
                    Number(adsTypeDetail.maxBookingQuantity) &&
                  'Please enter a value greater than current value')
              }
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>View Type</Typography>
            <TextField
              select
              name="viewType"
              className={classes.selectInput}
              placeholder="View Type"
              sx={{ fontSize: '20px', padding: '0px' }}
              value={formik.values.viewType}
              InputProps={{
                className: classes.input,
              }}
              onChange={formik.handleChange}
              helperText={formik.touched.viewType ? formik.errors.viewType : ''}
            >
              {advertisementType.map((item, idx) => (
                <MenuItem key={idx} sx={{ fontSize: '16px' }} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '62px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isAdd ? (
                <IconButton
                  onClick={() => {
                    setIsDelete(true);
                  }}
                  sx={{ borderRadius: '0%', color: '#000000' }}
                >
                  <DeleteIcon />
                  <Typography className={classes.deleteTitle}>Delete ads type</Typography>
                </IconButton>
              ) : (
                <div></div>
              )}

              <ConfirmDialog
                onClickAction={handleDeleteAdsType}
                open={isDelete}
                handleClose={handleNonDelete}
                title="Permanently"
                question="This ads type"
                action="delete"
                confirm="Yes"
              />
            </Box>
            <Button disableElevation variant="contained" className={classes.button} type="submit">
              {isAdd ? 'Add' : 'Save'}
            </Button>
            {isAdd ? (
              <ConfirmDialog
                onClickAction={onCreateAdsType}
                open={isSave}
                handleClose={handleNonEdit}
                title={!isAdd ? "You've made a change" : "You're adding a new ads type"}
                question={!isAdd ? 'Save changes ?' : 'Add new ads type ?'}
                action="add"
                confirm="Add"
              />
            ) : (
              <ConfirmDialog
                onClickAction={onUpdateAdsType}
                open={isSave}
                handleClose={handleNonEdit}
                title={!isAdd ? "You've made a change" : "You're adding a new ads type"}
                question={!isAdd ? 'Save changes ?' : 'Add new ads type ?'}
                action="edit"
                confirm="Yes"
              />
            )}
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default AdTypeForm;
