import { AdsTarget, AdvertiseViewType, AdvertisingLocation } from '__generated__/globalTypes';

export const appConstants = {
  dateFormat: 'DD/MM/YYYY',
  dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
};

export const selectLocation = [
  {
    value: AdvertisingLocation.NewSouthWales,
    label: 'New South Wales',
  },
  {
    value: AdvertisingLocation.Queensland,
    label: 'Queensland',
  },
  {
    value: AdvertisingLocation.NorthernTerritory,
    label: 'Northern Territory',
  },
  {
    value: AdvertisingLocation.SouthAustralia,
    label: 'South Australia',
  },
  {
    value: AdvertisingLocation.Victoria,
    label: 'Victoria',
  },
  {
    value: AdvertisingLocation.Tasmania,
    label: 'Tasmania',
  },
  {
    value: AdvertisingLocation.WesternAustralia,
    label: 'Western Australia',
  },
];

export const advertisementType = [
  {
    value: AdvertiseViewType.banner,
    label: 'Banner',
  },
  {
    value: AdvertiseViewType.grid,
    label: 'Grid',
  },
  {
    value: AdvertiseViewType.horizontalList,
    label: 'Horizontal List',
  },
  {
    value: AdvertiseViewType.verticalList,
    label: 'Vertical List',
  },
  {
    value: AdvertiseViewType.carasel,
    label: 'Carousel',
  },
];

export const advertisementBookingAdsTarget = [
  {
    value: AdsTarget.merchantApp,
    label: 'Merchant App',
  },
  {
    value: AdsTarget.orderWebsite,
    label: 'Order Website',
  },
  {
    value: AdsTarget.orderbuddyApp,
    label: 'Orderbuddy App',
  },
];

export const dateFormatDefault = 'YYYY/MM/DD';
