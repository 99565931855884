import { gql } from '@apollo/client';

export const GET_ALL_ADSTARGETS = gql`
  query AllAdsTargetContent {
    allAdsTargetContent {
      id
      target
      adTypeObjects {
        adType {
          id
          name
          viewType
          maxBookingQuantity
        }
        sort
      }
    }
  }
`;
