const ROOTS_DASHBOARD = '/dashboard';
export const PATH = {
  dashboard: {
    root: ROOTS_DASHBOARD,
    saleAccounts: {
      root: `${ROOTS_DASHBOARD}/sale-accounts`,
      detail: (id) => `${ROOTS_DASHBOARD}/sale-accounts/${id}`,
      add: `${ROOTS_DASHBOARD}/sale-accounts/add`,
    },
    bookings: {
      root: `${ROOTS_DASHBOARD}/bookings`,
      view: (id) => `${ROOTS_DASHBOARD}/bookings/${id}`,
    },
    calendar: {
      root: `${ROOTS_DASHBOARD}/calendar`,
    },
    companies: {
      root: `${ROOTS_DASHBOARD}/companies`,
      add: `${ROOTS_DASHBOARD}/companies/add`,
      detail: (id) => `${ROOTS_DASHBOARD}/companies/${id}`,
    },
    reports: {
      root: `${ROOTS_DASHBOARD}/reports`,
    },
    adTypes: {
      root: `${ROOTS_DASHBOARD}/advertising-types`,
      detail: (id) => `${ROOTS_DASHBOARD}/advertising-types/${id}`,
      add: `${ROOTS_DASHBOARD}/advertising-types/add`,
    },
    adsTarget: {
      root: `${ROOTS_DASHBOARD}/advertising-target`,
      detail: (id) => `${ROOTS_DASHBOARD}/advertising-target/${id}`,
      add: `${ROOTS_DASHBOARD}/advertising-target/add`,
    },
  },
};
