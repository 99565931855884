import { Box, IconButton, InputBase, Paper, Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './style';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../path';
import { query } from 'firebase/firestore';
import AdTypeTable from './component/AdTypeTable';

export default function AdvertisingTypes(props) {
  const typingTimeout = React.useRef<any>(null);
  const { querySearch } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [tempSearch, setTempSearch] = useState(search);

  const toogleAddAccount = () => {
    navigate(PATH.dashboard.adTypes.add);
  };

  const toogleSearchField = (e: any) => {
    setSearch(e.target.value);
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      setTempSearch(e.target.value);
    }, 500);
  };

  return (
    <div style={{ width: '100%' }}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="h2" className={classes.headerText}>
            Advertising Types
          </Typography>
          <Box className={classes.searchAndAdd} sx={{ flexDirection: 'row' }}>
            <Paper component="form" className={classes.searchPaper}>
              <InputBase style={{ flex: 1 }} placeholder="Search" onChange={toogleSearchField} />
              <SearchIcon sx={{ fontSize: '24px', color: '#747474' }} />
            </Paper>
            <Button onClick={() => toogleAddAccount()} variant="text" className={classes.button}>
              <AddIcon sx={{ fontSize: 40 }} />
              <Typography>Add New Ads Type</Typography>
            </Button>
          </Box>
        </Box>
        <Box marginTop={3}>
          <AdTypeTable search={tempSearch} />
        </Box>
      </Box>
    </div>
  );
}
