import React from 'react';
import { Box, InputBase, Paper, Typography } from '@material-ui/core';
import useStyles from './style';
import SearchIcon from '@mui/icons-material/Search';
import BookingsTable from './component/BookingsTable';

export default function Bookings() {
  const typingTimeout = React.useRef<any>(null);
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [tempSearch, setTempSearch] = React.useState(search);

  const toogleSearchField = (e: any) => {
    setSearch(e.target.value);
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      setTempSearch(e.target.value);
    }, 500);
  };

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="h2" className={classes.headerTitle}>
            Bookings
          </Typography>
          <Paper component="form" className={classes.searchPaper}>
            <InputBase
              style={{ flex: 1 }}
              placeholder="Search"
              onChange={toogleSearchField}
              onKeyDown={onKeyDown}
            />
            <SearchIcon sx={{ fontSize: '24px', color: '#747474' }} />
          </Paper>
        </Box>
        <Box marginTop={3}>
          <BookingsTable search={tempSearch} />
        </Box>
      </Box>
    </Box>
  );
}
