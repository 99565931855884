import { useMutation } from '@apollo/client';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ConfirmDialog from 'components/ConfirmDialog';
import { toastError, toastSuccess } from 'components/Toast';
import dayjs from 'dayjs';
import { DELETE_BOOKING, DELETE_BOOKING_PHOTO } from 'graphQL/Bookings/Mutations';
import { GET_BOOKING_BY_ID } from 'graphQL/Bookings/Queries';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FLOAT } from 'types/globalType';
import { advertisementBookingAdsTarget, selectLocation } from 'utils/constants';
import { handleRegexEvent } from 'utils/regexConfig';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete-icon.svg';
import { PATH } from '../../../path/index';
import useStyles from '../style';
import BookingsUpload from './BookingsUpload';

export default function BookingsForm(props) {
  const {
    isEdit,
    data,
    id,
    setEditBookingData,
    editBookingData,
    uploadResourceData,
    setUploadResourceType,
    companies,
    adTypes,
    setFile,
    file,
    checkDisableAdsType,
  } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const [isDelete, setDelete] = useState(false);
  const [adType, setAdType] = useState('');
  const [location, setLocation] = useState('');
  const [perDayQuotation, setPerDayQuotation] = useState('');
  const [target, setTarget] = useState('');
  const [adDuration, setAdDuration] = useState('');
  const [company, setCompany] = useState('');
  const [removeDialog, setRemoveDialog] = useState(false);
  const [imgId, setImgId] = useState();
  const [name, setName] = useState('');

  const [deleteBooking] = useMutation(DELETE_BOOKING, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      toastSuccess('Delete Booking Success');
      navigate(PATH.dashboard.bookings.root);
    },
    onError(err) {
      toastError('Delete Booking Fail');
    },
  });

  const handleChangeAdType = (event: SelectChangeEvent) => {
    const adTypeId = adTypes.filter((e) => e.name === event.target.value)[0].id;
    setAdType(event.target.value);
    setEditBookingData((e) => ({
      ...e,
      adTypeId: adTypeId,
    }));
  };

  const [deleteBookingResource] = useMutation(DELETE_BOOKING_PHOTO, {
    refetchQueries: [{ query: GET_BOOKING_BY_ID, variables: { id: id } }],
    onCompleted(res) {
      setRemoveDialog(false);
      toastSuccess('Remove Image Success');
    },
    onError(error) {
      toastError('Remove Image Fail');
    },
  });

  const handleRemoveDialogOpen = (e) => {
    setRemoveDialog(true);
    setImgId(e);
  };

  const handleRemoveResource = () => {
    const variables = {
      advertisingResourceId: imgId,
    };
    deleteBookingResource({ variables });
    setRemoveDialog(false);
  };

  const handleChangeLocation = (event: SelectChangeEvent) => {
    setLocation(event.target.value);
    setEditBookingData((e) => ({
      ...e,
      location: event.target.value,
    }));
  };

  const handleChangeName = (event: any) => {
    setName(event.target.value);
    setEditBookingData((e) => ({
      ...e,
      name: event.target.value,
    }));
  };

  const handleClose = () => {
    setDelete(false);
    setRemoveDialog(false);
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue);
    setEditBookingData((e) => ({
      ...e,
      startDate: newValue,
    }));
  };

  const errorDate = moment(endDate).diff(startDate, 'days') < 0;

  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
    setEditBookingData((e) => ({
      ...e,
      endDate: newValue,
    }));
  };

  const handleChangeCompany = (newValue) => {
    setCompany(newValue.target.value);
    const temp = companies.find((company) => {
      return company.companyName === newValue.target.value;
    });
    setEditBookingData((e) => ({
      ...e,
      companyName: newValue.target.value,
      companyId: temp.id,
    }));
  };

  const handleChangeAdQuotation = (event) => {
    setPerDayQuotation(event);
    setEditBookingData((e) => ({
      ...e,
      quotationPerDay: parseInt(event, 10),
    }));
  };

  const handleChangeTarget = (event) => {
    setTarget(event.target.value);
    setEditBookingData((e) => ({
      ...e,
      target: event.target.value,
    }));
  };

  const handleDeleteBooking = () => {
    if (id) {
      const variables = {
        advertiseBookingId: id,
      };
      deleteBooking({ variables });
    }
    setDelete(false);
  };

  useEffect(() => {
    if (data) {
      setPerDayQuotation((e) => data.quotationPerDay);
      setLocation((e) => data.location);
      setAdType((e) => data.adType.name);
      setCompany((e) => data.company.companyName);
      setTarget((e) => data.target);
      setName((e) => data.name);
      setAdDuration(
        (e) =>
          `${dayjs(data.startDate).format('DD MMM')} - ${dayjs(data.endDate).format(
            'DD MMM YYYY',
          )} (${dayjs(data.endDate).diff(dayjs(data.startDate), 'day') + 1} days)`,
      );
      setStartDate((e) => data.startDate);
      setEndDate((e) => data.endDate);
    }
  }, [data]);

  return (
    <Paper className={classes.paper}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box className={classes.gridBox}>
          <FormControl className={classes.formControl} sx={{ marginTop: '21px' }}>
            <Typography className={classes.inputTitle}>Name</Typography>
            <TextField
              InputProps={{ style: { fontSize: 18, fontWeight: 500, height: '50px' } }}
              value={name}
              disabled={!isEdit}
              sx={{ fontSize: '21px', marginBottom: name === '' ? 0 : '28px' }}
              fullWidth
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              error={name === ''}
              helperText={name === '' && 'Name is required'}
              onChange={handleChangeName}
            />
            <Typography className={classes.inputTitle}>Ads Type</Typography>
            <Select
              value={adType}
              disabled={!isEdit}
              onChange={handleChangeAdType}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ fontSize: '21px' }}
              IconComponent={(props) => (
                <KeyboardArrowDownIcon {...props} sx={{ fontSize: '24px' }} />
              )}
              style={{ height: '50px' }}
            >
              {adTypes?.map((item, idx) => {
                return (
                  <MenuItem
                    key={idx}
                    sx={{ fontSize: '16px' }}
                    disabled={checkDisableAdsType[idx]}
                    value={item.name}
                  >
                    {item.name}{' '}
                    {checkDisableAdsType[idx] && (
                      <Chip
                        style={{ marginLeft: '10px', fontSize: '16px' }}
                        label="Sold out"
                        size="medium"
                      ></Chip>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
            {adTypes?.filter((e) => e.name === adType).length === 0 && (
              <FormHelperText sx={{ fontSize: '14px', color: 'red' }}>
                Current Ads Type is not available, please update new Ads Type !
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.formControl} sx={{ marginTop: '21px' }}>
            <Typography className={classes.inputTitle}>Per Day Quotation ($)</Typography>
            <TextField
              InputProps={{ style: { fontSize: 18, fontWeight: 500, height: '50px' } }}
              value={perDayQuotation}
              disabled={!isEdit}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              error={perDayQuotation === ''}
              helperText={perDayQuotation === '' && 'Per Day Quotation is required'}
              sx={{ fontSize: '21px', marginBottom: perDayQuotation === '' ? 0 : '28px' }}
              onChange={(e) => handleChangeAdQuotation(handleRegexEvent(e, FLOAT))}
            />
            <Typography className={classes.inputTitle}>Trading Name</Typography>
            <Select
              value={company}
              disabled={!isEdit}
              onChange={handleChangeCompany}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ fontSize: '21px' }}
              IconComponent={(props) => (
                <KeyboardArrowDownIcon {...props} sx={{ fontSize: '24px' }} />
              )}
              style={{ height: '50px' }}
            >
              {companies?.map((item, idx) => {
                return (
                  <MenuItem sx={{ fontSize: '16px' }} key={idx} value={item.companyName}>
                    {item.tradingName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.gridBox}>
          <Box className={classes.formControl}>
            <FormControl sx={{ width: '100%' }}>
              {!isEdit ? (
                <FormControl sx={{ marginBottom: '21px' }}>
                  <Typography className={classes.inputTitle}>Ads Duration</Typography>
                  <TextField
                    InputProps={{ style: { fontSize: 18, fontWeight: 500, height: '50px' } }}
                    disabled={!isEdit}
                    value={adDuration}
                    sx={{ fontSize: '21px' }}
                    onChange={(e) => setAdDuration(e.target.value)}
                  />
                </FormControl>
              ) : (
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <FormControl sx={{ marginBottom: '21px' }}>
                    <Typography className={classes.inputTitle}>Start Date</Typography>
                    <DesktopDatePicker
                      inputFormat="yyyy-MMM-DD"
                      disableMaskedInput={true}
                      value={startDate}
                      disabled={editBookingData.isDefaultBooking}
                      InputProps={{ style: { fontSize: 18, fontWeight: 500, height: '50px' } }}
                      onChange={handleChangeStartDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errorDate}
                          FormHelperTextProps={{
                            classes: {
                              root: classes.helperText,
                            },
                          }}
                          helperText={errorDate && 'Invalid Date'}
                        />
                      )}
                      components={{
                        OpenPickerIcon: CalendarTodayOutlinedIcon,
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ marginBottom: '21px' }}>
                    <Typography className={classes.inputTitle}>End Date</Typography>
                    <DesktopDatePicker
                      inputFormat="yyyy-MMM-DD"
                      disableMaskedInput={true}
                      value={endDate}
                      disabled={editBookingData.isDefaultBooking}
                      InputProps={{ style: { fontSize: 18, fontWeight: 500, height: '50px' } }}
                      onChange={handleChangeEndDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errorDate}
                          FormHelperTextProps={{
                            classes: {
                              root: classes.helperText,
                            },
                          }}
                          helperText={errorDate && 'Invalid Date'}
                        />
                      )}
                      components={{
                        OpenPickerIcon: CalendarTodayOutlinedIcon,
                      }}
                    />
                  </FormControl>
                </LocalizationProvider>
              )}
              <FormControl sx={{ marginBottom: '21px' }}>
                <Typography className={classes.inputTitle}>Location</Typography>
                <Select
                  value={location}
                  disabled={!isEdit}
                  onChange={handleChangeLocation}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{ fontSize: '21px' }}
                  IconComponent={(props) => (
                    <KeyboardArrowDownIcon {...props} sx={{ fontSize: '24px' }} />
                  )}
                  style={{ height: '50px' }}
                >
                  {selectLocation.map((item, idx) => (
                    <MenuItem sx={{ fontSize: '16px' }} key={idx} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormControl>
          </Box>
          <Box className={classes.formControl}>
            <FormControl fullWidth sx={{ marginBottom: '21px' }}>
              <Typography className={classes.inputTitle}>Target</Typography>
              <Select
                fullWidth
                value={target}
                disabled={!isEdit}
                onChange={handleChangeTarget}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ fontSize: '21px' }}
                IconComponent={(props) => (
                  <KeyboardArrowDownIcon {...props} sx={{ fontSize: '24px' }} />
                )}
                style={{ height: '50px' }}
              >
                {advertisementBookingAdsTarget.map((item, idx) => (
                  <MenuItem sx={{ fontSize: '16px' }} key={idx} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: '73px', mb: 10 }}>
        <BookingsUpload
          isEdit={isEdit}
          data={data}
          id={id}
          uploadResourceData={uploadResourceData}
          setUploadResourceType={setUploadResourceType}
          setFile={setFile}
          handleRemoveDialogOpen={handleRemoveDialogOpen}
          file={file}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className={classes.salePerson}>Salesperson: {data?.salesName}</Typography>
        {isEdit && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={() => {
                setDelete(true);
              }}
              sx={{ borderRadius: '0%', color: '#000000' }}
            >
              <DeleteIcon />
              <Typography className={classes.deleteTitle}>Delete Booking?</Typography>
            </IconButton>
            <ConfirmDialog
              onClickAction={handleDeleteBooking}
              open={isDelete}
              handleClose={handleClose}
              title="Permanently"
              question="Delete this booking ?"
              action="delete"
              confirm="Yes"
            />
          </Box>
        )}
      </Box>
      <ConfirmDialog
        open={removeDialog}
        handleClose={handleClose}
        onClickAction={handleRemoveResource}
        confirm="Remove"
        title="You're going to remove a Resource"
        question="Confirm Remove?"
      />
    </Paper>
  );
}
