import { useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Box, Button, IconButton, MenuItem, TextField } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import { toastError, toastSuccess } from 'components/Toast';
import { Form, FormikProvider, useFormik } from 'formik';
import { ADD_USER, DELETE_USER } from 'graphQL/Accounts/Mutation';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { GET_ALL_ACCOUNTS } from '../../graphQL/Accounts/Queries';
import { useStyles } from './style';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const accountSchema = Yup.object().shape({
  firstName: Yup.string().required('You have to fill this field'),
  lastName: Yup.string().required('You have to fill this field'),
  email: Yup.string().email('Must be a valid email').required('You have to fill this field'),
  password: Yup.string()
    .required('You have to fill this field')
    .min(6, 'Password must be minimum 6+'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('You have to fill this field'),
  role: Yup.string().required('Please select a role'),
});

interface IProps {
  isSave: boolean;
  setIsSave: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDelete: React.Dispatch<React.SetStateAction<boolean>>;
  isDelete?: boolean;
  isAdd?: boolean;
  handleNonDelete: () => void;
  handleNonEdit: () => void;
  editUserData?: any;
}

function AddOrEditForm({
  isSave,
  setIsSave,
  isDelete,
  handleNonDelete,
  handleNonEdit,
  setIsDelete,
  isAdd,
  editUserData,
}: IProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    role: 'admin',
  });

  const formik = useFormik({
    initialValues: userData,
    validationSchema: accountSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsSave(true);
        setUserData(values);
      } catch (err: any) {
        console.log('false', err);
      }
    },
  });

  const [createNewUser] = useMutation(ADD_USER, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_ALL_ACCOUNTS }],
    onCompleted(response) {
      toastSuccess(isAdd ? 'Create User Success' : 'Update User Success');
      navigate(-1);
    },
    onError(error) {
      toastSuccess(isAdd ? 'Create User Fail' : 'Update User Fail');
      console.log('Add Failed: ', error);
    },
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (!isDelete) {
        const variables = {
          account: userData,
        };
        createNewUser({ variables });
      } else {
        navigate(-1);
        toastSuccess('Delete User Success');
      }
    },
    onError(error) {
      if (!isDelete) {
        toastError('Update User Fail');
      } else {
        toastError('Delete User Fail');
      }
      console.log('Delete Failed: ', error);
    },
  });

  const onCreateNewUser = () => {
    if (editUserData) {
      const variables = {
        email: editUserData.email,
      };
      deleteUser({ variables });
    } else {
      const variables = {
        account: userData,
      };
      createNewUser({ variables });
    }
    setIsSave(false);
  };

  const onDeleteUser = () => {
    const variables = {
      email: editUserData.email,
    };
    deleteUser({ variables });
    setIsSave(false);
  };

  useEffect(() => {
    if (editUserData) {
      setUserData((e) => ({
        ...e,
        firstName: editUserData.firstName,
        lastName: editUserData.lastName,
        phoneNumber: editUserData.phoneNumber,
        email: editUserData.email,
        password: '',
        role: editUserData.role,
      }));
    }
  }, [editUserData]);

  const { touched, errors } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Box sx={{ width: '500px', marginTop: '40px', display: 'block !important' }}>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>First Name</Typography>
            <TextField
              fullWidth
              name="firstName"
              className={classes.input}
              placeholder="First Name"
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helpertext,
                },
              }}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={formik.touched.firstName ? formik.errors.firstName : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Last Name</Typography>
            <TextField
              fullWidth
              name="lastName"
              className={classes.input}
              placeholder="Last Name"
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helpertext,
                },
              }}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={formik.touched.lastName ? formik.errors.lastName : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Phone Number</Typography>
            <TextField
              fullWidth
              name="phoneNumber"
              className={classes.input}
              placeholder="Phone Number"
              sx={{ fontSize: '20px' }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helpertext,
                },
              }}
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={formik.touched.phoneNumber ? formik.errors.phoneNumber : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Email</Typography>
            <TextField
              fullWidth
              name="email"
              className={classes.input}
              placeholder="Email"
              sx={{ fontSize: '20px' }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helpertext,
                },
              }}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={Boolean(touched.email && errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Password</Typography>
            <TextField
              fullWidth
              name="password"
              className={classes.input}
              placeholder="Password"
              sx={{ fontSize: '20px' }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helpertext,
                },
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={Boolean(touched.password && errors.password)}
              helperText={formik.touched.password ? formik.errors.password : ''}
            />
          </Box>
          {isAdd ? (
            <Box sx={{ marginTop: '17px' }}>
              <Typography className={classes.inputHeader}>Role</Typography>
              <TextField
                select
                name="role"
                className={classes.selectInput}
                placeholder="Password"
                sx={{ fontSize: '20px', padding: '0px' }}
                value={formik.values.role}
                InputProps={{
                  className: classes.input,
                }}
                onChange={formik.handleChange}
                helperText={formik.touched.role ? formik.errors.role : ''}
              >
                <MenuItem className={classes.menuItem} value={'admin'}>
                  Admin
                </MenuItem>
                <MenuItem className={classes.menuItem} value={'agent'}>
                  Agent
                </MenuItem>
              </TextField>
            </Box>
          ) : (
            <div></div>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '62px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isAdd ? (
                <IconButton
                  onClick={() => {
                    setIsDelete(true);
                  }}
                  sx={{ borderRadius: '0%', color: '#000000' }}
                >
                  <DeleteIcon />
                  <Typography className={classes.deleteTitle}>Delete account</Typography>
                </IconButton>
              ) : null}
              <ConfirmDialog
                onClickAction={onDeleteUser}
                open={isDelete}
                handleClose={handleNonDelete}
                title="Permanently"
                question="This account"
                action="delete"
                confirm="Yes"
              />
            </Box>
            <Button disableElevation variant="contained" className={classes.button} type="submit">
              {isAdd ? 'Add' : 'Save'}
            </Button>
            <ConfirmDialog
              onClickAction={onCreateNewUser}
              open={isSave}
              handleClose={handleNonEdit}
              title={!isAdd ? "You've made a change" : "You're adding a new account"}
              question={!isAdd ? 'Save changes ?' : 'Add new user ?'}
              action="add"
              confirm="Add"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default AddOrEditForm;
