import { useLazyQuery, useMutation } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem, MenuList } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import { RoleContext } from 'components/Layout';
import { toastError, toastSuccess } from 'components/Toast';
import { DELETE_COMPANY } from 'graphQL/Companies/Mutation';
import { GET_ALL_COMPANIES } from 'graphQL/Companies/Queries';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../path/index';
import useStyles from '../style';

function DropdownMenu(props) {
  const testRole = useContext(RoleContext);
  const { row, setList, list } = props;
  const [companyId, setCompanyId] = useState('');
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAccountDetail = (id: string) => {
    navigate(PATH.dashboard.companies.detail(id));
    setAnchorEl(null);
  };

  const [getAllCompanies] = useLazyQuery(GET_ALL_COMPANIES, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setList(response.companies);
    },
    onError(error) {
      console.log('Login Failed: ', error);
    },
  });
  const [deleteCompany] = useMutation(DELETE_COMPANY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      toastSuccess('Delete Company Success');
      getAllCompanies();
    },
    onError(error) {
      toastError('Delete Company Fail');
      console.log('Delete Failed: ', error);
    },
  });

  const getCompanyId = (e) => {
    setCompanyId(e);
    setIsDialogOpen(true);
  };

  const handleDeleteCompany = () => {
    const variables = {
      companyId: companyId,
    };
    deleteCompany({ variables });
  };

  const adminAction = {
    element: (
      <>
        <MenuList>
          <MenuItem className={classes.menuItem} onClick={() => onAccountDetail(row.id)}>
            Company Details
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={() => getCompanyId(row.id)}>
            Delete Company
          </MenuItem>
        </MenuList>
      </>
    ),
  };

  const agentAction = {
    element: (
      <>
        <MenuItem className={classes.menuItem} onClick={() => onAccountDetail(row.id)}>
          Company Details
        </MenuItem>
      </>
    ),
  };

  return (
    <>
      <Box>
        <IconButton onClick={handleClick}>
          <MoreVertIcon sx={{ fontSize: 25 }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {testRole === 'admin' ? adminAction.element : agentAction.element}
        </Menu>
        <ConfirmDialog
          onClickAction={handleDeleteCompany}
          open={isDialogOpen}
          handleClose={handleCloseDialog}
          title={"You're permanent"}
          question={'Confirm delete ?'}
          action="delete"
          confirm="Yes"
        />
      </Box>
    </>
  );
}

export default DropdownMenu;
