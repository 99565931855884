import { useLazyQuery, useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';
import {
  Box,
  CircularProgress,
  LinearProgress,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ConfirmDialog from 'components/ConfirmDialog';
import LabelSortIcon from 'components/LabelSortIcon';
import { toastError, toastSuccess } from 'components/Toast';
import dayjs from 'dayjs';
import { DELETE_ADSTARGET, UPDATE_ADSTARGET } from 'graphQL/AdsTarget/Mutation';
import { GET_ALL_ADSTARGETS } from 'graphQL/AdsTarget/Queries';
import { GET_ALL_BOOKINGS } from 'graphQL/Bookings/Queries';
import _ from 'lodash';
import { renderStatus } from 'pages/Bookings/component/BookingsTable';
import React, { Fragment, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { EStatus, EStatusDisplay } from 'types/globalType';
import { ascSort, descSort } from 'utils/businessLogic';
import { compareDateBooking } from 'utils/compareDateBooking';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E36285',
    color: theme.palette.common.black,
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '34px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: '400',
    fontSize: '16px',
    backgroundColor: '#E5E5E5',
    lineHeight: '22px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 9,
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function AdTargetTable({ list, adTypeArr, setAdTypeArr }) {
  const [openDeleteAdTypeDiaglog, setOpenDeleteAdTypeDiaglog] = useState(false);
  const [openDeleteAdTargetDialog, setOpenDeleteAdTargetDialog] = useState(false);
  const [bookingsData, setBookingsData] = useState<any[]>([]);
  const [dataNoneSort, setDataNoneSort] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [fieldSort, setFieldSort] = useState('');
  const [sortBy, setSortBy] = useState('');

  const [updateAdsTarget] = useMutation(UPDATE_ADSTARGET, {
    refetchQueries: [{ query: GET_ALL_ADSTARGETS }],
    onCompleted(res) {},
  });

  const [deleteAdsTarget] = useMutation(DELETE_ADSTARGET, {
    onCompleted(res) {
      toastSuccess('Delete Ads Target Success');
      setOpenDeleteAdTargetDialog(false);
    },
    onError(error) {
      toastError('Delete Ads Target Fail');
    },
  });

  const [getBookingsData, { data: dataBookings }] = useLazyQuery(GET_ALL_BOOKINGS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setLoading(false);
    },
    onError(err) {
      setLoading(false);
    },
  });

  const handleClose = () => {
    setOpenDeleteAdTypeDiaglog(false);
    setOpenDeleteAdTargetDialog(false);
  };

  const handleDeleteAdsTarget = () => {
    const variables = {
      adsTargetContentId: list[0].id,
    };
    deleteAdsTarget({ variables });
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items: any = reorder(adTypeArr, result.source.index, result.destination.index);
    setAdTypeArr(items);
    let arr: any = [];
    items.forEach((e, index) => {
      arr = [...arr, { adTypeID: e.adType.id, sort: index + 1 }];
    });
    const variables = {
      adsTargetContentId: list[0].id,
      adsTargetContent: {
        target: list[0].target,
        adTypes: arr,
      },
    };
    updateAdsTarget({ variables });
  };

  useEffect(() => {
    if (dataBookings?.advertiseBookings) {
      const listFilter = dataBookings?.advertiseBookings.map((item: any) => {
        return {
          ...item,
          adType: item.adType.name,
          companyName: item.company.tradingName,
          statusDisplay: compareDateBooking(item).status,
        };
      });
      const temp =
        fieldSort !== ''
          ? sortBy === 'asc'
            ? listFilter.sort(ascSort(fieldSort))
            : listFilter.sort(descSort(fieldSort))
          : listFilter;
      setBookingsData(temp);
      setDataNoneSort(listFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBookings?.advertiseBookings]);

  useEffect(() => {
    getBookingsData();
  }, [getBookingsData]);

  const bookingRender = () => {
    const temp = bookingsData?.filter((item) => {
      return [EStatusDisplay.PUBLISHED, EStatusDisplay.ACTIVE, EStatusDisplay.RESERVED].includes(
        compareDateBooking(item)?.status,
      );
    });
    return temp || [];
  };

  return (
    <>
      {list?.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                <Box sx={{ pb: 5 }}>
                  <Typography style={{ fontWeight: 700, fontSize: '2rem' }}>{`Target: ${_.startCase(
                    list[0].target,
                  )}`}</Typography>
                </Box>
                {!loading ? (
                  <Box
                    sx={{
                      p: 2,
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ width: '40%' }}>
                      <Typography style={{ fontWeight: 700, fontSize: '2rem' }}>
                        Ads Types:
                      </Typography>
                      {adTypeArr?.map((item, index) => (
                        <Draggable
                          key={item.adType?.id}
                          draggableId={item.adType?.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Box
                              sx={{
                                border: '1px solid gray',
                                borderRadius: '20px',
                                my: 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box sx={{ mx: 3, py: 2 }}>
                                <List>
                                  <ListItem>
                                    <Box sx={{ width: '150px' }}>
                                      <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
                                        Name:
                                      </Typography>
                                    </Box>
                                    <Typography style={{ fontSize: '20px', fontWeight: '400' }}>
                                      {item.adType.name}
                                    </Typography>
                                  </ListItem>
                                  <ListItem>
                                    <Box sx={{ width: '150px' }}>
                                      <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
                                        View Type:
                                      </Typography>
                                    </Box>
                                    <Typography style={{ fontSize: '20px', fontWeight: '400' }}>
                                      {_.startCase(item.adType.viewType)}
                                    </Typography>
                                  </ListItem>
                                  <ListItem>
                                    <Box sx={{ width: '150px' }}>
                                      <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
                                        Priority:
                                      </Typography>
                                    </Box>
                                    <Typography style={{ fontSize: '20px', fontWeight: '400' }}>
                                      {item.sort}
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Box>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'column', width: '55%' }}>
                      <Typography
                        style={{ fontSize: '2rem', marginBottom: '10px', fontWeight: 700 }}
                      >
                        Bookings Published :
                      </Typography>
                      <TableContainer style={{ border: '2px solid #E36285', borderRadius: '5px' }}>
                        <Table
                          sx={{ minWidth: 700, borderRadius: '0px' }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <LabelSortIcon
                                  title="Company"
                                  field="companyName"
                                  list={bookingsData}
                                  setList={setBookingsData}
                                  dataNoneSort={dataNoneSort}
                                  setSortBy={setSortBy}
                                  setFieldSort={setFieldSort}
                                />
                              </StyledTableCell>
                              <StyledTableCell>
                                <LabelSortIcon
                                  title="Ads type"
                                  field="adType"
                                  list={bookingsData}
                                  setList={setBookingsData}
                                  dataNoneSort={dataNoneSort}
                                  setSortBy={setSortBy}
                                  setFieldSort={setFieldSort}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <LabelSortIcon
                                  title="Duration"
                                  field="startDate"
                                  list={bookingsData}
                                  setList={setBookingsData}
                                  dataNoneSort={dataNoneSort}
                                  setSortBy={setSortBy}
                                  setFieldSort={setFieldSort}
                                />
                              </StyledTableCell>
                              <StyledTableCell>
                                <LabelSortIcon
                                  title="Sales Person"
                                  field="salesName"
                                  list={bookingsData}
                                  setList={setBookingsData}
                                  dataNoneSort={dataNoneSort}
                                  setSortBy={setSortBy}
                                  setFieldSort={setFieldSort}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <LabelSortIcon
                                  title="Status"
                                  field="statusDisplay"
                                  list={bookingsData}
                                  setList={setBookingsData}
                                  dataNoneSort={dataNoneSort}
                                  setSortBy={setSortBy}
                                  setFieldSort={setFieldSort}
                                />
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{ paddingTop: '32px' }}>
                            <TableRow sx={{ height: '20px' }}></TableRow>
                            {bookingRender()?.length > 0 ? (
                              bookingRender()?.map((row, index) => (
                                <Fragment key={row.id}>
                                  <StyledTableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        backgroundColor: '#E5E5E5',
                                        lineHeight: '22px',
                                      }}
                                    >
                                      {row.company.tradingName}
                                    </TableCell>
                                    <StyledTableCell align="left">{row?.adType}</StyledTableCell>
                                    <StyledTableCell align="left">
                                      {`${dayjs(row.startDate).format('DD MMM')} - ${dayjs(
                                        row.endDate,
                                      ).format('DD MMM')}`}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{row.salesName}</StyledTableCell>
                                    <StyledTableCell align="left">
                                      {renderStatus(row)}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <TableRow sx={{ height: '7px' }}></TableRow>
                                </Fragment>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                  <Typography variant="h4">No booking published!</Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                ) : (
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <LinearProgress />
      )}

      <ConfirmDialog
        open={openDeleteAdTypeDiaglog}
        handleClose={handleClose}
        title="Permanently"
        question="Delete this adType ?"
        action="delete"
        confirm="Yes"
      />
      <ConfirmDialog
        onClickAction={handleDeleteAdsTarget}
        open={openDeleteAdTargetDialog}
        handleClose={handleClose}
        title="Permanently"
        question="Delete thisasdasdas adTarget ?"
        action="delete"
        confirm="Yes"
      />
    </>
  );
}
