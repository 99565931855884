import { Box } from '@material-ui/core';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SortIcon } from '../../assets/images/sort-icon.svg';

interface IProps {
  title: string;
  field: string;
  list: any[];
  setList: (list: any) => void;
  dataNoneSort: any[];
  setSortBy?: (sortBy: string) => void;
  setFieldSort?: (fieldSort: string) => void;
}

const LabelSortIcon = ({
  title,
  field,
  list,
  setList,
  dataNoneSort,
  setSortBy,
  setFieldSort,
}: IProps) => {
  const newList = _.cloneDeep(list);
  const [sortState, setSortState] = useState(field === 'startDate' ? 'desc' : 'none');

  const ascSort = (sortBy) => (a, b) =>
    a[sortBy]?.toLowerCase() > b[sortBy]?.toLowerCase() ? 1 : -1;
  const descSort = (sortBy) => (a, b) =>
    a[sortBy]?.toLowerCase() < b[sortBy]?.toLowerCase() ? 1 : -1;

  const tableItemSort = (field) => {
    if (setFieldSort) {
      setFieldSort(field);
    }
    if (sortState === 'none') {
      setSortState('asc');
      if (setSortBy) {
        setSortBy('asc');
      }
      setList(newList.sort(ascSort(field)));
    } else if (sortState === 'desc') {
      setSortState('none');
      if (setSortBy) {
        setSortBy('none');
      }
      setList(dataNoneSort);
    } else {
      setSortState('desc');
      if (setSortBy) {
        setSortBy('desc');
      }
      setList(newList.sort(descSort(field)));
    }
  };

  useEffect(() => {
    setList(newList.sort(descSort('startDate')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {title}&ensp;
      {sortState === 'asc' ? (
        <KeyboardDoubleArrowUpIcon
          sx={{ fontSize: 30, cursor: 'pointer' }}
          onClick={() => tableItemSort(field)}
        />
      ) : sortState === 'desc' ? (
        <KeyboardDoubleArrowDownIcon
          sx={{ fontSize: 30, cursor: 'pointer' }}
          onClick={() => tableItemSort(field)}
        />
      ) : (
        <SortIcon style={{ cursor: 'pointer' }} onClick={() => tableItemSort(field)} />
      )}
    </Box>
  );
};

export default LabelSortIcon;
