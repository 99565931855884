/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-var */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Divider,
  InputBase,
  FormControl,
} from '@mui/material';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from 'react-i18next';

export default function UploadImageCrop(props: any) {
  const {
    open,
    type,
    handleClose,
    handleSelectBannerChiFile,
    handleSelectBannerEngFile,
    setExternalUrl,
    externalUrl,
  } = props;
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    handleClose();
  };

  const [upImg, setUpImg] = useState<any>();
  const imgRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const previewCanvasRef = useRef<any>(null);
  const [crop, setCrop] = useState<any>({ unit: '%', width: 30, aspect: 4 / 3 });
  const [completedCrop, setCompletedCrop] = useState<any>(null);
  const [isImage, setIsImage] = useState(true);
  const [nameImage, setNameImage] = useState('');

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files[0].type.includes('image') && e.target.files.length > 0) {
      setIsImage(true);
      setNameImage(e.target.files[0].name);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setUpImg('');
      setIsImage(false);
    }
  };

  function generateDownload(canvas: any, crop: any) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      async (blob: any) => {
        await setIsLoading(true);
        const previewUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = 'cropPreview.png';
        anchor.href = URL.createObjectURL(blob);
        await onUploadButton(blob);
        await setIsLoading(false);
        handleClose();
        window.URL.revokeObjectURL(previewUrl);
      },
      'image/png',
      1,
    );
  }

  const onUploadButton = (blob: any) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');
    var formdata = new FormData();
    if (!!blob) {
      var formatFile = new File([blob], nameImage, {
        type: 'image/png',
        lastModified: new Date().getTime(),
      });
      formdata.append('file', formatFile);
      if (type === 'ENG') {
        handleSelectBannerEngFile(formatFile);
      } else {
        handleSelectBannerChiFile(formatFile);
      }
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
  }, [completedCrop]);

  const handleChangeExternalUrl = (e: any) => {
    setExternalUrl(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        color="primary"
        style={{ fontSize: '22px', color: '#E36285' }}
      >
        {t('Upload Image')}
      </DialogTitle>
      <DialogContent>
        <div className="App" style={{ marginTop: '1rem', minWidth: '500px', minHeight: '600px' }}>
          <div>
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              {t('Please choose the file to upload')}
            </Typography>
            <Box
              style={{ marginTop: '0.5rem', width: '100%', borderRadius: '4px' }}
              borderColor="primary"
            >
              <Button
                variant="contained"
                component="label"
                style={{ fontSize: '14px', backgroundColor: '#E36285' }}
              >
                {t('Upload Image')}
                <input type="file" hidden accept="image/*" onChange={onSelectFile} />
              </Button>
            </Box>
            {!isImage && (
              <Typography
                variant="caption"
                sx={{
                  mt: 2,
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'left',
                  color: 'red',
                  fontSize: '16px',
                }}
              >
                {t('Invalid File! Please try again!')}
              </Typography>
            )}
            <Typography
              variant="caption"
              sx={{
                mt: 2,
                mx: 'auto',
                display: 'block',
                textAlign: 'left',
                color: 'text.secondary',
                fontSize: '16px',
              }}
            >
              {`${t('Allowed')} *.jpeg, *.jpg, *.png, *.gif`}
            </Typography>
          </div>
          {isImage && (
            <>
              {upImg && (
                <div style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
                  <Divider />
                  <Typography marginTop={1} variant="body2" style={{ fontSize: '16px' }}>
                    {t('Choose position and resize this image')}
                  </Typography>
                </div>
              )}
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c: any) => setCrop(c)}
                onComplete={(c: any) => setCompletedCrop(c)}
                style={{ width: 'auto', height: 'cover', maxWidth: '400px' }}
              />
              <div>
                {upImg && (
                  <div style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
                    <Divider />
                    <Typography marginTop={1} variant="body2" style={{ fontSize: '16px' }}>
                      {t('Your Image')}
                    </Typography>
                  </div>
                )}
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    width: upImg ? 400 : 0,
                    height: upImg ? 300 : 0,
                  }}
                />
              </div>
            </>
          )}
        </div>
        {completedCrop && (
          <FormControl sx={{ marginTop: '21px', width: '100%' }}>
            <Typography
              sx={{
                '&.MuiTypography-root': {
                  fontWeight: 700,
                  fontSize: '15px',
                  lineHeight: '26px',
                },
              }}
            >
              External Url
            </Typography>
            <InputBase
              value={externalUrl}
              sx={{
                width: '100%',
                borderRadius: '7px',
                padding: '5px 10px',
                fontSize: '20px',
                boxShadow:
                  'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                background: '#E5E5E5',
                '&.Mui-disabled': {
                  background: '#fff',
                },
              }}
              onChange={handleChangeExternalUrl}
            />
          </FormControl>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '24px' }}>
        <Button
          variant="contained"
          onClick={handleCloseDialog}
          style={{ fontSize: '16px', backgroundColor: '#E36285' }}
        >
          {t('Cancel')}
        </Button>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            disabled={!completedCrop?.width || !completedCrop?.height}
            onClick={() => generateDownload(previewCanvasRef.current, completedCrop)}
            autoFocus
            style={{
              fontSize: '16px',
              backgroundColor:
                !completedCrop?.width || !completedCrop?.height ? '#db9cb6' : '#E36285',
            }}
          >
            {t('Save Image')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
