import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Box, TextField, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { DateRangePicker, DateRange } from '@mui/lab';
import { Line } from 'react-chartjs-2';
import Plot from 'react-plotly.js';
import useStyles from '../style';
import LineChart from 'components/Chart/Line/LineChart';
import DatePicker from './DatePicker';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const fakeData = [
  {
    name: 'January',
    value: 62,
  },
  {
    name: 'February',
    value: 17,
  },
  {
    name: 'March',
    value: 16,
  },
  {
    name: 'April',
    value: 98,
  },
  {
    name: 'May',
    value: 21,
  },
  {
    name: 'June',
    value: 34,
  },
  {
    name: 'July',
    value: 46,
  },
  {
    name: 'August',
    value: 52,
  },
  {
    name: 'September',
    value: 42,
  },
  {
    name: 'October',
    value: 64,
  },
  {
    name: 'November',
    value: 98,
  },
  {
    name: 'December',
    value: 86,
  },
];

function TotalReports(props) {
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
  const classes = useStyles();

  return (
    <div>
      <Box>
        <DatePicker value={value} setValue={setValue} />
      </Box>
      <Box className={classes.chartBox}>
        <LineChart data={fakeData} name="Total Sales" />
      </Box>
    </div>
  );
}

export default TotalReports;
