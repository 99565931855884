import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Box, TextField, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { DateRangePicker, DateRange } from '@mui/lab';
import { Line } from 'react-chartjs-2';
import Plot from 'react-plotly.js';
import useStyles from '../style';
import LineChart from 'components/Chart/Line/LineChart';
import DatePicker from './DatePicker';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const fakeData = [
  {
    name: 'Ads 1',
    value: 62,
  },
  {
    name: 'Ads 2',
    value: 54,
  },
  {
    name: 'Ads 3',
    value: 16,
  },
  {
    name: 'Ads 4',
    value: 11,
  },
  {
    name: 'Ads 5',
    value: 21,
  },
  {
    name: 'Ads 6',
    value: 34,
  },
  {
    name: 'Ads 7',
    value: 76,
  },
  {
    name: 'Ads 8',
    value: 5,
  },
  {
    name: 'Ads 9',
    value: 10,
  },
  {
    name: 'Ads 10',
    value: 6,
  },
  {
    name: 'Ads 11',
    value: 46,
  },
  {
    name: 'Ads 12',
    value: 90,
  },
  {
    name: 'Ads 13',
    value: 54,
  },
  {
    name: 'Ads 14',
    value: 98,
  },
  {
    name: 'Ads 15',
    value: 99,
  },
  {
    name: 'Ads 16',
    value: 35,
  },
  {
    name: 'A very long ads name abcxyz',
    value: 11,
  },
  {
    name: 'Ads 18',
    value: 15,
  },
  {
    name: 'Ads 19',
    value: 65,
  },
];

function AdsImpressionReport(props) {
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
  const classes = useStyles();

  return (
    <div>
      <Box>
        <DatePicker value={value} setValue={setValue} />
      </Box>
      <Box className={classes.chartBox}>
        <LineChart data={fakeData} name="Total Ads Impressions" />
      </Box>
    </div>
  );
}

export default AdsImpressionReport;
