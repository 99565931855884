import { gql } from '@apollo/client';

export const ADD_COMPANY = gql`
  mutation CreateCompnay($company: CompanyInput!) {
    createCompnay(company: $company)
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($company: CompanyInput!, $companyId: ID!) {
    updateCompany(company: $company, companyId: $companyId)
  }
`;

export const DELETE_COMPANY = gql`
  mutation DeleteCompnay($companyId: ID!) {
    deleteCompnay(companyId: $companyId)
  }
`;
