import { EStatus, EStatusDisplay } from 'types/globalType';
import moment from 'moment-timezone';
import { dateFormatDefault } from './constants';

export const compareDateBooking = (booking) => {
  const startDateSecond = new Date(moment(booking?.startDate).format(dateFormatDefault)).getTime();
  const endDateSecond =
    new Date(moment(booking?.endDate).add(1, 'days').format(dateFormatDefault)).getTime() - 1;
  const nowSecond = new Date(moment().format(dateFormatDefault)).getTime();
  if (booking.status === EStatus.DARFT) {
    return {
      status: EStatusDisplay.DARFT,
      styles: {},
    };
  }
  if (booking.status === EStatus.RESERVED) {
    return {
      status: EStatusDisplay.RESERVED,
      styles: {},
    };
  }
  if (nowSecond < startDateSecond) {
    return {
      status: EStatusDisplay.PUBLISHED,
      styles: {
        color: '#000000',
        backgroundColor: '#ffffff',
      },
    };
  }
  if (nowSecond > endDateSecond) {
    return {
      status: EStatusDisplay.COMPLETED,
      styles: {
        color: '#ffffff',
        backgroundColor: '#000000',
      },
    };
  }
  return {
    status: EStatusDisplay.ACTIVE,
    styles: {
      color: '#000000',
      backgroundColor: '#4ce54c',
    },
  };
};
