import { useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Box, Button, TextField } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import { toastError, toastSuccess } from 'components/Toast';
import { Form, FormikProvider, useFormik } from 'formik';
import { ADD_COMPANY, UPDATE_COMPANY } from 'graphQL/Companies/Mutation';
import { GET_ALL_COMPANIES } from 'graphQL/Companies/Queries';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import * as Yup from 'yup';
import { useStyles } from '../../../components/AddOrEditForm/style';
import './ReactTags.css';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

interface IProps {
  isSave: boolean;
  setIsSave: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDelete: React.Dispatch<React.SetStateAction<boolean>>;
  isDelete?: boolean;
  isAdd?: boolean;
  handleNonDelete: () => void;
  handleNonEdit: () => void;
  editData?: any;
  action: string;
  companyId?: string | undefined;
}

interface IMutation {
  companyName: string;
  tradingName: string;
  abn: string;
  contactFirstName: string;
  contactLastName: string;
  phoneNumber: string;
  email: string;
  category: string;
  tags: any[];
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const accountSchema = Yup.object().shape({
  companyName: Yup.string().required('You have to fill this field'),
  tradingName: Yup.string().required('You have to fill this field'),
  email: Yup.string().email('Must be a valid email').required('You have to fill this field'),
  abn: Yup.string().required('You have to fill this field'),
  contactFirstName: Yup.string().required('You have to fill this field'),
  contactLastName: Yup.string().required('You have to fill this field'),
  category: Yup.string().required('You have to fill this field'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('You have to fill this field'),
});

function AddOrCompaniesForm({
  isSave,
  setIsSave,
  isDelete,
  handleNonDelete,
  handleNonEdit,
  setIsDelete,
  isAdd,
  editData,
  action,
  companyId,
}: IProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tags, setTags] = React.useState<any>([]);
  const [companyData, setCompanyData] = React.useState<IMutation>({
    companyName: '',
    tradingName: '',
    abn: '',
    contactFirstName: '',
    contactLastName: '',
    phoneNumber: '',
    email: '',
    category: '',
    tags: [],
  });

  const formik = useFormik({
    initialValues: companyData,
    validationSchema: accountSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsSave(true);
        setCompanyData(values);
        let finalTag: any[] = [];
        finalTag.push(tags.map((e) => e.text));
        setCompanyData((e) => ({ ...e, tags: finalTag[0] }));
      } catch (err: any) {
        console.log('false', err);
      }
    },
  });

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: never) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag: never, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {};

  const [createNewCompany] = useMutation(ADD_COMPANY, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_ALL_COMPANIES }],
    onCompleted(response) {
      toastSuccess('Create Company Success');
    },
    onError(error) {
      console.log('Add Failed: ', error);
      toastError('Create Company Fail');
    },
  });

  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_ALL_COMPANIES }],
    onCompleted(res) {
      toastSuccess('Update Company Success');
    },
    onError(err) {
      toastError('Update Company Fail');
    },
  });

  const onUpateCompany = () => {
    if (companyId) {
      const variables = {
        company: companyData,
        companyId: companyId,
      };
      updateCompany({ variables });
      setIsSave(false);
      navigate(-1);
    }
  };

  const onCreateNewCompany = () => {
    const variables = {
      company: companyData,
    };
    createNewCompany({ variables });
    setIsSave(false);
    navigate(-1);
  };

  useEffect(() => {
    if (editData) {
      setCompanyData((e) => ({
        ...e,
        companyName: editData.companyName,
        tradingName: editData.tradingName,
        abn: editData.abn,
        contactFirstName: editData.contactFirstName,
        contactLastName: editData.contactLastName,
        phoneNumber: editData.phoneNumber,
        email: editData.email,
        category: editData.category,
      }));
    }
  }, [editData, tags]);

  useEffect(() => {
    if (editData) {
      editData.tags.map((text) => {
        setTags((e) => [
          ...e,
          {
            id: text,
            text: text,
          },
        ]);
      });
    }
  }, [editData, editData?.tags]);

  const { touched, errors } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Box sx={{ width: '500px', marginTop: '40px', display: 'block !important' }}>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Company Name</Typography>
            <TextField
              fullWidth
              name="companyName"
              className={classes.input}
              placeholder="Company Name"
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.input,
                },
              }}
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={formik.touched.companyName ? formik.errors.companyName : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Trading Name</Typography>
            <TextField
              fullWidth
              name="tradingName"
              className={classes.input}
              placeholder="Trading Name"
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.input,
                },
              }}
              value={formik.values.tradingName}
              onChange={formik.handleChange}
              error={Boolean(touched.tradingName && errors.tradingName)}
              helperText={formik.touched.tradingName ? formik.errors.tradingName : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Abn</Typography>
            <TextField
              fullWidth
              name="abn"
              className={classes.input}
              placeholder="Abn"
              sx={{ fontSize: '20px' }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.input,
                },
              }}
              value={formik.values.abn}
              onChange={formik.handleChange}
              error={Boolean(touched.abn && errors.abn)}
              helperText={formik.touched.abn ? formik.errors.abn : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Contact FirstName</Typography>
            <TextField
              fullWidth
              name="contactFirstName"
              className={classes.input}
              placeholder="Contact FirstName"
              sx={{ fontSize: '20px' }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.input,
                },
              }}
              value={formik.values.contactFirstName}
              onChange={formik.handleChange}
              error={Boolean(touched.contactFirstName && errors.contactFirstName)}
              helperText={formik.touched.contactFirstName ? formik.errors.contactFirstName : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Contact LastName</Typography>
            <TextField
              fullWidth
              name="contactLastName"
              className={classes.input}
              placeholder="Contact LastName"
              sx={{ fontSize: '20px' }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.input,
                },
              }}
              value={formik.values.contactLastName}
              onChange={formik.handleChange}
              error={Boolean(touched.contactLastName && errors.contactLastName)}
              helperText={formik.touched.contactLastName ? formik.errors.contactLastName : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Phone Number</Typography>
            <TextField
              fullWidth
              name="phoneNumber"
              className={classes.input}
              placeholder="Phone Number"
              sx={{ fontSize: '20px' }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.input,
                },
              }}
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={formik.touched.phoneNumber ? formik.errors.phoneNumber : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Email</Typography>
            <TextField
              fullWidth
              name="email"
              className={classes.input}
              placeholder="Email"
              sx={{ fontSize: '20px' }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.input,
                },
              }}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={Boolean(touched.email && errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Category</Typography>
            <TextField
              fullWidth
              name="category"
              className={classes.input}
              placeholder="Category"
              sx={{ fontSize: '20px' }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.input,
                },
              }}
              value={formik.values.category}
              onChange={formik.handleChange}
              error={Boolean(touched.category && errors.category)}
              helperText={formik.touched.category ? formik.errors.category : ''}
            />
          </Box>
          <Box sx={{ marginTop: '17px' }}>
            <Typography className={classes.inputHeader}>Tags</Typography>
            <ReactTags
              tags={tags}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="top"
              autofocus={false}
              placeholder="Tags"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '62px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ConfirmDialog
                open={isDelete}
                handleClose={handleNonDelete}
                title="Permanently"
                question="This account"
                action="delete"
                confirm="Yes"
              />
            </Box>
            <Button disableElevation variant="contained" className={classes.button} type="submit">
              {isAdd ? 'Add' : 'Save'}
            </Button>
            {action === 'add' ? (
              <ConfirmDialog
                onClickAction={onCreateNewCompany}
                open={isSave}
                handleClose={handleNonEdit}
                title={"You're adding a new company"}
                question={'Add new company ?'}
                action="add"
                confirm="Add"
              />
            ) : (
              <ConfirmDialog
                onClickAction={onUpateCompany}
                open={isSave}
                handleClose={handleNonEdit}
                title={"You're editing a company"}
                question={'Confirm changes ?'}
                action="edit"
                confirm="Yes"
              />
            )}
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default AddOrCompaniesForm;
