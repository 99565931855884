import { gql } from '@apollo/client';

export const CREATE_NEW_ADSTYPE = gql`
  mutation CreateAdvertiseType($advertiseType: AdvertiseTypeInput!) {
    createAdvertiseType(advertiseType: $advertiseType)
  }
`;

export const DELETE_ADSTYPE = gql`
  mutation DeleteAdvertiseType($advertiseTypeId: ID!) {
    deleteAdvertiseType(advertiseTypeId: $advertiseTypeId)
  }
`;

export const UPDATE_ADSTYPE = gql`
  mutation UpdateAdvertiseType($advertiseType: AdvertiseTypeInput!, $advertiseTypeId: ID!) {
    updateAdvertiseType(advertiseType: $advertiseType, advertiseTypeId: $advertiseTypeId)
  }
`;
