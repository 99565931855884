import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Typography } from '@material-ui/core';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, MenuList } from '@mui/material';
import useStyles from './styles';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from 'utils/firebase';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleContext } from 'components/Layout';
import ConfigurationsDialog from 'components/ConfigurationsDialog';

export default function User(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { email, name } = props;
  const testRole = React.useContext(RoleContext);

  const open = Boolean(anchorEl);
  const classes = useStyles();
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/login');
  }, [user, loading, navigate]);

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <Box display="flex" alignItems="flex-end" sx={{ background: 'none', boxShadow: 'none' }}>
      <AccountCircleIcon
        sx={{
          fontSize: '45px',
          color: '#E36285',
        }}
        id="demo-positioned-AccountCircleIcon"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.menuRoot}
        sx={{ background: 'none', boxShadow: 'none' }}
      >
        <Box sx={{ width: '300px', padding: '12px' }}>
          <Typography variant="body2" style={{ fontSize: 18, color: '#E36285' }}>
            {name}
          </Typography>
          <Typography variant="body1" style={{ fontSize: 14, marginTop: '12px' }}>
            {email}
          </Typography>
        </Box>
        <MenuList>
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            <PersonOutlineIcon sx={{ marginRight: '15px', fontSize: '24px' }} />
            <Typography>My Profile</Typography>
          </MenuItem>
          {testRole === 'admin' ? (
            <MenuItem className={classes.menuItem} onClick={handleOpenDialog}>
              <SettingsIcon sx={{ marginRight: '15px', fontSize: '24px' }} />
              <Typography>Configurations</Typography>
            </MenuItem>
          ) : (
            <div></div>
          )}
          <MenuItem className={classes.menuItem} onClick={handleLogout}>
            <LogoutIcon sx={{ marginRight: '15px', fontSize: '24px' }} />
            <Typography>Logout</Typography>
          </MenuItem>
        </MenuList>
      </Menu>
      <ConfigurationsDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
    </Box>
  );
}
