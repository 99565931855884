import { gql } from '@apollo/client';

export const GET_ALL_ADSTYPES = gql`
  query AdvertiseTypes {
    advertiseTypes {
      id
      name
      description
      viewType
      maxBookingQuantity
    }
  }
`;
