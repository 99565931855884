import { Chip, Link, Typography } from '@mui/material';
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PanoramaIcon from '@mui/icons-material/Panorama';
import useStyles from '../style';

interface IChipItemProps {
  index: number;
  isEdit: boolean;
  data: any;
  handleRemoveDialogOpen: (state: any) => void;
  handleOpenDetailPictureDialog: (state: any) => void;
}

const ChipItem = ({
  index,
  isEdit,
  data,
  handleRemoveDialogOpen,
  handleOpenDetailPictureDialog,
}: IChipItemProps) => {
  const classes = useStyles();
  return (
    <Chip
      key={index}
      sx={{ width: '60%', justifyContent: 'flex-start', mb: 2 }}
      variant="outlined"
      icon={
        isEdit ? (
          <HighlightOffIcon
            sx={{ fontSize: '22px', cursor: 'pointer' }}
            onClick={() => handleRemoveDialogOpen(data.id)}
          />
        ) : (
          <PanoramaIcon sx={{ fontSize: '22px', pl: 1 }} />
        )
      }
      label={
        <Typography
          sx={{ '&.MuiTypography-root': { fontStyle: 'italic' } }}
          className={classes.uploadFileName}
        >
          <Link
            underline="hover"
            color="inherit"
            target="_blank"
            onClick={() => handleOpenDetailPictureDialog(data)}
          >
            {data && data.description}
          </Link>
        </Typography>
      }
    />
  );
};

export default ChipItem;
