import { useLazyQuery, useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import { toastError, toastSuccess } from 'components/Toast';
import { Form, FormikProvider, useFormik } from 'formik';
import { CREATE_NEW_ADSTARGET, UPDATE_ADSTARGET } from 'graphQL/AdsTarget/Mutation';
import { GET_ALL_ADSTARGETS } from 'graphQL/AdsTarget/Queries';
import { GET_ALL_ADSTYPES } from 'graphQL/AdsType/Queries';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useStyles } from './style';

const accountSchema = Yup.object().shape({
  adTypeIDs: Yup.array().min(1, 'Please select at least 1 ads type'),
  target: Yup.string().required('Please select a role'),
});

interface IProps {
  isSave?: boolean;
  setIsSave: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDelete: React.Dispatch<React.SetStateAction<boolean>>;
  isDelete?: boolean;
  isAdd?: boolean;
  handleNonDelete: () => void;
  handleNonEdit: () => void;
  adsTypeDetail?: any;
  list: any;
  setIsEdit: any;
  setLoading: any;
}

function AdTargetForm({
  isSave,
  setIsSave,
  isDelete,
  handleNonDelete,
  handleNonEdit,
  setIsDelete,
  isAdd,
  adsTypeDetail,
  list,
  setIsEdit,
  setLoading,
}: IProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [adsTypes, setAdsTypes] = React.useState<any[]>();
  const [adsTargetData, setAdsTargetData] = React.useState({
    adTypeIDs: list[0].adTypeObjects.map((e) => e.adType.id),
    target: list[0].target,
  });

  const formik = useFormik({
    initialValues: adsTargetData,
    validationSchema: accountSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsSave(true);
        setAdsTargetData(values);
      } catch (err: any) {
        console.log('false', err);
      }
    },
  });

  const [getAllAdsTypes] = useLazyQuery(GET_ALL_ADSTYPES, {
    onCompleted(res) {
      setAdsTypes(res.advertiseTypes);
    },
  });

  const [createNewAdsTarget] = useMutation(CREATE_NEW_ADSTARGET, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_ALL_ADSTARGETS }],
    onCompleted(response) {
      toastSuccess('Create Ads Target Success');
      setIsSave(false);
      navigate(-1);
    },
    onError(error) {
      toastError('Create Ads Target Fail');
      console.log('Add Failed: ', error);
    },
  });

  const [updateAdsTarget] = useMutation(UPDATE_ADSTARGET, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_ALL_ADSTARGETS }],
    onCompleted(response) {
      setIsEdit(false);
      setIsSave(false);
      toastSuccess('Update Ads Target Success');
    },
    onError(error) {
      toastError('Update Ads Target Fail');
      console.log('Add Failed: ', error);
    },
  });

  const handleCreateNewAdsTarget = () => {
    let adTypes: any = [];
    adsTargetData.adTypeIDs.forEach((e, index) => {
      adTypes = [
        ...adTypes,
        {
          adTypeID: e,
          sort: index + 1,
        },
      ];
    });
    const variables = {
      adsTargetContent: {
        target: adsTargetData.target,
        adTypes: adTypes,
      },
    };
    createNewAdsTarget({ variables });
  };

  const onUpdateAdsTarget = () => {
    setLoading(true);
    let adTypes: any = [];
    adsTargetData.adTypeIDs.forEach((e, index) => {
      adTypes = [
        ...adTypes,
        {
          adTypeID: e,
          sort: index + 1,
        },
      ];
    });
    const variables = {
      adsTargetContent: {
        target: adsTargetData.target,
        adTypes: adTypes,
      },
      adsTargetContentId: list[0].id,
    };
    updateAdsTarget({ variables });
  };

  useEffect(() => {
    getAllAdsTypes();
  }, [getAllAdsTypes]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Box sx={{ width: '500px', marginTop: '40px', display: 'block !important' }}>
          <Box>
            <Typography className={classes.inputHeader}>Ads Types</Typography>
            <TextField
              fullWidth
              select
              name="adTypeIDs"
              className={classes.input}
              placeholder="Name"
              SelectProps={{
                multiple: true,
                value: formik.values.adTypeIDs,
              }}
              InputProps={{
                className: classes.input,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helpertext,
                },
              }}
              onChange={formik.handleChange}
              helperText={formik.touched.adTypeIDs ? formik.errors.adTypeIDs : ''}
            >
              {adsTypes?.map((item, idx) => (
                <MenuItem key={idx} sx={{ fontSize: '16px' }} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '62px',
            }}
          >
            <Button disableElevation variant="contained" className={classes.button} type="submit">
              {isAdd ? 'Add' : 'Update'}
            </Button>

            <ConfirmDialog
              onClickAction={isAdd ? handleCreateNewAdsTarget : onUpdateAdsTarget}
              open={isSave}
              handleClose={handleNonEdit}
              title={!isAdd ? "You've made a change" : "You're adding a new ads target"}
              question={!isAdd ? 'Confirm update Ads Target ?' : 'Add new ads type ?'}
              action={isAdd ? 'add' : 'edit'}
              confirm={isAdd ? 'Add' : 'Yes'}
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default AdTargetForm;
