import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  Skeleton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import './style.scss';

function DetailPictureDialog(props) {
  const { open, handleClose, pictureData, objectFit = 'contain', alt = 'notFound' } = props;

  const [loading, setLoading] = React.useState(false);

  const handleCloseDiaglog = () => {
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
          maxWidth: 'none !important',
          // height: '90vh !important',
          width: '700px !important',
          overflow: 'none',
        },
      }}
      open={open}
      onClose={handleCloseDiaglog}
    >
      <DialogTitle
        sx={{
          '&.MuiDialogTitle-root': {
            textAlign: 'end',
            padding: '16px 24px 0px',
          },
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ fontSize: '30px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {pictureData && (
          <>
            <Typography sx={{ fontSize: '2rem', fontWeight: 700, pb: 2 }}>
              Picture Details:{' '}
            </Typography>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className="smooth-image-wrapper"
            >
              <img
                src={pictureData.fileUrl}
                className={`smooth-image img-${loading ? 'visible' : 'hidden'}`}
                style={{ objectFit, paddingBottom: '20px' }}
                width={'80%'}
                height={'60%'}
                alt=""
                onLoad={() => setLoading(true)}
              />

              {!loading && (
                <Skeleton variant="rectangular" animation="wave" width={'50vh'} height={'50vh'} />
              )}
            </div>
            <List>
              <ListItem>
                <Box sx={{ width: '50px' }}>
                  <Typography style={{ fontSize: '20px', fontWeight: '700' }}>URL:</Typography>
                </Box>
                <Link
                  underline="hover"
                  target="_blank"
                  href={
                    pictureData.externalUrl?.includes('https://')
                      ? pictureData.externalUrl
                      : `https://${pictureData.externalUrl}`
                  }
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    color: 'black',
                    fontFamily: 'Roboto',
                  }}
                >
                  {pictureData.externalUrl ? pictureData.externalUrl : 'No URL found !'}
                </Link>
              </ListItem>
            </List>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default DetailPictureDialog;
