import { makeStyles } from '@material-ui/core';
const breakPoint = 890;
const largeBreakPoint = 1204;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  headerTitle: {
    '&.MuiTypography-h2': {
      fontWeight: 700,
      lineHeight: '54.43px',
    },
  },
  searchPaper: {
    '&.MuiPaper-root': {
      padding: '2px 10px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: '13px',
      minHeight: '45px',
      maxWidth: '100%',
    },
  },
  detailTitle: {
    '&.MuiTypography-root': {
      fontSize: '32px',
      fontWeight: '700',
      marginRight: '40px',
      lineHeight: '54px',
      color: '#E36285',
    },
  },
  editLabelIcon: {
    '&.MuiTypography-root': {
      fontSize: '16px',
      marginRight: '10px',
      lineHeight: '29px',
      fontWeight: 300,
    },
  },
  paper: {
    '&.MuiPaper-root': {
      flex: 5,
      borderRadius: '12px',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
      padding: '25px 35px',
      marginTop: '42px',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  inputTitle: {
    '&.MuiTypography-root': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
    },
    display: 'block',
  },
  input: {
    width: '100%',
    borderRadius: '7px',
    padding: '5px 10px',
    fontSize: '20px',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
    background: '#E5E5E5',
    '&.Mui-disabled': {
      background: '#fff',
    },
  },
  uploadTitle: {
    '&.MuiTypography-root': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
      color: '#E36285',
    },
  },
  uploadInput: {
    '&.MuiTypography-root': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
      marginRight: '10px',
    },
  },
  uploadFileName: {
    '&.MuiTypography-root': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '26px',
      background: 'none',
      boxShadow: 'none',
    },
  },
  radio: {
    color: '#E36285',
    '& svg': {
      width: '20px',
      height: '20px',
    },
    '&.Mui-checked': {
      color: '#E36285',
    },
  },
  salePerson: {
    '&.MuiTypography-root': {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  deleteTitle: {
    '&.MuiTypography-root': {
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '26px',
      cursor: 'pointer',
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      fontSize: '1.7rem',
    },
  },
  detailHeader: {
    '&.MuiTypography-root': {
      fontSize: '34px',
      fontWeight: '700',
      lineHeight: '54,43px',
      color: '#159600',
    },
  },
  totalDetail: {
    '&.MuiTypography-root': {
      fontSize: '21px',
      lineHeight: '25,6px',
      fontWeight: 400,
    },
  },
  paymentDetail: {
    '&.MuiTypography-root': {
      fontSize: '18px',
      lineHeight: '21,94px',
      fontWeight: 400,
    },
  },
  button: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: '#F4D23F',
      backgroundColor: '#E36285',
      borderRadius: '13px',
      fontSize: '21px',
      lineHeight: '33,62',
      fontWeight: 600,
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#E36285',
    },
  },
  muiSelect: {
    '&.MuiSelect-outlined': {
      fontSize: '21px',
    },
    '&.MuiSelect-select': {
      fontSize: '21px',
    },
  },
  datePicker: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '7px',
      background: '#E5E5E5',
      fontSize: '16px',
    },
  },
  contentBox: {
    width: '100%',
    padding: '0px 42px 42px',
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${largeBreakPoint}px)`]: {
      flexDirection: 'row',
    },
  },
  gridBox: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${breakPoint}px)`]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  formControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '21px',
    [`@media (min-width: ${breakPoint}px)`]: {
      width: '45%',
      flexDirection: 'column',
    },
  },
  detailBox: {
    marginTop: '42px',
    [`@media (min-width: ${breakPoint}px)`]: {
      flex: 2,
    },
  },
  contDetailBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    alignItems: 'flex-start',
    [`@media (min-width: ${largeBreakPoint}px)`]: {
      textAlign: 'end',
      alignItems: 'flex-end',
    },
  },
  tableContainer: {
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F9E0E7',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E36285',
      width: '4px',
      borderRadius: '12px',
    },
  },
  helperText: {
    color: 'red',
    fontSize: '1.7rem',
    height: '25px',
    '&.MuiFormHelperText-root': {
      fontSize: '1.5rem',
      color: 'red',
    },
  },
}));
export default useStyles;
