import { gql } from '@apollo/client';

export const CREATE_NEW_BOOKING = gql`
  mutation CreateAdvertiseBooking($advertiseBooking: AdvertiseBookingInput!) {
    createAdvertiseBooking(advertiseBooking: $advertiseBooking)
  }
`;

export const UPDATE_BOOKING = gql`
  mutation UpdateAdvertiseBooking(
    $advertiseBooking: AdvertiseBookingInput!
    $advertiseBookingId: ID!
  ) {
    updateAdvertiseBooking(
      advertiseBooking: $advertiseBooking
      advertiseBookingId: $advertiseBookingId
    )
  }
`;

export const DELETE_BOOKING = gql`
  mutation DeleteAdvertiseBooking($advertiseBookingId: ID!) {
    deleteAdvertiseBooking(advertiseBookingId: $advertiseBookingId)
  }
`;

export const PUBLISH_BOOKING = gql`
  mutation PublishAdvertiseBooking($advertiseBookingId: ID!) {
    publishAdvertiseBooking(advertiseBookingId: $advertiseBookingId)
  }
`;

export const UNPUBLISH_BOOKING = gql`
  mutation UnPublishAdvertiseBooking($advertiseBookingId: ID!) {
    unPublishAdvertiseBooking(advertiseBookingId: $advertiseBookingId)
  }
`;

export const RESERVED_BOOKING = gql`
  mutation reserveAdvertiseBooking($advertiseBookingId: ID!) {
    reserveAdvertiseBooking(advertiseBookingId: $advertiseBookingId)
  }
`;

export const UPLOAD_BOOKING_PHOTO = gql`
  mutation AddAdvertisingResource($file: Upload!, $advertisingResource: AdvertisingResourceInput!) {
    addAdvertisingResource(file: $file, advertisingResource: $advertisingResource)
  }
`;

export const DELETE_BOOKING_PHOTO = gql`
  mutation DeleteAdvertisingResource($advertisingResourceId: ID!) {
    deleteAdvertisingResource(advertisingResourceId: $advertisingResourceId)
  }
`;
