import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  calendar: {
    '&.month': {
      width: '300px',
      height: '300px',
    },
  },
  inputTitle: {
    '&.MuiTypography-root': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  input: {
    width: '100%',
    borderRadius: '7px',
    padding: '5px 10px',
    fontSize: '20px',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
    '&.Mui-disabled': {
      background: '#fff',
    },
  },
  selectInput: {
    width: '350px',
    borderRadius: '7px',
    fontSize: '20px',
    '&.MuiSelect-select': {
      padding: '0px',
    },
  },
  contentDialog: {
    minWidth: '500px',
    padding: '24px',
    maxHeight: '800px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 4,
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'gray',
      width: '4px',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E36285',
      width: '4px',
      borderRadius: '8px',
    },
  },
  dialogButton: {
    background: '#E36285',
    color: '#F4D23F',
    textTransform: 'none',
    padding: '8px 24px',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#ccc',
      color: '#fff',
    },
  },
  menuItem: {
    fontSize: '2rem',
    '&.MuiMenuItem-root': {
      fontSize: '2rem',
    },
    '&.MuiInputBase-root': {
      fontSize: '2rem',
    },
  },
  selectBox: {
    display: 'flex',
  },
  monthsBox: {
    margin: '20px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  adsDialogButton: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: 'black',
      backgroundColor: '#fff',
      borderRadius: '10.5425px',
      fontSize: '10px',
      fontWeight: 700,
      width: '70px',
      height: '40.41px',
      border: '1px solid gray',
      margin: '0px 12px',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgb(249, 224, 231)',
    },
  },
});

export default useStyles;
