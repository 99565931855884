/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdsTarget {
  merchantApp = "merchantApp",
  orderWebsite = "orderWebsite",
  orderbuddyApp = "orderbuddyApp",
}

export enum AdvertiseViewType {
  banner = "banner",
  carasel = "carasel",
  grid = "grid",
  horizontalList = "horizontalList",
  verticalList = "verticalList",
}

export enum AdvertisingLocation {
  NewSouthWales = "NewSouthWales",
  NorthernTerritory = "NorthernTerritory",
  Queensland = "Queensland",
  SouthAustralia = "SouthAustralia",
  Tasmania = "Tasmania",
  Victoria = "Victoria",
  WesternAustralia = "WesternAustralia",
}

export enum AdvertisingResourceType {
  html = "html",
  photo = "photo",
  text = "text",
  video = "video",
}

export enum UserRole {
  admin = "admin",
  agent = "agent",
}

export interface AccountInput {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: UserRole;
  email: string;
  password: string;
}

export interface AdvertiseBookingInput {
  name: string;
  adTypeId: string;
  target: AdsTarget;
  location: AdvertisingLocation;
  startDate: any;
  endDate: any;
  quotationPerDay: number;
  companyId: string;
  companyName: string;
}

export interface AdvertiseTypeInput {
  name: string;
  description?: string | null;
  viewType: AdvertiseViewType;
}

export interface AdvertisingResourceInput {
  description?: string | null;
  adTypeId: string;
  companyId: string;
  bookingId: string;
  type: AdvertisingResourceType;
  tags?: (string | null)[] | null;
}

export interface CompanyInput {
  companyName: string;
  tradingName: string;
  abn: string;
  contactFirstName: string;
  contactLastName: string;
  phoneNumber: string;
  category: string;
  tags: (string | null)[];
  email: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
