import React, { useEffect, useState } from 'react';
import { Box, InputBase, MenuItem, Paper, TextField, Typography } from '@mui/material';
import useStyles from './style';
import SalePersonReport from './component/SalePersonReport';
import TotalReports from './component/TotalReports';
import AdsImpressionReport from './component/AdsImpressionReport';

const selectOption = [
  {
    label: 'Sales Person Report',
    render: <SalePersonReport />,
  },
  {
    label: 'Total Sales Report',
    render: <TotalReports />,
  },
  {
    label: 'Ads Impression Report',
    render: <AdsImpressionReport />,
  },
];

function Reports(props) {
  const classes = useStyles();
  const [reportName, setReportName] = useState('Sales Person Report');

  const handleChange = (e) => {
    setReportName(e.target.value);
  };

  return (
    <div style={{ width: '100%' }}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="h2"
            sx={{
              color: 'black',
              border: 'none',
              background: 'none',
              boxShadow: 'none',
              padding: 0,
            }}
            className={classes.headerText}
          >
            Reports
          </Typography>
          <Box className={classes.searchPaper} sx={{ display: 'block' }}>
            <TextField
              select
              sx={{ padding: '0px 0px' }}
              value={reportName}
              onChange={handleChange}
              className={classes.reportsInput}
              InputProps={{
                className: classes.reportsInput,
              }}
            >
              {selectOption.map((option) => (
                <MenuItem key={option.label} value={option.label} className={classes.menuItem}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <Box>{selectOption.filter((e) => e.label === reportName)[0].render}</Box>
      </Box>
    </div>
  );
}

export default Reports;
