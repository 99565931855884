import { Box, IconButton, InputBase, Paper, Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CompaniesTable from './component/CompaniesTable';
import useStyles from './style';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../path';

function Companies(props) {
  const typingTimeout = React.useRef<any>(null);
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [tempSearch, setTempSearch] = React.useState(search);
  const navigate = useNavigate();

  const toogleAddAccount = () => {
    navigate(PATH.dashboard.companies.add);
  };

  const toogleSearchField = (e: any) => {
    setSearch(e.target.value);
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      setTempSearch(e.target.value);
    }, 500);
  };

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="h2" className={classes.headerText}>
            Companies
          </Typography>
          <Box className={classes.searchAndAdd} sx={{ flexDirection: 'row' }}>
            <Paper component="form" className={classes.searchPaper}>
              <InputBase
                style={{ flex: 1 }}
                placeholder="Search"
                onChange={toogleSearchField}
                onKeyDown={onKeyDown}
              />
              <SearchIcon sx={{ fontSize: '24px', color: '#747474' }} />
            </Paper>
            <Button onClick={() => toogleAddAccount()} variant="text" className={classes.button}>
              <AddIcon sx={{ fontSize: 40 }} />
              <Typography>Add New Company</Typography>
            </Button>
          </Box>
        </Box>
        <Box marginTop={3}>
          <CompaniesTable search={tempSearch} />
        </Box>
      </Box>
    </Box>
  );
}

export default Companies;
