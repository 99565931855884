import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles({
  dialogRoot: {
    '& .MuiPaper-root': {
      width: '550px',
      borderRadius: '20px',
    },
  },
  adsbutton: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: 'black',
      backgroundColor: '#fff',
      borderRadius: '10.5425px',
      fontSize: '18px',
      fontWeight: 400,
      width: '188.86px',
      height: '57.41px',
      border: 'none',
      margin: '0px 12px',
      boxShadow: '0px 1.50607px 7.53037px rgba(0, 0, 0, 0.25)',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgb(249, 224, 231)',
    },
  },
  input: {
    width: '100%',
    borderRadius: '7px',
    padding: '5px 10px',
    fontSize: '20px',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
    '&.Mui-disabled': {
      background: '#fff',
    },
  },
  selectInput: {},

  inputBox: {
    padding: '0px 40px',
  },
  activeBtn: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: '#F4D23F',
      backgroundColor: '#E36285',
      borderRadius: '15px',
      fontSize: '15px',
      fontWeight: 400,
      width: '150px',
      height: '48px',
      border: '1.51087px solid #C4C4C4',
      margin: '0px 12px',
      boxShadow: '0px 1.50607px 7.53037px rgba(0, 0, 0, 0.25)',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#E36285',
    },
  },
  cancelBtn: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: 'black',
      backgroundColor: '#fff',
      borderRadius: '15px',
      fontSize: '15px',
      fontWeight: 400,
      width: '150px',
      height: '48px',
      border: '1.51087px solid #C4C4C4',
      margin: '0px 12px',
      boxShadow: '0px 1.50607px 7.53037px rgba(0, 0, 0, 0.25)',
    },
  },
  configHeader: {
    fontSize: '24px',
    fontWeight: 600,
  },
  configTypo: {
    fontSize: '18px',
    fontWeight: 600,
  },
});
