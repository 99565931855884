import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Box, TextField, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function LineChart(props) {
  const { data, name } = props;
  return (
    <div style={{ padding: '50px 80px' }}>
      <Line
        data={{
          labels: data.map((e) => e.name),
          datasets: [
            {
              label: name,
              data: data.map((e) => e.value),
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgba(255, 99, 132, 0.5)',
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              labels: {
                font: {
                  size: 20,
                },
              },
              title: {
                font: {
                  size: 20,
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

export default LineChart;
