import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { Typography, MenuItem, Box, Select, Button } from '@mui/material';
import CalendarDialog from './CalendarDialog';
import MyCalendar from './Calendar';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ALL_BOOKINGS } from 'graphQL/Bookings/Queries';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import Spinner from 'components/Spiner';
import { GET_ALL_ADSTYPES } from 'graphQL/AdsType/Queries';
import { advertisementBookingAdsTarget } from 'utils/constants';
import { GET_ALL_ADSTARGETS } from 'graphQL/AdsTarget/Queries';
import { UPDATE_ADSTARGET } from 'graphQL/AdsTarget/Mutation';
import { GET_ALL_COMPANIES } from 'graphQL/Companies/Queries';
import AddIcon from '@mui/icons-material/Add';
import { RoleContext } from 'components/Layout';
import { ERole, EStatus } from 'types/globalType';
dayjs.extend(isBetween);

interface IData {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  adType: string;
}

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

export const checkDisableAdsType = (dataBookings, now, adsTypes) => {
  if (dataBookings) {
    const temp = dataBookings.advertiseBookings.filter((booking) => {
      return booking.status !== EStatus.DARFT && dayjs(now).diff(dayjs(booking.endDate)) <= 0;
    });
    const result = adsTypes?.allAdsTargetContent[0]?.adTypeObjects.map((item) => {
      const adsType = item.adType;
      const arrayId = temp?.map((item) => {
        return item?.adType?.id;
      });
      const quantityBookingType = arrayId?.filter((item) => {
        return item === adsType.id;
      }).length;
      return Number(quantityBookingType) >= Number(adsType.maxBookingQuantity);
    });
    return result;
  }
};

export default function CalendarPage() {
  const classes = useStyles();
  const [dialogCalendar, setDialogCalendar] = useState(false);
  const [calendarData, setCalendarData] = React.useState<any>([]);
  const [adTypes, setAdTypes] = React.useState([]);
  const [disableCalendar, setDisableCalendar] = React.useState<any>([]);
  const [dataResponseLength, setDataResponseLength] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const [dayOnClick, setSelectedDays] = React.useState('');
  const [target, setTarget] = React.useState('merchantApp');
  const [bookings, setBookings] = React.useState<any>();
  const roleUser = useContext(RoleContext);
  const [isDefaultBooking, setIsDefaultBooking] = useState(false);

  const dateSort = (sortBy) => (a, b) => dayjs(a[sortBy]).isAfter(dayjs(b[sortBy])) ? 1 : -1;

  const [getAllBookings, { data: dataBookings }] = useLazyQuery(GET_ALL_BOOKINGS, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      setBookings(res.advertiseBookings);
      setDataResponseLength(res.advertiseBookings?.filter((e) => e.status === 'published').length);
    },
  });

  const [getAllAdTypes] = useLazyQuery(GET_ALL_ADSTYPES, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      setAdTypes(res.advertiseTypes);
    },
  });

  const [getAdsTargetLazy, { data: adsTypes }] = useLazyQuery(GET_ALL_ADSTARGETS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setTarget(response?.allAdsTargetContent[0]?.target);
      setLoading(false);
    },
    onError(error) {
      console.log('Login Failed: ', error);
    },
  });

  const [getAllCompanies, { data: dataCompanies }] = useLazyQuery(GET_ALL_COMPANIES, {
    fetchPolicy: 'network-only',
    onCompleted(res) {},
  });

  const handleOnDayClick = (e, month, i, yearIncrease) => {
    setSelectedDays(`${month.name}-${i + 1}-${dayjs().year() + yearIncrease}`);
    if (e) {
      if (e[0].color != '#FE4D4D') {
        setDialogCalendar(true);
      }
    }
  };

  const [updateAdsTarget] = useMutation(UPDATE_ADSTARGET, {
    fetchPolicy: 'network-only',
    refetchQueries: [{ query: GET_ALL_ADSTARGETS }],
    onCompleted(response) {},
    onError(error) {
      console.log('Add Failed: ', error);
    },
  });

  const now = new Date();

  const handleTargetChange = (e) => {
    setTarget(e.target.value);
    setCalendarData((e) => [
      {
        id: 1,
        name: 'Available Days',
        startDate: new Date(currentYear, 0, 1),
        endDate: new Date(currentYear, 11, 29),
        color: '#8BE362',
      },
      {
        id: 2,
        name: 'Available Days',
        startDate: new Date(currentYear + 1, 0, 1),
        endDate: new Date(currentYear + 1, 11, 29),
        color: '#8BE362',
      },
    ]);
    const allAdsTypeTarget = adsTypes?.allAdsTargetContent[0]?.adTypeObjects?.map((item) => {
      return {
        adTypeID: item?.adType?.id,
        sort: item.sort,
      };
    });
    const variables = {
      adsTargetContent: {
        target: e.target.value,
        adTypes: allAdsTypeTarget,
      },
      adsTargetContentId: adsTypes?.allAdsTargetContent[0]?.id,
    };

    updateAdsTarget({ variables });
  };

  useEffect(() => {
    setCalendarData((e) => [{}]);
    if (bookings) {
      bookings
        .filter((i) => i.target === target)
        .forEach((item, idx) => {
          setCalendarData((e) => [
            ...e,
            {
              id: item.id,
              name: item.companyName,
              startDate: dayjs(item.startDate).subtract(2, 'd'),
              endDate: dayjs(item.endDate).subtract(1, 'd'),
              color: '#FFBA52',
              adType: item.adType.name,
            },
          ]);
        });
      setCalendarData((e) => [
        ...e,
        {
          id: 1,
          name: 'Available Days',
          startDate: new Date(currentYear, 0, 1),
          endDate: new Date(currentYear, 11, 29),
          color: '#8BE362',
        },
        {
          id: 2,
          name: 'Available Days',
          startDate: new Date(currentYear + 1, 0, 1),
          endDate: new Date(currentYear + 1, 11, 29),
          color: '#8BE362',
        },
      ]);
    }
  }, [bookings, target]);

  useEffect(() => {
    getAllBookings();
    getAllAdTypes();
  }, [getAllAdTypes, getAllBookings]);

  useEffect(() => {
    if (calendarData) {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [calendarData]);

  useEffect(() => {
    if (calendarData.length == dataResponseLength + 1) {
      let testArr = calendarData.filter((i) => i.color === '#FFBA52').sort(dateSort('startDate'));
      let newArr: any[] = [];
      let check = false;
      testArr.forEach((i, idx) => {
        newArr.push(i);
        testArr.forEach((j) => {
          if (
            (dayjs(i.startDate).isBetween(
              dayjs(j.startDate).subtract(1, 'd'),
              dayjs(j.endDate).add(1, 'd'),
              'd',
            ) &&
              i.id != j.id &&
              i.adType != j.adType) ||
            (dayjs(i.endDate).isBetween(
              dayjs(j.startDate).subtract(1, 'd'),
              dayjs(j.endDate).add(1, 'd'),
              'd',
            ) &&
              i.id != j.id &&
              i.adType != j.adType)
          ) {
            newArr.push(j);
            if (newArr.length === 4) {
              let firstDate = newArr.sort(dateSort('startDate'))[3].startDate;
              let lastDate = newArr.sort(dateSort('endDate'))[0].endDate;
              check = true;
              if (check) {
                setCalendarData((e) => [
                  {
                    id: 3,
                    name: 'Medium Days',
                    startDate: dayjs(firstDate),
                    endDate: dayjs(lastDate),
                    color: '#FE4D4D',
                  },
                  ...e,
                ]);
                check = false;
              }
              newArr = [];
            }
          }
        });
        newArr = [];
      });
    }
  }, [calendarData, dataResponseLength]);

  useEffect(() => {
    getAdsTargetLazy();
    getAllCompanies();
  }, [getAdsTargetLazy, getAllCompanies]);

  const labelTarget = () => {
    const temp = advertisementBookingAdsTarget.find((item: any) => {
      return item.value === target;
    });
    return temp?.label;
  };

  const handleAddDefaultBooking = () => {
    setDialogCalendar(true);
    setIsDefaultBooking(true);
  };

  return (
    <div style={{ background: 'none' }}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Select
              name="viewType"
              className={classes.selectInput}
              placeholder="View Type"
              sx={{ fontSize: '15px', mb: 2 }}
              value={target}
              onChange={handleTargetChange}
            >
              {advertisementBookingAdsTarget.map((item, idx) => (
                <MenuItem key={idx} sx={{ fontSize: '16px' }} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {roleUser === ERole.ADMIN && (
              <Button
                variant="contained"
                onClick={handleAddDefaultBooking}
                sx={{
                  ml: 5,
                  height: '55px',
                  background: '#E36285',
                  color: '#F4D23F',
                  textTransform: 'none',
                  padding: '8px 12px',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: '#F9E0E7',
                    color: '#F4D23F',
                  },
                }}
              >
                <AddIcon style={{ fontSize: '30px' }} />
                <Typography style={{ fontSize: '14px' }}>Add Default Booking</Typography>
              </Button>
            )}
          </Box>
          <Typography variant="h4" style={{ fontWeight: 700 }}>
            Calendar {currentYear} {currentMonth < 5 ? '' : ` - ${currentYear + 1}`}
          </Typography>

          <div>
            <MyCalendar handleOnDayClick={handleOnDayClick} dataSource={calendarData} />
            {dialogCalendar && (
              <CalendarDialog
                allAdsTypeTarget={adsTypes?.allAdsTargetContent[0]?.adTypeObjects}
                target={target}
                labelTarget={labelTarget()}
                isDefaultBooking={isDefaultBooking}
                companiesData={dataCompanies?.companies}
                dayOnClick={dayOnClick}
                adTypes={adTypes}
                disabledDays={disableCalendar}
                open={dialogCalendar}
                dataSource={calendarData}
                closeDialog={() => {
                  setDialogCalendar(false);
                  setIsDefaultBooking(false);
                }}
                checkDisableAdsType={checkDisableAdsType(dataBookings, now, adsTypes)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
