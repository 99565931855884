/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useStyles from '../style';
import { Box, Divider, Typography } from '@mui/material';
import { ReactComponent as EditIcon } from '../../../assets/images/edit-icon.svg';
import BookingsForm from './BookingsForm';
import BookingsDetails from './BookingsDetails';
import { useNavigate, useParams } from 'react-router';
import { PATH } from '../../../path/index';
import { RoleContext } from 'components/Layout';
import { GET_ALL_BOOKINGS, GET_BOOKING_BY_ID } from 'graphQL/Bookings/Queries';
import { GET_ALL_COMPANIES, GET_COMPANY_BY_ID } from 'graphQL/Companies/Queries';
import Spinner from 'components/Spiner';
import React, { useContext, useEffect, useState } from 'react';
import { compareDateBooking } from 'utils/compareDateBooking';
import { EStatusDisplay } from 'types/globalType';
import { GET_ALL_ADSTARGETS } from 'graphQL/AdsTarget/Queries';
import { checkDisableAdsType } from 'pages/Calendar';

export default function BookingsView(props) {
  const { salesPersonName } = props;
  const navigate = useNavigate();
  const role = useContext(RoleContext);
  const { id } = useParams();
  const classes = useStyles();
  const [isEdit, setEdit] = useState(false);
  const [bookingData, setBookingData] = useState<any>();
  const [detailCompanyData, setDetailCompanyData] = useState<any>([]);
  const [editBookingData, setEditBookingData] = useState({
    name: '',
    adTypeId: '',
    location: '',
    startDate: '',
    endDate: '',
    quotationPerDay: '',
    companyId: '',
    companyName: '',
    target: '',
    isDefaultBooking: false,
  });
  const [companies, setCompanies] = useState([]);
  const [status, setStatus] = useState<any>('draft');

  const [loading, setLoading] = useState({
    bookingFormLoading: false,
    bookingDetailsLoading: false,
  });

  const [uploadResourceData, setUploadResourceData] = useState([]);

  const [file, setFile] = useState<File[]>([]);
  const now = new Date();

  const onBack = () => {
    if (isEdit) {
      setEdit(false);
    } else {
      navigate(PATH.dashboard.bookings.root);
    }
  };

  const [getBookingById] = useLazyQuery(GET_BOOKING_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setBookingData(res.advertisingBooking);
      setEditBookingData((e) => ({
        ...e,
        name: res.advertisingBooking?.name,
        target: res.advertisingBooking?.target,
        adTypeId: res.advertisingBooking?.adType.id,
        location: res.advertisingBooking?.location,
        startDate: res.advertisingBooking?.startDate,
        endDate: res.advertisingBooking?.endDate,
        quotationPerDay: res.advertisingBooking?.quotationPerDay,
        companyId: res.advertisingBooking?.company?.id,
        companyName: res.advertisingBooking?.company?.companyName,
        isDefaultBooking: res.advertisingBooking?.isDefaultBooking,
      }));
      setStatus(res.advertisingBooking?.status)
    },
  });

  const [getAllBookings, { data: dataBookings }] = useLazyQuery(GET_ALL_BOOKINGS, {
    fetchPolicy: 'no-cache',
  });

  const [getCompanybyId] = useLazyQuery(GET_COMPANY_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setDetailCompanyData(response?.company);
      setEditBookingData((e) => ({
        ...e,
        companyName: response.company.companyName,
      }));
    },
    onError(error) {
      console.log('Login Failed: ', error);
    },
  });

  const [getAllCompanies] = useLazyQuery(GET_ALL_COMPANIES, {
    onCompleted(res) {
      setCompanies(res.companies);
    },
  });

  const [getAdsTargetLazy, { data: adsTypes }] = useLazyQuery(GET_ALL_ADSTARGETS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (id) {
      const variables = {
        id: id,
      };
      getBookingById({ variables });
    }
  }, [getBookingById, id]);

  useEffect(() => {
    getAllCompanies();
    getAdsTargetLazy();
    getAllBookings();
  }, [getAdsTargetLazy, getAllCompanies, getAllBookings]);

  useEffect(() => {
    if (adsTypes && detailCompanyData) {
      setTimeout(() => {
        setLoading((e) => ({
          ...e,
          bookingFormLoading: true,
          bookingDetailsLoading: true,
        }));
      }, 1500);
    }
  }, [adsTypes, detailCompanyData]);

  useEffect(() => {
    if (bookingData) {
      const variables = {
        id: bookingData.company.id,
      };
      getCompanybyId({ variables });
    }
  }, [bookingData, getCompanybyId]);
  const allAdsTypeTarget = adsTypes?.allAdsTargetContent[0]?.adTypeObjects?.map((item) => {
    return item.adType;
  });
  const bookingCompleted = compareDateBooking({...editBookingData, status: status})?.status !== EStatusDisplay.COMPLETED;
  return (
    <>
      {loading.bookingDetailsLoading === false && loading.bookingFormLoading === false ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
            <Box sx={{ width: '100px' }}>
              <KeyboardBackspaceIcon sx={{ fontSize: '34px' }} onClick={onBack} />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography className={classes.detailTitle}>
                  {detailCompanyData.tradingName}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {(role === 'admin' || salesPersonName === bookingData?.salesName) && bookingCompleted && !isEdit ? (
                    <>
                      <Typography className={classes.editLabelIcon}>Edit</Typography>
                      <EditIcon onClick={() => setEdit(true)} />
                    </>
                  ) : (
                    <div></div>
                  )}
                </Box>
              </Box>
              <Divider sx={{ border: '1px solid #000000' }} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
            <Box className="123" sx={{ width: '100px' }}></Box>
            <Box className={classes.contentBox}>
              <BookingsForm
                isEdit={isEdit}
                data={bookingData}
                id={id}
                setEditBookingData={setEditBookingData}
                editBookingData={editBookingData}
                uploadResourceData={uploadResourceData}
                setUploadResourceType={setUploadResourceData}
                companies={companies}
                adTypes={allAdsTypeTarget}
                setFile={setFile}
                file={file}
                checkDisableAdsType={checkDisableAdsType(dataBookings, now, adsTypes)}
              />
              <Box className={classes.detailBox}>
                <BookingsDetails
                  isEdit={isEdit}
                  data={bookingData}
                  editBookingData={editBookingData}
                  id={id}
                  setEditBookingData={setEditBookingData}
                  detailCompanyData={detailCompanyData}
                  uploadResourceData={uploadResourceData}
                  file={file}
                  setFile={setFile}
                  setIsEdit={setEdit}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
