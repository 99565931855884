import { makeStyles } from '@material-ui/core';
const breakPoint = 890;
const largeBreakPoint = 1204;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  headerText: {
    '&.MuiTypography-h2': {
      fontWeight: 700,
      lineHeight: '54.43px',
    },
  },
  searchPaper: {
    '&.MuiPaper-root': {
      padding: '8px 10px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: '13px',
    },
    marginRight: '20px',
    marginBottom: '12px',
    [`@media (min-width:${breakPoint}px)`]: {
      marginBottom: '0px',
    },
  },
  input: {
    '&.MuiInputBase-root': {
      width: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      fontWeight: 300,
      fontSize: '2rem',
    },
    '&.MuiOutlinedInput-input': {
      fontSize: '2rem',
      padding: '5px 10px',
    },
    '&.MuiFormHelperText-root': {
      fontSize: '1.5rem',
      color: 'red',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
  },
  inputHeader: {
    fontSize: '20px !important',
    fontWeight: 700,
    lineHeight: '26px !important',
    marginBottom: '5px !important',
  },
  button: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: '#F4D23F',
      backgroundColor: '#E36285',
      borderRadius: '13px',
      fontSize: '21px',
      lineHeight: '33,62px',
      fontWeight: 700,
      padding: '10px 66px',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#E36285',
    },
  },
  deleteTitle: {
    '&.MuiTypography-root': {
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '26px',
      cursor: 'pointer',
    },
  },
  searchAndAdd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  tableContainer: {
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F9E0E7',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E36285',
      width: '4px',
      borderRadius: '12px',
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      fontSize: '1.7rem',
    },
  },
}));

export default useStyles;
