/* eslint-disable  */
import { useLazyQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import { toastError, toastSuccess } from 'components/Toast';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { CREATE_NEW_BOOKING } from 'graphQL/Bookings/Mutations';
import { GET_ALL_BOOKINGS } from 'graphQL/Bookings/Queries';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { useNavigate } from 'react-router';
import { FLOAT } from 'types/globalType';
import { selectLocation } from 'utils/constants';
import { handleRegexEvent } from 'utils/regexConfig';
import { PATH } from '../../path/index';
import useStyles from './style';

dayjs.extend(isBetween);
interface BookingType {
  name: string;
  start: any;
  end: any;
  adType: string;
  location: string;
  company: string;
  costPerDay: Number | string;
}

interface Error {
  name: boolean;
  costPerDay: boolean;
}

export const checkErrorName = (error: Error, bookingForms: BookingType[]) => {
  return error.name && !Boolean(bookingForms[bookingForms.length - 1]?.name);
};

export const checkErrorCostPerDay = (error: Error, bookingForms: BookingType[]) => {
  return bookingForms[bookingForms.length - 1].costPerDay !== 0 && error.costPerDay;
};

export default function CalendarDialog({
  allAdsTypeTarget,
  target,
  labelTarget,
  isDefaultBooking,
  companiesData,
  open,
  closeDialog,
  dataSource,
  disabledDays,
  adTypes,
  dayOnClick,
  checkDisableAdsType,
}) {
  const navigate = useNavigate();

  const [addNewBooking] = useMutation(CREATE_NEW_BOOKING, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      toastSuccess('Create Booking Success');
      closeDialog();
      navigate(PATH.dashboard.bookings.root);
    },
    onError(err) {
      toastError('Create Booking Fail');
    },
  });
  const [state, setState] = useState({
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  });
  const now = new Date();
  const yesterday = moment(now).add(-1, 'days').hasOwnProperty('_d')
    ? moment(now).add(-1, 'days')['_d']
    : moment(now).add(-1, 'days');
  const [bookingForms, setBookingForm] = useState<Array<BookingType>>([]);
  const [step, setStep] = useState(1);
  const [modifiers, setModifiers] = useState({ start: state.from, end: state.enteredTo });
  const [selectedDays, setSelectedDays] = useState([
    state.from,
    { from: state.from, to: state.enteredTo },
  ]);
  const [error, setError] = useState({
    name: false,
    costPerDay: false,
  });
  const [errSelect, setErrSelect] = useState(false);
  const classes = useStyles();

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = (day) => {
    const { from, to } = state;
    if (from && to && day >= from && day <= to) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setState({
        from: day,
        to: undefined,
        enteredTo: undefined,
      });
    } else {
      setState({
        ...state,
        to: day,
        enteredTo: day,
      });
    }
  };

  const handleResetClick = () => {
    setState({
      from: undefined,
      to: undefined,
      enteredTo: undefined,
    });
  };

  const handleDayMouseEnter = (day) => {
    const { from, to } = state;
    if (!isSelectingFirstDay(from, to, day)) {
      setState({
        ...state,
        enteredTo: day,
      });
    }
  };

  const handleButtonClick = () => {
    const checkForm = Object.keys(bookingForms[0]).filter((item: string) => {
      return (
        bookingForms[bookingForms.length - 1][item] === '' ||
        bookingForms[bookingForms.length - 1][item] === undefined
      );
    }).length;
    if (step === 1) {
      const tempStep = step + 1;
      setStep(tempStep);
    } else if (step === 2) {
      setError({
        name: true,
        costPerDay: true,
      });
      setErrSelect(true);
      if (!checkForm) {
        const tempStep = step + 1;
        setStep(tempStep);
      }
    } else if (step === 3) {
      bookingForms.forEach((itemBooking: BookingType) => {
        const advertiseBooking = {
          name: itemBooking.name,
          adTypeId: adTypes.filter((e) => e.name === itemBooking.adType)[0].id,
          location: itemBooking.location,
          startDate: dayjs(itemBooking.start),
          endDate: dayjs(itemBooking.end),
          quotationPerDay: itemBooking.costPerDay,
          companyId: itemBooking.company,
          companyName: companiesData.filter((e) => e.id === itemBooking.company)[0]?.companyName,
          target: target,
        };
        const variables = {
          advertiseBooking: !isDefaultBooking
            ? advertiseBooking
            : {
                ...advertiseBooking,
                isDefaultBooking: true,
              },
        };
        addNewBooking({ variables });
      });
    }
  };

  const onChangeAdType = (e) => {
    const tempBookingForms = [...bookingForms];
    const currentIndex = tempBookingForms.length - 1;
    tempBookingForms[currentIndex] = {
      ...tempBookingForms[currentIndex],
      adType: e.target.value,
    };
    setBookingForm(tempBookingForms);
  };

  const onChangeLocation = (e) => {
    const tempBookingForms = [...bookingForms];
    const currentIndex = tempBookingForms.length - 1;
    tempBookingForms[currentIndex] = {
      ...tempBookingForms[currentIndex],
      location: e.target.value,
    };
    setBookingForm(tempBookingForms);
  };

  const onChangeCostPerDay = (e) => {
    const tempBookingForms = [...bookingForms];
    const currentIndex = tempBookingForms.length - 1;
    tempBookingForms[currentIndex] = {
      ...tempBookingForms[currentIndex],
      costPerDay: e,
    };
    setBookingForm(tempBookingForms);
  };

  const onChangeCompany = (e) => {
    const tempBookingForms = [...bookingForms];
    const currentIndex = tempBookingForms.length - 1;
    tempBookingForms[currentIndex] = {
      ...tempBookingForms[currentIndex],
      company: e.target.value,
    };
    setBookingForm(tempBookingForms);
  };

  const onChangeName = (e) => {
    const tempBookingForms = [...bookingForms];
    const currentIndex = tempBookingForms.length - 1;
    tempBookingForms[currentIndex] = {
      ...tempBookingForms[currentIndex],
      name: e.target.value,
    };
    setBookingForm(tempBookingForms);
  };

  const handleBackStep = () => {
    setStep(step - 1);
  };

  const handleCloseDialog = () => {
    closeDialog();
    setStep(1);
  };

  const resetDatePicker = () => {
    setState({
      from: undefined,
      to: undefined,
      enteredTo: undefined,
    });
  };

  const onRemoveBooking = (removeIndex) => {
    const tempBooking = [...bookingForms].filter((itemBooking, index) => index !== removeIndex);
    setBookingForm(tempBooking);
  };

  const buttonDisable = () => {
    if (step === 1) {
      if (!state.to || !state.from) {
        return true;
      } else {
        return false;
      }
    } else if (step === 3) {
      if (!Boolean(bookingForms.length)) {
        return true;
      } else return false;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!isDefaultBooking) {
      setModifiers({ start: state.from, end: state.enteredTo });
      setSelectedDays([state.from, { from: state.from, to: state.enteredTo }]);

      const tempBookingForms = [...bookingForms];
      const currentIndex = tempBookingForms.length - 1;

      tempBookingForms[currentIndex] = {
        ...tempBookingForms[currentIndex],
        start: state.from,
        end: state.to,
      };
      setBookingForm(tempBookingForms);
    }
  }, [state]);

  useEffect(() => {
    const index = checkDisableAdsType.indexOf(false);
    if (step === 1) {
      resetDatePicker();
      if (companiesData) {
        setBookingForm([
          ...bookingForms,
          {
            name: '',
            start: isDefaultBooking ? now : undefined,
            end: isDefaultBooking
              ? moment(now).add(1, 'year').hasOwnProperty('_d')
                ? moment(yesterday).add(1, 'year')['_d']
                : moment(yesterday).add(1, 'year')
              : undefined,
            adType: allAdsTypeTarget[index]?.adType?.name || '',
            costPerDay: '',
            location: selectLocation[0]?.value,
            company: bookingForms?.length === 0 ? companiesData[0]?.id : bookingForms[0]?.company,
          },
        ]);
      }
    }
  }, [step]);

  useEffect(() => {
    if (isDefaultBooking) {
      setStep(step + 1);
    }
  }, []);

  const getGrantTotal = () => {
    const result = [...bookingForms].reduce((sum: any, itemBooking) => {
      const days = calculatedDays(itemBooking.start, itemBooking.end);
      const totalBill = itemBooking.costPerDay ? days * Number(itemBooking.costPerDay) : 0;
      return sum + totalBill;
    }, 0);
    return result;
  };

  const calculatedDays = (dayStart: any, dayEnd: any) => {
    const result = dayjs(dayEnd).diff(dayjs(dayStart), 'day') + 1;
    return result;
  };

  const tradingNameCompany = () => {
    if (Boolean(bookingForms.length)) {
      const company = companiesData.find((item: any) => {
        return item?.id === bookingForms[bookingForms.length - 1]?.company;
      });
      return company;
    }
  };

  const handleAddBookingDates = () => {
    setStep(1);
    setError({
      name: false,
      costPerDay: false,
    });
  };

  const [getBookingsData] = useLazyQuery(GET_ALL_BOOKINGS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getBookingsData();
  }, [getBookingsData]);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Box
              display="flex"
              flexDirection="row"
              sx={{ width: '100%' }}
              justifyContent="space-between"
            >
              <Box sx={{ width: '45%' }}>
                <Typography style={{ fontSize: '16px', lineHeight: '26px', fontWeight: 700 }}>
                  Start Date
                </Typography>
                <Box
                  style={{
                    width: '100%',
                    border: '1px solid #C4C4C4',
                    borderRadius: '8px',
                    fontSize: '18px',
                    minHeight: '40px',
                  }}
                >
                  <Typography
                    style={{
                      borderRadius: '8px',
                      fontSize: '16px',
                      padding: '8px 12px ',
                    }}
                  >
                    {state.from && moment(state.from).format('LL')}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: '45%' }}>
                <Typography style={{ fontSize: '16px', lineHeight: '26px', fontWeight: 700 }}>
                  End Date
                </Typography>
                <Box
                  style={{
                    width: '100%',
                    border: '1px solid #C4C4C4',
                    borderRadius: '8px',
                    fontSize: '18px',
                    minHeight: '40px',
                  }}
                >
                  <Typography
                    style={{
                      borderRadius: '8px',
                      fontSize: '16px',
                      padding: '8px 12px ',
                    }}
                  >
                    {state.to && moment(state.to).format('LL')}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '32px',
              }}
            >
              <DayPicker
                className="Range"
                numberOfMonths={1}
                fromMonth={state.from}
                initialMonth={new Date(dayOnClick)}
                selectedDays={selectedDays}
                disabledDays={disabledDays}
                modifiers={modifiers}
                onDayClick={handleDayClick}
                onDayMouseEnter={handleDayMouseEnter}
              />
            </Box>
          </>
        );
      case 2:
        return (
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Name</Typography>
            <TextField
              InputLabelProps={{ style: { fontSize: 16, fontWeight: 500 } }}
              InputProps={{ style: { fontSize: 16, fontWeight: 500, height: '55px' } }}
              fullWidth
              onBlur={() => setError({ ...error, name: true })}
              value={bookingForms[bookingForms.length - 1].name}
              error={checkErrorName(error, bookingForms)}
              helperText={
                checkErrorName(error, bookingForms) && (
                  <Typography style={{ fontSize: 14, width: '100%' }}>Name is required</Typography>
                )
              }
              onChange={onChangeName}
            />
            <FormControl
              sx={{ marginTop: '21px', width: '100%' }}
              error={errSelect && !Boolean(bookingForms[bookingForms.length - 1].adType)}
            >
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Ads Type</Typography>
              <Select
                value={bookingForms[bookingForms.length - 1]?.adType}
                onChange={onChangeAdType}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ fontSize: '16px' }}
              >
                {allAdsTypeTarget?.map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      sx={{ fontSize: '16px' }}
                      disabled={checkDisableAdsType[idx]}
                      value={item.adType.name}
                    >
                      {item.adType.name}{' '}
                      {checkDisableAdsType[idx] && (
                        <Chip
                          style={{ marginLeft: '10px', fontSize: '16px' }}
                          label="Sold out"
                          size="medium"
                        ></Chip>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
              {errSelect && !Boolean(bookingForms[bookingForms.length - 1].adType) && (
                <FormHelperText style={{ fontSize: 14 }}>Ads Type is required</FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={{ marginTop: '21px', width: '100%' }}
              error={!Boolean(bookingForms[bookingForms.length - 1].location)}
            >
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Location</Typography>
              <Select
                value={bookingForms[bookingForms.length - 1].location}
                onChange={onChangeLocation}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ fontSize: '16px' }}
              >
                {selectLocation.map((item, idx) => (
                  <MenuItem sx={{ fontSize: '16px' }} key={idx} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {errSelect && !Boolean(bookingForms[bookingForms.length - 1].location) && (
                <FormHelperText style={{ fontSize: 14 }}>Location is required</FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ marginTop: '21px', width: '100%' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Cost per day</Typography>
              <TextField
                value={bookingForms[bookingForms.length - 1].costPerDay}
                className={classes.input}
                onBlur={() => setError({ ...error, costPerDay: true })}
                sx={{ fontSize: '18px', boxShadow: 'none !important' }}
                onChange={(e) => onChangeCostPerDay(handleRegexEvent(e, FLOAT))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography style={{ fontSize: '18px' }}>$</Typography>
                    </InputAdornment>
                  ),
                  style: { fontSize: 16, fontWeight: 500, height: '55px' },
                }}
                error={
                  checkErrorCostPerDay(error, bookingForms) &&
                  !Boolean(bookingForms[bookingForms.length - 1].costPerDay)
                }
                helperText={
                  checkErrorCostPerDay(error, bookingForms) &&
                  !Boolean(bookingForms[bookingForms.length - 1].costPerDay) && (
                    <Typography style={{ fontSize: 14, width: '100%' }}>
                      Cost per day is required
                    </Typography>
                  )
                }
              />
            </FormControl>
            <FormControl
              sx={{ marginTop: '21px', width: '100%' }}
              error={!Boolean(bookingForms[bookingForms.length - 1].company)}
            >
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Trading Name</Typography>
              <Select
                value={bookingForms[bookingForms.length - 1].company || companiesData[0].id}
                onChange={onChangeCompany}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ fontSize: '18px' }}
                disabled={bookingForms.length > 1}
              >
                {companiesData.map((item, idx) => (
                  <MenuItem sx={{ fontSize: '16px' }} key={idx} value={item.id}>
                    {item.tradingName}
                  </MenuItem>
                ))}
              </Select>
              {errSelect && !Boolean(bookingForms[bookingForms.length - 1].company) && (
                <FormHelperText style={{ fontSize: 14 }}>Company is required</FormHelperText>
              )}
            </FormControl>
          </Box>
        );
      case 3:
        return (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Booking Summary</Typography>
                {!isDefaultBooking && (
                  <Button
                    variant="contained"
                    onClick={handleAddBookingDates}
                    sx={{
                      background: '#E36285',
                      color: '#F4D23F',
                      textTransform: 'none',
                      padding: '8px 12px',
                      fontSize: '16px',
                      marginTop: '12px',
                      '&:hover': {
                        backgroundColor: '#F9E0E7',
                        color: '#F4D23F',
                      },
                    }}
                  >
                    <AddIcon style={{ fontSize: '30px' }} />
                    <Typography style={{ fontSize: '14px' }}>Add Booking Dates</Typography>
                  </Button>
                )}
              </Box>
              <Box sx={{ width: '100%' }}>
                {bookingForms.map((itemBooking, index) => (
                  <Paper
                    key={index}
                    elevation={3}
                    sx={{
                      width: '100%',
                      boxSizing: 'border-box',
                      padding: '16px',
                      marginTop: '32px',
                    }}
                  >
                    <Typography style={{ fontSize: '18px', fontWeight: 700 }}>
                      {`Name: ${itemBooking.name}`}
                    </Typography>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography style={{ fontSize: '18px', fontWeight: 700 }}>
                        {itemBooking.adType}{' '}
                        <span style={{ fontSize: '18px', fontWeight: 300 }}>
                          ({itemBooking.location})
                        </span>
                      </Typography>
                      {!isDefaultBooking && (
                        <Button
                          sx={{
                            width: 'initial',
                            background: 'transparent !important',
                            minWidth: 0,
                            padding: 0,
                            cursor: 'pointer',
                          }}
                          onClick={() => onRemoveBooking(index)}
                        >
                          <HighlightOffIcon style={{ fontSize: '30px' }} />
                        </Button>
                      )}
                    </Box>
                    <Typography style={{ fontSize: '18px', color: '#E36285' }}>
                      {`Ads Target: ${labelTarget}`}
                    </Typography>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Typography style={{ fontSize: '18px' }}>
                        {moment(itemBooking.start).format('LL')}
                      </Typography>
                      <Arrow style={{ margin: '0 12px' }} />
                      <Typography style={{ fontSize: '18px' }}>
                        {moment(itemBooking.end).format('LL')}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '26px',
                        position: 'relative',
                        top: '-30px',
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '18px',
                          fontWeight: 500,
                          color: '#E36285',
                          width: '90px',
                          textAlign: 'center',
                        }}
                      >
                        {calculatedDays(itemBooking.start, itemBooking.end)}days
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginTop: '26px',
                      }}
                    >
                      <Typography style={{ fontSize: '18px' }}>
                        Cost Der Day: ${itemBooking.costPerDay}
                      </Typography>
                      <Typography style={{ fontSize: '18px', fontWeight: 700 }}>
                        Total: $
                        {itemBooking.costPerDay &&
                          calculatedDays(itemBooking.start, itemBooking.end) *
                            Number(itemBooking.costPerDay)}
                      </Typography>
                    </Box>
                  </Paper>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
                marginTop: '32px',
                borderTop: '1px solid #000',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography style={{ fontSize: '18px', marginTop: '15px', color: '#E36285' }}>
                  {tradingNameCompany()?.tradingName}
                </Typography>
                <Typography style={{ fontSize: '18px', marginTop: '15px' }}>
                  GTS(10%): ${getGrantTotal() / 10}
                </Typography>
              </Box>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography style={{ fontSize: '18px', marginTop: '15px', color: '#E36285' }}>
                  {tradingNameCompany()?.companyName}
                </Typography>
                <Typography
                  style={{ fontSize: '18px', marginTop: '12px', color: '#E36285', fontWeight: 700 }}
                >
                  Grand Total: ${getGrantTotal() + getGrantTotal() / 10}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCloseDialog} sx={{ overflowX: 'none', display: 'block' }}>
        <Box
          sx={{
            width: '100%',
            height: '50px',
            display: 'flex',
            justifyContent: step > 1 ? 'space-between' : 'flex-end',
          }}
        >
          {step > 1 && (
            <Button onClick={handleBackStep}>
              <ArrowBackIosIcon style={{ fontSize: '25px' }} />
            </Button>
          )}

          <Button onClick={handleCloseDialog}>
            <CloseIcon style={{ fontSize: '32px' }} />
          </Button>
        </Box>
        <div className={classes.contentDialog} style={{ display: 'block' }}>
          {renderContent()}
        </div>
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '24px' }}
        >
          {step > 0 ? (
            <Button
              variant="contained"
              sx={{
                background: '#E36285',
                color: '#F4D23F',
                textTransform: 'none',
                padding: '8px 24px',
                fontSize: '16px',
                marginTop: '12px',
                '&:hover': {
                  backgroundColor: '#F9E0E7',
                  color: '#fff',
                },
              }}
              onClick={handleButtonClick}
              disabled={buttonDisable()}
            >
              {step < 3 ? 'Next' : 'Submit'}
            </Button>
          ) : (
            <div></div>
          )}
        </Box>
      </Dialog>
    </div>
  );
}
