import { makeStyles } from '@material-ui/core';

const breakPoint = 890;

const useStyles = makeStyles((theme) => ({
  headerOption: {
    '&.MuiTypography-h2': {
      fontWeight: '600',
      cursor: 'pointer',
      lineHeight: '38.42px',
    },
  },
  userIcon: {
    fontSize: '47px',
    color: '#E36285',
  },
  menuRoot: {
    '& .MuiList-root': {
      background: '#F9E0E7',
    },
    '& .MuiMenu-root': {
      background: 'none',
      boxShadow: 'none',
    },
    '& .MuiModal-root': {
      background: 'none',
      boxShadow: 'none',
    },
  },
  menuRoot2: {
    overflow: 'auto',
    '& .MuiList-root': {
      background: '#F9E0E7',
    },
    [`@media (min-width: ${breakPoint}px)`]: {
      display: 'none',
    },
  },
  menuItem: {
    cursor: 'pointer',
    '&.MuiMenuItem-root': {
      padding: '12px 24px',
    },
  },
  logoHeader: {},
  optionBox: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menuInMobile: {
    display: 'flex',
    [`@media (min-width: ${breakPoint}px)`]: {
      display: 'none',
    },
  },
  menuItemText: {
    minWidth: '150px',
    fontSize: '1.6rem !important',
    cursor: 'pointer !important',
  },
}));

export default useStyles;
