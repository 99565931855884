import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputBase,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import React from 'react';
import { useStyles } from './style';
import CloseIcon from '@mui/icons-material/Close';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ADSTYPES } from 'graphQL/AdsType/Queries';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function ConfigurationsDialog(props) {
  const { isDialogOpen, setIsDialogOpen } = props;
  const classes = useStyles();
  const [renderCase, setRenderCase] = React.useState('default');
  const [isEdit, setIsEdit] = React.useState(false);
  const [banner1price, setBanner1price] = React.useState('30.00');
  const [banner2price, setBanner2price] = React.useState('30.00');
  const [banner3price, setBanner3price] = React.useState('30.00');
  const [caraselprice, setCaraselprice] = React.useState('30.00');
  const [isClicked, setIsClicked] = React.useState(false);
  const [adTypes, setAdTypes] = React.useState<any>([]);
  const [adType, setAdType] = React.useState('');

  const [getAllAdTypes] = useLazyQuery(GET_ALL_ADSTYPES, {
    onCompleted(res) {
      setAdTypes(res.advertiseTypes);
    },
  });

  React.useEffect(() => {
    getAllAdTypes();
  }, [getAllAdTypes]);

  const handleClose = () => {
    setRenderCase('default');
    setIsDialogOpen(false);
    setIsClicked(false);
  };

  const handleEdit = (e) => {
    setRenderCase(e.target.outerText.toLowerCase());
    setIsClicked(true);
  };

  const handleEditPrice = () => {
    setIsEdit(true);
  };

  const handleBack = () => {
    setIsEdit(false);
    setIsClicked(false);
    setRenderCase('default');
    setAdType('');
  };

  const handleSavePrice = () => {
    setIsEdit(false);
  };

  const handleChangeBanner1Price = (e) => {
    setBanner1price(e.target.value);
  };

  const handleChangeBanner2Price = (e) => {
    setBanner2price(e.target.value);
  };
  const handleChangeBanner3Price = (e) => {
    setBanner3price(e.target.value);
  };
  const handleChangeCaraselPrice = (e) => {
    setCaraselprice(e.target.value);
  };

  const handleChangeAdType = (e) => {
    setAdType(e.target.value);
    setIsClicked(true);
  };

  const renderContent = () => {
    switch (renderCase) {
      case 'default':
        return (
          <>
            <Box sx={{ ml: 8, mb: 3 }}>
              <Typography
                className={classes.configTypo}
                sx={{ fontSize: 18, fontWeight: 600, fontFamily: 'Hind' }}
              >
                Ads Type:
              </Typography>
            </Box>
            <Grid container spacing={3} sx={{ textAlign: 'center' }}>
              <Grid item xs={12}>
                <Button className={classes.adsbutton} onClick={(e) => handleEdit(e)}>
                  <Typography variant="h4" sx={{ fontFamily: 'Hind' }}>
                    Banner1
                  </Typography>
                </Button>
                <Button className={classes.adsbutton} onClick={(e) => handleEdit(e)}>
                  <Typography variant="h4" sx={{ fontFamily: 'Hind' }}>
                    Banner2
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button className={classes.adsbutton} onClick={(e) => handleEdit(e)}>
                  <Typography variant="h4" sx={{ fontFamily: 'Hind' }}>
                    Banner3
                  </Typography>
                </Button>
                <Button className={classes.adsbutton} onClick={(e) => handleEdit(e)}>
                  <Typography variant="h4" sx={{ fontFamily: 'Hind' }}>
                    Carousel
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        );
      case 'banner1':
        return (
          <>
            <Box className={classes.inputBox}>
              <Typography variant="h3" sx={{ fontSize: 24, fontWeight: 600 }}>
                Banner1:
              </Typography>
              <FormControl sx={{ marginTop: '21px' }}>
                <Typography variant="h3" sx={{ fontSize: 18, mb: 1 }}>
                  Price ($):
                </Typography>
                <InputBase
                  value={banner1price}
                  disabled={!isEdit}
                  className={classes.input}
                  sx={{ fontSize: '21px' }}
                  onChange={handleChangeBanner1Price}
                />
              </FormControl>
            </Box>
          </>
        );
      case 'banner2':
        return (
          <>
            <Box className={classes.inputBox}>
              <Typography variant="h3" sx={{ fontSize: 24, fontWeight: 600 }}>
                Banner2:
              </Typography>
              <FormControl sx={{ marginTop: '21px' }}>
                <Typography variant="h3" sx={{ fontSize: 18, mb: 1 }}>
                  Price ($):
                </Typography>
                <InputBase
                  value={banner2price}
                  disabled={!isEdit}
                  className={classes.input}
                  sx={{ fontSize: '21px' }}
                  onChange={handleChangeBanner2Price}
                />
              </FormControl>
            </Box>
          </>
        );
      case 'banner3':
        return (
          <>
            <Box className={classes.inputBox}>
              <Typography variant="h3" sx={{ fontSize: 24, fontWeight: 600, fontFamily: 'Hind' }}>
                Banner3:
              </Typography>
              <FormControl sx={{ marginTop: '21px' }}>
                <Typography variant="h3" sx={{ fontSize: 18, mb: 1 }}>
                  Price ($):
                </Typography>
                <InputBase
                  value={banner3price}
                  disabled={!isEdit}
                  className={classes.input}
                  sx={{ fontSize: '21px' }}
                  onChange={handleChangeBanner3Price}
                />
              </FormControl>
            </Box>
          </>
        );
      case 'carousel':
        return (
          <>
            <Box className={classes.inputBox}>
              <Typography variant="h3" sx={{ fontSize: 24, fontWeight: 600 }}>
                carousel:
              </Typography>
              <FormControl sx={{ marginTop: '21px' }}>
                <Typography variant="h3" sx={{ fontSize: 18, mb: 1 }}>
                  Price ($):
                </Typography>
                <InputBase
                  fullWidth={true}
                  value={caraselprice}
                  disabled={!isEdit}
                  className={classes.input}
                  sx={{ fontSize: '21px' }}
                  onChange={handleChangeCaraselPrice}
                />
              </FormControl>
            </Box>
            <Box></Box>
          </>
        );
    }
  };

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialogRoot}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleClose}>
            <CloseIcon style={{ fontSize: '32px' }} />
          </Button>
        </Box>
        <Box sx={{ pb: 0 }}>
          <Typography
            className={classes.configHeader}
            sx={{ textAlign: 'center', pb: 2, fontSize: 24, fontWeight: 600, fontFamily: 'Hind' }}
          >
            Configuration
          </Typography>
          {!isClicked && (
            <Box sx={{ px: 5, pb: 5 }}>
              <Typography
                className={classes.configTypo}
                sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Hind', mb: 2 }}
              >
                Select Ads Type to config:
              </Typography>
              <FormControl fullWidth>
                <TextField
                  select
                  value={adType}
                  onChange={handleChangeAdType}
                  className={classes.selectInput}
                  fullWidth
                  sx={{ fontSize: '21px' }}
                >
                  {adTypes?.map((item, idx) => {
                    return (
                      <MenuItem key={idx} sx={{ fontSize: '16px' }} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Box>
          )}
        </Box>
        {isClicked ? (
          <>
            <Box sx={{ ml: 3, mb: 3 }}>
              <Typography
                className={classes.configTypo}
                sx={{ fontSize: 18, fontWeight: 400, fontFamily: 'Hind' }}
              >
                <b>Name:</b> {adType}
              </Typography>
              {isEdit ? (
                <FormControl sx={{ marginTop: '21px' }}>
                  <Typography
                    variant="h3"
                    sx={{ fontSize: 18, mb: 1, fontWeight: 600, fontFamily: 'Hind' }}
                  >
                    Price ($):
                  </Typography>
                  <InputBase
                    value={banner1price}
                    className={classes.input}
                    sx={{ fontSize: '21px' }}
                    onChange={handleChangeBanner1Price}
                  />
                </FormControl>
              ) : (
                <Typography
                  className={classes.configTypo}
                  sx={{ fontSize: 18, fontWeight: 400, fontFamily: 'Hind' }}
                >
                  <b>Price ($):</b> {banner1price}
                </Typography>
              )}
            </Box>
            <DialogActions>
              {isEdit ? (
                <>
                  <Button className={classes.cancelBtn} onClick={handleBack}>
                    Cancel
                  </Button>
                  <Button className={classes.activeBtn} onClick={handleSavePrice}>
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button className={classes.cancelBtn} onClick={handleBack}>
                    Back
                  </Button>
                  <Button className={classes.activeBtn} onClick={handleEditPrice}>
                    Edit
                  </Button>
                </>
              )}
            </DialogActions>
          </>
        ) : (
          <div></div>
        )}
      </Dialog>
    </div>
  );
}

export default ConfigurationsDialog;
