import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Divider, List, ListItem } from '@mui/material';
import Spinner from 'components/Spiner';
import { toastError } from 'components/Toast';
import { GET_COMPANY_BY_ID } from 'graphQL/Companies/Queries';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ReactComponent as EditIcon } from '../../../assets/images/edit-icon.svg';
import { PATH } from '../../../path/index';
import AddOrEditCompaniesForm from './AddOrEditCompaniesForm';

export default function CompanyDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [detailCompanyData, setDetailCompanyData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [getCompanybyId] = useLazyQuery(GET_COMPANY_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setDetailCompanyData(response?.company);
      setIsLoading(false);
    },
    onError(error) {
      toastError('An error occurred, please try again');
      console.log('Login Failed: ', error);
    },
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const headers = [
    {
      name: 'Company Name',
      value: detailCompanyData.companyName,
    },
    {
      name: 'Trading Name',
      value: detailCompanyData.tradingName,
    },
    {
      name: 'Contact Name',
      value: `${detailCompanyData.contactFirstName} ${detailCompanyData.contactLastName}`,
    },
    {
      name: 'Email',
      value: detailCompanyData.email,
    },
    {
      name: 'Phone Number',
      value: detailCompanyData.phoneNumber,
    },
    {
      name: 'Category',
      value: detailCompanyData.category,
    },
    {
      name: 'Tags',
      value: detailCompanyData?.tags,
    },
  ];

  const onBack = () => {
    if (isEdit) {
      setIsEdit(false);
    } else {
      navigate(PATH.dashboard.companies.root);
    }
  };

  const handleNonDelete = () => {
    setIsDelete(false);
  };

  const handleNonEdit = () => {
    setIsSave(false);
  };

  useEffect(() => {
    if (id) {
      const variables = {
        id: id,
      };
      getCompanybyId({ variables });
    }
  }, [getCompanybyId, id]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ width: '100px' }}>
          <KeyboardBackspaceIcon sx={{ fontSize: '36px' }} onClick={onBack} />
        </Box>
        <div style={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography style={{ fontSize: '34px', fontWeight: '700', marginRight: '40px' }}>
              Company Details
            </Typography>
            {!isEdit && <EditIcon onClick={() => setIsEdit(true)} />}
          </Box>
          <Divider />
          {isLoading ? (
            <Spinner />
          ) : isEdit ? (
            <AddOrEditCompaniesForm
              companyId={id}
              editData={detailCompanyData}
              isSave={isSave}
              setIsSave={setIsSave}
              isDelete={isDelete}
              handleNonDelete={handleNonDelete}
              handleNonEdit={handleNonEdit}
              setIsDelete={setIsDelete}
              action="edit"
            />
          ) : (
            <Box>
              <List component="nav" aria-label="mailbox folders">
                {headers.map((acc, idx) => (
                  <ListItem key={idx} button>
                    <Box sx={{ width: '200px' }}>
                      <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
                        {acc.name}
                      </Typography>
                    </Box>
                    <Typography style={{ fontSize: '20px', fontWeight: '400' }}>
                      {acc.value ? acc.value.toString() : ''}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </div>
      </div>
    </>
  );
}
