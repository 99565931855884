import { gql } from '@apollo/client';

export const GET_ALL_BOOKINGS = gql`
  query AdvertiseBookings {
    advertiseBookings {
      id
      adType {
        id
        name
        description
        viewType
      }
      location
      startDate
      endDate
      company {
        abn
        category
        companyName
        contactFirstName
        contactLastName
        createdAt
        email
        phoneNumber
        tags
        tradingName
      }
      quotationPerDay
      salesName
      status
      target
      isDefaultBooking
    }
  }
`;

export const GET_BOOKING_BY_ID = gql`
  query AdvertisingBooking($id: ID!) {
    advertisingBooking(id: $id) {
      adType {
        id
        name
        description
        viewType
      }
      name
      location
      startDate
      endDate
      company {
        abn
        category
        companyName
        contactFirstName
        contactLastName
        createdAt
        email
        id
        phoneNumber
        tags
        tradingName
      }
      quotationPerDay
      salesName
      status
      target
      resources {
        id
        description
        adTypeId
        companyId
        bookingId
        fileUrl
        type
        tags
        language
        externalUrl
      }
      isDefaultBooking
    }
  }
`;
