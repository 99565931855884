import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LabelSortIcon from 'components/LabelSortIcon';
import { GET_ALL_ADSTYPES } from 'graphQL/AdsType/Queries';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ascSort, descSort } from 'utils/businessLogic';
import { advertisementType } from 'utils/constants';
import { PATH } from '../../../path/index';
import useStyles from '../style';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E36285',
    color: theme.palette.common.black,
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '34px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: '400',
    fontSize: '16px',
    backgroundColor: '#E5E5E5',
    lineHeight: '22px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 9,
  },
}));

interface IData {
  id: number;
  name: string;
  description: string;
  viewType: string;
}

export default function AdTypeTable({ search }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [list, setList] = useState<any[]>();
  const [dataNoneSort, setDataNoneSort] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState('');
  const [fieldSort, setFieldSort] = useState('');

  const [getAdsTypeLazy, { data: data }] = useLazyQuery(GET_ALL_ADSTYPES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.advertiseTypes.length > 0) {
      const listFilter = data.advertiseTypes.filter(
        (item: IData) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.description.toLowerCase().includes(search.toLowerCase()) ||
          item.viewType.toLowerCase().includes(search.toLowerCase()),
      );
      const temp =
        fieldSort !== ''
          ? sortBy === 'asc'
            ? listFilter.sort(ascSort(fieldSort))
            : listFilter.sort(descSort(fieldSort))
          : listFilter;
      setList(temp);
      setDataNoneSort(listFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.advertiseTypes, search]);

  const onAdsTypeDetail = (id) => {
    navigate(PATH.dashboard.adTypes.detail(id.id));
  };

  useEffect(() => {
    getAdsTypeLazy();
  }, [getAdsTypeLazy]);

  return (
    <>
      {list ? (
        <TableContainer className={classes.tableContainer}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <LabelSortIcon
                    title="Name"
                    field="name"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <LabelSortIcon
                    title="View Type"
                    field="viewType"
                    list={list}
                    setList={setList}
                    dataNoneSort={dataNoneSort}
                    setSortBy={setSortBy}
                    setFieldSort={setFieldSort}
                  />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ paddingTop: '32px' }}>
              <TableRow sx={{ height: '32px' }}></TableRow>
              {list.length > 0 ? (
                list.map((row: IData) => (
                  <Fragment key={row.id}>
                    <StyledTableRow onClick={() => onAdsTypeDetail(row)}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {advertisementType.filter((e) => e.value === row.viewType)[0].label}
                      </StyledTableCell>
                    </StyledTableRow>
                    <TableRow sx={{ height: '7px' }}></TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={2} sx={{ py: 3 }}>
                    <Typography variant="h4">No ads type found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}
