import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Box, TextField, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { DateRangePicker, DateRange } from '@mui/lab';
import { Bar } from 'react-chartjs-2';
import useStyles from '../style';
import BarChart from 'components/Chart/Bar/BarChart';
import DatePicker from './DatePicker';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const fakeData = [
  {
    name: 'name 1',
    value: 62,
  },
  {
    name: 'name 2',
    value: 17,
  },
  {
    name: 'name 3',
    value: 16,
  },
  {
    name: 'name 4',
    value: 98,
  },
  {
    name: 'name 5',
    value: 21,
  },
  {
    name: 'name 6',
    value: 34,
  },
  {
    name: 'name 7',
    value: 46,
  },
  {
    name: 'name 8',
    value: 52,
  },
  {
    name: 'name 9',
    value: 42,
  },
  {
    name: 'name 10',
    value: 64,
  },
  {
    name: 'name 11',
    value: 98,
  },
  {
    name: 'name 12',
    value: 86,
  },
  {
    name: 'name 13',
    value: 16,
  },
  {
    name: 'name 14',
    value: 85,
  },
  {
    name: 'name 15',
    value: 36,
  },
  {
    name: 'name 16',
    value: 22,
  },
  {
    name: 'name 17',
    value: 99,
  },
  {
    name: 'name 18',
    value: 16,
  },
  {
    name: 'a very long name abcxyz 123',
    value: 64,
  },
  {
    name: 'name 20',
    value: 72,
  },
];

function SalePersonReport(props) {
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
  const classes = useStyles();

  return (
    <div>
      <Box>
        <DatePicker value={value} setValue={setValue} />
      </Box>
      <Box className={classes.chartBox}>
        <BarChart data={fakeData} name="Sales Amount" />
      </Box>
    </div>
  );
}

export default SalePersonReport;
