import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Divider, List, ListItem } from '@mui/material';
import { GET_ALL_ADSTYPES } from 'graphQL/AdsType/Queries';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { AdvertiseViewType } from '__generated__/globalTypes';
import { ReactComponent as EditIcon } from '../../../assets/images/edit-icon.svg';
import { PATH } from '../../../path/index';
import AdTypeForm from './AdTypeForm';

export default function AdTypeDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [detailAdsType, setDetailAdsType] = useState<any>([]);

  const [getAdsTypeLazy] = useLazyQuery(GET_ALL_ADSTYPES, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setDetailAdsType(response.advertiseTypes.filter((e) => e.id === id));
    },
    onError(error) {
      console.log('Login Failed: ', error);
    },
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const adsType = [
    {
      name: 'Name',
      value: detailAdsType[0]?.name,
    },
    {
      name: 'Description',
      value: detailAdsType[0]?.description,
    },
    {
      name: 'View Type',
      value:
        detailAdsType[0]?.viewType === AdvertiseViewType.carasel
          ? 'Carousel'
          : detailAdsType[0]?.viewType,
    },
    {
      name: 'Max Booking Quantity',
      value: detailAdsType[0]?.maxBookingQuantity,
    },
  ];

  const onBack = () => {
    if (isEdit) {
      setIsEdit(false);
    } else {
      navigate(PATH.dashboard.adTypes.root);
    }
  };

  const handleNonDelete = () => {
    setIsDelete(false);
  };

  const handleNonEdit = () => {
    setIsSave(false);
  };

  useEffect(() => {
    if (id) {
      getAdsTypeLazy();
    }
  }, [id, getAdsTypeLazy]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ width: '100px' }}>
          <KeyboardBackspaceIcon sx={{ fontSize: '36px' }} onClick={onBack} />
        </Box>
        <div style={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography style={{ fontSize: '34px', fontWeight: '700', marginRight: '40px' }}>
              Ads Type Details
            </Typography>
            {!isEdit && <EditIcon onClick={() => setIsEdit(true)} />}
          </Box>
          <Divider />
          {isEdit ? (
            <AdTypeForm
              adsTypeDetail={detailAdsType[0]}
              isSave={isSave}
              setIsSave={setIsSave}
              isDelete={isDelete}
              handleNonDelete={handleNonDelete}
              handleNonEdit={handleNonEdit}
              setIsDelete={setIsDelete}
            />
          ) : (
            <Box>
              <List component="nav" aria-label="mailbox folders">
                {adsType.map((acc, idx) => (
                  <ListItem key={idx} button>
                    <Box sx={{ width: '250px' }}>
                      <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
                        {acc.name}
                      </Typography>
                    </Box>
                    <Typography style={{ fontSize: '20px', fontWeight: '400' }}>
                      {' '}
                      {acc.value}{' '}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </div>
      </div>
    </>
  );
}
