import { gql } from '@apollo/client';

export const GET_ALL_ACCOUNTS = gql`
  query Accounts {
    accounts {
      id
      firstName
      lastName
      phoneNumber
      role
      email
      createdAt
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      firstName
      lastName
      phoneNumber
      role
      email
      createdAt
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query Account($email: String) {
    account(email: $email) {
      id
      firstName
      lastName
      phoneNumber
      role
      email
    }
  }
`;
