import Header from 'components/Header';
import Spinner from 'components/Spiner';
import SaleAcounts from 'pages/SaleAcounts';
import React, { lazy, Suspense, useEffect, useContext } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

interface IProps {
  salesPersonName?: string;
}

export default function Dashboard({ salesPersonName }: IProps) {
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <Header salesPersonName={salesPersonName} />
        <div style={{ marginTop: '160px', padding: '0 5%' }}>
          <Outlet />
        </div>
      </Suspense>
    </>
  );
}
