import dayjs from 'dayjs';
import range from 'lodash-es/range';
import React from 'react';
import './style.css';

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = [
  {
    id: 0,
    name: 'January',
  },
  {
    id: 1,
    name: 'Febuary',
  },
  {
    id: 2,
    name: 'March',
  },
  {
    id: 3,
    name: 'April',
  },
  {
    id: 4,
    name: 'May',
  },
  {
    id: 5,
    name: 'June',
  },
  {
    id: 6,
    name: 'July',
  },
  {
    id: 7,
    name: 'August',
  },
  {
    id: 8,
    name: 'September',
  },
  {
    id: 9,
    name: 'October',
  },
  {
    id: 10,
    name: 'November',
  },
  {
    id: 11,
    name: 'December',
  },
];

interface IDataSource {
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
  color: string;
}

interface IProps {
  dataSource: IDataSource[];
  handleOnDayClick: any;
}

const Calendar = ({ dataSource, handleOnDayClick }: IProps) => {
  const dayObj = dayjs();
  const currentMonths = dayObj.month();
  const monthStartSlice = currentMonths;
  const monthEndSlice = monthStartSlice + 12;

  const getEvent = (calendarDay: number, calendarMonth: number, calendarYear: number) => {
    const formatDataSource = dataSource.map((item) => ({
      ...item,
      onEvent:
        new Date(calendarYear, calendarMonth, calendarDay) >= item.startDate &&
        new Date(calendarYear, calendarMonth, calendarDay) <= item.endDate,
    }));
    const tempResult = formatDataSource.filter((item) => item.onEvent);
    if (tempResult.length) {
      return tempResult;
    } else {
      return null;
    }
  };

  return (
    <>
      {[...months, ...months].slice(monthStartSlice, monthEndSlice).map((month, index) => {
        const tempDayobj = dayObj.subtract(currentMonths, 'month').add(month.id, 'month');
        const thisYear =
          monthStartSlice + index + 1 > 12 ? tempDayobj.year() + 1 : tempDayobj.year();
        const thisMonth = tempDayobj.month();
        const daysInMonth = tempDayobj.daysInMonth();
        const dayObjOf1 = dayjs(`${thisYear}-${thisMonth + 1}-1`);
        const weekDayOf1 = dayObjOf1.day();
        const dayObjOfLast = dayjs(`${thisYear}-${thisMonth + 1}-${daysInMonth}`);
        const weekDayOfLast = dayObjOfLast.day();
        const yearIncrease = monthStartSlice + index + 1 > 12 ? 1 : 0;
        return (
          <div key={month.id} className="calendar">
            <div className="header">
              <div className="datetime">{tempDayobj.format('MMMM')}</div>
            </div>
            <div className="calendarWeeks">
              {weekDays.map((d) => (
                <div className="cellCalendar" key={d}>
                  {d}
                </div>
              ))}
            </div>
            <div className="calendarRow">
              {range(weekDayOf1).map((i) => (
                <button className="cell-calendar" key={i}></button>
              ))}

              {range(daysInMonth).map((i) => {
                getEvent(i, thisMonth, thisYear);
                const isOnEvent = getEvent(i, thisMonth, thisYear);
                const boxShadowEvent = isOnEvent
                  ? `${isOnEvent[0].color} 0px -12px 0px 0px inset`
                  : 'none';
                return (
                  <button
                    className="cell-calendar"
                    style={{
                      boxShadow: boxShadowEvent,
                    }}
                    key={i}
                    onClick={() => handleOnDayClick(isOnEvent, month, i, yearIncrease)}
                  >
                    {i + 1}
                  </button>
                );
              })}

              {range(6 - weekDayOfLast).map((i) => (
                <button className="cell-calendar" key={i}>
                  {/* {dayObjOfLast.add(i + 1, 'day').date()} */}{' '}
                </button>
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Calendar;
