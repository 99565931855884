import { CircularProgress, Stack } from '@mui/material';
import React from 'react';
import { ReactComponent as LogoImg } from 'assets/images/Logo.svg';

function Spinner() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        padding: '0',
      }}
    >
      <Stack alignItems="center">
        <LogoImg />
        <CircularProgress />
      </Stack>
    </div>
  );
}

export default Spinner;
