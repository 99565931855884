import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import Layout from 'components/Layout';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'utils/firebase';
import { createUploadLink } from 'apollo-upload-client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import client from './utils/graphql';

const clientID = process.env.REACT_APP_CLIENT_ID;

function App() {
  const [user] = useAuthState(auth);

  const customFetch = (uri: RequestInfo, options: RequestInit | undefined) => {
    return fetch(uri, options).then((response) => {
      if (response.status >= 500) {
        // or handle 400 errors
        return Promise.reject(response.status);
      }
      return response;
    });
  };

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_API_GRAPHQL,
    fetch: customFetch,
    credentials: 'include',
  });

  const getCurrentUserAuthToken = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken();

      return token;
    } else {
      return null;
    }
  };

  const authLink = setContext((_, { headers }) => {
    return getCurrentUserAuthToken().then((token) => {
      return {
        headers: {
          ...headers,
          Authorization: token ? token : '',
          clientID: clientID,
        },
      };
    });
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ToastContainer progressClassName="toastProgress" bodyClassName="toastBody" />
        <Layout />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
