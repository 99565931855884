import { gql } from '@apollo/client';

export const GET_ALL_COMPANIES = gql`
  query Companies {
    companies {
      id
      companyName
      tradingName
      abn
      contactFirstName
      contactLastName
      phoneNumber
      email
      category
      tags
      createdAt
    }
  }
`;

export const GET_COMPANY_BY_ID = gql`
  query Company($id: ID!) {
    company(id: $id) {
      companyName
      tradingName
      abn
      contactFirstName
      contactLastName
      phoneNumber
      email
      category
      tags
      createdAt
    }
  }
`;
