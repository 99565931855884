import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddOrCompaniesForm from './AddOrEditCompaniesForm';

function AddCompanies(props) {
  const navigate = useNavigate();
  const [isAdd, setIsAdd] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const handleNonDelete = () => {
    setIsDelete(false);
  };

  const handleNonEdit = () => {
    setIsSave(false);
  };

  const onBack = () => {
    setIsAdd(false);
    navigate('/dashboard/companies');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <Box sx={{ width: '100px' }}>
        <KeyboardBackspaceIcon sx={{ fontSize: '36px' }} onClick={onBack} />
      </Box>
      <div style={{ width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography style={{ fontSize: '34px', fontWeight: '700', marginRight: '40px' }}>
            Add New Company
          </Typography>
        </Box>
        <Divider />
        <AddOrCompaniesForm
          isAdd={isAdd}
          isSave={isSave}
          setIsSave={setIsSave}
          isDelete={isDelete}
          handleNonDelete={handleNonDelete}
          handleNonEdit={handleNonEdit}
          setIsDelete={setIsDelete}
          action="add"
        />
      </div>
    </div>
  );
}

export default AddCompanies;
