import { useLazyQuery, useMutation } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import { toastError, toastSuccess } from 'components/Toast';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DELETE_USER } from '../../../graphQL/Accounts/Mutation';
import { GET_ALL_ACCOUNTS } from '../../../graphQL/Accounts/Queries';
import { PATH } from '../../../path/index';
import useStyles from '../style';

function DropdownMenu(props) {
  const classes = useStyles();
  const { row, setList, list } = props;
  const navigate = useNavigate();
  const [save, setSave] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const handleSave = () => {
    setSave(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAccountDetail = (id: number) => {
    navigate(PATH.dashboard.saleAccounts.detail(id));
    setAnchorEl(null);
  };

  const [getAllAccounts] = useLazyQuery(GET_ALL_ACCOUNTS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setList(response.accounts);
    },
    onError(error) {
      console.log('Login Failed: ', error);
    },
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      getAllAccounts();
      toastSuccess('Delete User Success');
    },
    onError(error) {
      toastError('Delete User Fail');
      console.log('Delete Failed: ', error);
    },
  });

  const handleDeleteAccount = () => {
    const variables = {
      email: email,
    };
    deleteUser({ variables });
    setSave(false);
  };

  const onDeleteAccount = (e) => {
    setEmail(e);
    setSave(true);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{ fontSize: 25 }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className={classes.menu} onClick={() => onAccountDetail(row.id)}>
          Account Details
        </MenuItem>
        <MenuItem className={classes.menu} onClick={() => onDeleteAccount(row.email)}>
          Delete Account
        </MenuItem>
      </Menu>
      <ConfirmDialog
        onClickAction={handleDeleteAccount}
        email={email}
        open={save}
        handleClose={handleSave}
        title={"You're permanent"}
        question={'Confirm delete ?'}
        action="delete"
        confirm="Yes"
      />
    </>
  );
}

export default DropdownMenu;
