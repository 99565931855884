import React from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { DateRangePicker, DateRange } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import useStyles from '../style';

function DatePicker(props) {
  const { value, setValue } = props;
  const classes = useStyles();

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DateRangePicker
          startText=""
          endText=""
          value={value}
          inputFormat="DD/MM/yyyy"
          disableMaskedInput={true}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <Box>
                <Typography variant="h5">From:</Typography>
                <TextField
                  sx={{ padding: '0px 0px', background: 'none' }}
                  {...startProps}
                  className={classes.reportsInput}
                  InputProps={{
                    className: classes.reportsInput,
                  }}
                />
              </Box>
              <Box sx={{ mx: 2 }}> </Box>
              <Box>
                <Typography variant="h5">To:</Typography>
                <TextField
                  sx={{ padding: '0px 0px', background: 'none' }}
                  {...endProps}
                  className={classes.reportsInput}
                  InputProps={{
                    className: classes.reportsInput,
                  }}
                />
              </Box>
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export default DatePicker;
