export type ExampleGlobalType = {
  value: string;
};

export enum EFetchingStatus {
  LOADING = 'LOADING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum ELocation {
  ALL = 'ALL',
  NEW_SOUTH_WALES = 'New South Wales',
  QUEENSLAND = 'Queensland',
  SOUTH_AUSTRALIA = 'South Australia',
  TASMANIA = 'Tasmania',
  VICTORIA = 'Victoria',
  WESTERN_AUSTRALIA = 'Western Australia',
}

export enum EAdvertisementType {
  SPLASH = 'Splash',
  BANNER_HALF = 'Banner1/2',
  BANNER_THREE = 'Banner3',
  CAROUSEL = 'Carousel',
  banner = 'banner',
  carasel = 'carasel',
  grid = 'grid',
  verticalList = 'verticalList',
  horizontalList = 'horizontalList',
}

export enum EClientDetails {
  COMPANY_NAME = 'Company Name',
  TRADING_NAME = 'Trading Name',
  ABN = 'ABN',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  CONTACT = 'Contact',
  EMAIL = 'Email',
}

export enum ERole {
  ADMIN = 'admin',
  AGENT = 'agent',
}

export const FLOAT = 'FLOAT';
export const FLOATING_POINT = 'FLOATING_POINT';
export const INTEGER = 'INTEGER';
export const NAME_TABLE_TAB_START = 'NAME_TABLE_TAB_START';
export const NAME_TABLE_TAB_SPECIAL = 'NAME_TABLE_TAB_SPECIAL';
export const REGEX_INTERGER = 'REGEX_INTERGER';

export enum EStatus {
  DARFT = 'draft',
  PUBLISHED = 'published',
  RESERVED = 'reserved',
}

export enum EStatusDisplay {
  DARFT = 'Draft',
  PUBLISHED = 'Published',
  RESERVED = 'Reserved',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
}

export const ZH_CN = 'zh-CN';
export const ZH_SG = 'zh-SG';
export const ZH_TW = 'zh-TW';
export const ZH_HK = 'zh-HK';
